import { object, string } from "yup";
import img_leaf_conditions from "../../../../public/images/blattzustaende.png";

export const residueManagementSugarbeetSchema = function (
    {},
    { selectOptions }
) {
    const group = "residue_management_sugarbeet";

    const data = object({
        residue_management: string()
            .required()
            .oneOfOptions("residue_management"),
        sugar_beet_leave_health: string()
            .required()
            .oneOfOptions("sugarbeet_leave_health"),
    });

    const ui = {
        type: "section",
        name: group,
        label: "Crop residues",
        label__de: "Erntereste",
        children: [
            {
                type: "select_one",
                name: `${group}.residue_management`,
                label: "Crop residue management",
                label__de: "Ernterestemanagement",
                options: selectOptions("residue_management"),
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Assessment of leaf condition",
                label__de: "Bewertung des Blattapparats",
                styleClassName: "fs-3 mt-4x",
                widthLg: 12,
            },
            {
                type: "note",
                image: img_leaf_conditions,
                styleClassName: "fs-3",
                widthLg: 12,
            },
            {
                type: "select_one",
                name: `${group}.sugar_beet_leave_health`,
                label: "Condition of beet leaf",
                label__de: "Zustand des Blattapparats",
                options: selectOptions("sugarbeet_leave_health"),
                widthLg: 5,
            },
        ],
    };

    return { group, data, ui };
};
