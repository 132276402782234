import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Trans, useLingui } from "@lingui/react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { completeForm, enterForm } from "../../features/form/formSlice";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { usePostSubmissionEvaluateMutation } from "../../services/api";
import {
    useAllAssessmentChoices,
    useAssessment,
    useAuthToken,
    useSubmission,
} from "../../services/hooks";
import { cropLabel } from "../../utils/crops";
import { InfoModal } from "../InfoModal";
import { LoadingSpinner } from "../elements/LoadingSpinner";

export default function FormWelcome() {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const dispatch = useDispatch();

    const [showEvaluationErrorWarning, setEvaluationErrorWarning] =
        useState(false);

    const authToken = useAuthToken();
    const assessment = useAssessment({ authToken });
    const { assessmentData, assessmentError, assessmentIsLoading } = assessment;

    const { anyChoicesError, anyChoicesAreLoading } = useAllAssessmentChoices({
        authToken,
    });

    const latest_submission = assessmentData?.latest_submission;
    const latest_submission_date = latest_submission
        ? new Date(Date.parse(latest_submission.received_on)).toLocaleString()
        : null;

    const isOpen = assessmentData?.status == "OPEN";

    const { submissionData, submissionError, submissionIsLoading } =
        useSubmission({ authToken, submissionId: latest_submission?.id });

    const [
        postEvaluate,
        {
            isLoading: isEvaluating,
            isSuccess: isEvaluateSuccess,
            isError: isEvaluateError,
        },
    ] = usePostSubmissionEvaluateMutation({
        fixedCacheKey: "evaluate-submission",
    });
    const openEvaluationResults = async () => {
        const res = await postEvaluate({
            authToken: authToken,
            submissionId: latest_submission?.id,
        }).unwrap();
        console.log("Result of POST evaluate:", res);

        dispatch(completeForm());
    };

    useEffect(() => {
        if (isEvaluateError) setEvaluationErrorWarning(true);
    }, [isEvaluateError]);

    const action_title =
        latest_submission_date && !latest_submission?.is_prefilled_data
            ? /*i18n*/ i18n._("form.welcome.button_edit")
            : /*i18n*/ i18n._("form.welcome.button_start");

    let actionButtonVariant = "primary";
    if (
        latest_submission?.is_valid_complete &&
        !latest_submission?.is_prefilled_data &&
        isOpen
    ) {
        actionButtonVariant = "outline-primary";
    } else if (!isOpen) {
        actionButtonVariant = "outline-light";
    }

    return (
        <Container id="form-welcome">
            <div className="col-lg-5 mx-auto text-center">
                {assessmentError ? (
                    <Alert key="warning" variant="warning" className="my-5">
                        Sorry. Your requested survey does not exist or is no
                        longer available.
                    </Alert>
                ) : anyChoicesError ? (
                    <Alert key="warning" variant="warning" className="my-5">
                        Sorry. Your requested survey could not be loaded.
                    </Alert>
                ) : submissionError ? (
                    <Alert key="warning" variant="warning" className="my-5">
                        This survey is no longer available.
                    </Alert>
                ) : assessmentIsLoading ||
                  submissionIsLoading ||
                  anyChoicesAreLoading ? (
                    <LoadingSpinner label={i18n._("loading.loading_profile")} />
                ) : isEvaluating ? (
                    <LoadingSpinner
                        label={i18n._("loading.waiting_for_results")}
                    />
                ) : (
                    <>
                        <InfoModal
                            title={i18n._("form.main.evaluation_error_header")}
                            body={i18n._("form.main.evaluation_error_body")}
                            show={showEvaluationErrorWarning}
                            onClose={() => setEvaluationErrorWarning(false)}
                        />
                        <h1 className="startview-title mt-5">
                            <Trans id="form.welcome.heading" />
                        </h1>
                        <h3 className="mb-5">{assessmentData.farm.name}</h3>
                        {assessmentData?.survey?.title && (
                            <h4 className="center-text mb-5">
                                {assessmentData?.survey?.title}
                            </h4>
                        )}
                        <p className="lead center-text mb-5">
                            <Trans
                                id="form.welcome.intro"
                                components={[
                                    <b>
                                        {
                                            assessmentData.survey
                                                .requesting_organization.name
                                        }
                                    </b>,
                                    <b>
                                        {cropLabel(
                                            assessmentData.survey.crop,
                                            currentLanguage
                                        )}
                                    </b>,
                                ]}
                            />
                        </p>
                        {latest_submission_date &&
                            !latest_submission.is_prefilled_data &&
                            latest_submission?.is_valid_complete && (
                                <p className="center-text mb-5 fs-5">
                                    <Trans
                                        id="form.welcome.complete_submitted_message"
                                        components={[
                                            <>{latest_submission_date}</>,
                                        ]}
                                    />
                                </p>
                            )}
                        {latest_submission_date &&
                            !latest_submission.is_prefilled_data &&
                            !latest_submission?.is_valid_complete && (
                                <p className="center-text mb-5 fs-5">
                                    <Trans
                                        id="form.welcome.incomplete_submitted_message"
                                        components={[
                                            <>{latest_submission_date}</>,
                                        ]}
                                    />
                                </p>
                            )}
                        {latest_submission_date &&
                            latest_submission.is_prefilled_data &&
                            isOpen && (
                                <p className="center-text mb-2 fs-6">
                                    <Trans id="form.welcome.prefilled_data_available" />
                                </p>
                            )}
                        {!isOpen && (
                            <p className="center-text mb-2 fs-5">
                                <Trans id="form.welcome.assignment_closed" />
                            </p>
                        )}
                        {!assessmentData?.survey?.evaluation_inactive &&
                            latest_submission_date &&
                            latest_submission.is_valid_complete &&
                            !latest_submission.is_prefilled_data && (
                                <Button
                                    onClick={openEvaluationResults}
                                    size="lg"
                                >
                                    <Trans id="form.welcome.go_to_results_button" />
                                </Button>
                            )}{" "}
                        <Button
                            onClick={() => dispatch(enterForm())}
                            size="lg"
                            disabled={!isOpen}
                            variant={actionButtonVariant}
                        >
                            {action_title}
                        </Button>
                    </>
                )}
            </div>
        </Container>
    );
}
