import { boolean, number, object, string } from "yup";

export const packagingOffFarmProcessingSchema = function (
    { surveyParams, farm },
    { selectOptions, defaultUnit }
) {
    const group = "packaging_off_farm_processing";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const data = object({
        packaging_unit: number().min(0).nanToUndefined().notRequired(),
        packaging_unit__unit: string().notRequired().oneOfOptions("weight"),
        cardboard_amount_per_unit: number()
            .min(0)
            .nanToUndefined()
            .notRequired(),
        cardboard_amount_per_unit__unit: string()
            .notRequired()
            .oneOfOptions("weight"),
        cardboard_share_recycled: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        paper_amount_per_unit: number().min(0).nanToUndefined().notRequired(),
        paper_amount_per_unit__unit: string()
            .notRequired()
            .oneOfOptions("weight"),
        paper_share_recycled: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        wood_amount_per_unit: number().min(0).nanToUndefined().notRequired(),
        wood_amount_per_unit__unit: string()
            .notRequired()
            .oneOfOptions("weight"),
        wood_share_recycled: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        wood_use_cycles: number().min(0).nanToUndefined().notRequired(),
        plastic_amount_per_unit: number().min(0).nanToUndefined().notRequired(),
        plastic_amount_per_unit__unit: string()
            .notRequired()
            .oneOfOptions("weight"),
        plastic_share_recycled: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        plastic_use_cycles: number().min(0).nanToUndefined().notRequired(),
        metal_amount_per_unit: number().min(0).nanToUndefined().notRequired(),
        metal_amount_per_unit__unit: string()
            .notRequired()
            .oneOfOptions("weight"),
        metal_share_recycled: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        metal_use_cycles: number().min(0).nanToUndefined().notRequired(),
        natural_textile_amount_per_unit: number()
            .min(0)
            .nanToUndefined()
            .notRequired(),
        natural_textile_amount_per_unit__unit: string()
            .notRequired()
            .oneOfOptions("weight"),
        natural_textile_use_cycles: number()
            .min(0)
            .nanToUndefined()
            .notRequired(),
    });

    const defaults = {
        cardboard_amount_per_unit__unit: defaultUnit("weight", unitSystem).name,
        packaging_unit__unit: defaultUnit("weight", unitSystem).name,
        paper_amount_per_unit__unit: defaultUnit("weight", unitSystem).name,
        wood_amount_per_unit__unit: defaultUnit("weight", unitSystem).name,
        plastic_amount_per_unit__unit: defaultUnit("weight", unitSystem).name,
        metal_amount_per_unit__unit: defaultUnit("weight", unitSystem).name,
        natural_textile_amount_per_unit__unit: defaultUnit("weight", unitSystem)
            .name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Packaging",
        children: [
            {
                type: "note",
                label: "If you package {{crop_name}} for transportation to the buyer, please specify below the amount {{crop_name}} packaged per unit and the amount of the various packaging materials used per unit",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.packaging_unit`,
                label: "Amount of {{crop_name}} per packed unit",
                hint: "For example a 25kg bag of ground ginger, a big bag containing 100kg of dried mint, a 50kg can of oil etc.",
                unit: selectOptions("weight"),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Cardboard",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.cardboard_amount_per_unit`,
                label: "Amount of cardboard per packaged unit",
                hint: "E.g., a cardboard box with empty weight of 600 g",
                unit: selectOptions("weight"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.cardboard_share_recycled`,
                label: "Share recycled material",
                unit: "%",
                widthLg: 3,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Paper",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.paper_amount_per_unit`,
                label: "Amount of paper per packaged unit",
                hint: "E.g., a paper bag with empty weight of 100 g",
                unit: selectOptions("weight"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.paper_share_recycled`,
                label: "Share recycled material",
                unit: "%",
                widthLg: 3,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Wood",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.wood_amount_per_unit`,
                label: "Amount of wood per packaged unit",
                hint: "E.g. wood pallets, boxes, barrels etc.",
                unit: selectOptions("weight"),
                widthLg: 4,
                info: {
                    title: "Multiple packaged units per pallet/box etc.",
                    text: "If you use e.g. wooden pallets on which you transport multiple of the packed units specified above, please divide the weight of a single pallet by the number of packed units (e.g. 25kg bags) loaded onto a single pallet.",
                },
            },
            {
                type: "number",
                name: `${group}.wood_share_recycled`,
                label: "Share recycled material",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "number",
                name: `${group}.wood_use_cycles`,
                label: "Number of use cycles", //maybe put hint in info-button
                hint: "E.g. if a pallet is used for multiple transport journeys",
                widthLg: 3,
                info: {
                    title: "How to provide use cycles",
                    text: "For example if you are using wood boxes or pallets, these may have several use cycles (e.g. transported back and forth between your and your buyers facilities) before being discarded. If the exact number is unkown, please provide a rough estimate for the number of transports the material is used for before discarded.",
                },
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Plastic",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.plastic_amount_per_unit`,
                label: "Amount of plastic per packaged unit",
                hint: "E.g. pallets, crates, bags, big bags, barrels, cans etc.",
                unit: selectOptions("weight"),
                widthLg: 4,
                info: {
                    title: "Multiple packaged units per pallet/crate etc.",
                    text: "If you use e.g. plastic pallets or crates with which you transport multiple of the packed units specified above, please divide the weight of a single pallet/crate by the number of packed units (e.g. 25kg bags) loaded onto a single pallet/crate.",
                },
            },
            {
                type: "number",
                name: `${group}.plastic_share_recycled`,
                label: "Share recycled material",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "number",
                name: `${group}.plastic_use_cycles`,
                label: "Number of use cycles", //maybe put hint in info-button
                hint: "E.g. if plastic barrels, crates etc. are used for multiple transport journeys",
                info: {
                    title: "How to provide use cycles",
                    text: "For example if you are using plastic crates or pallets, these may have several use cycles (e.g. transported back and forth between your and your buyers facilities) before being discarded. If the exact number is unkown, please provide a rough estimate for the number of transports the material is used for before discarded.",
                },
                widthLg: 3,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Metal",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.metal_amount_per_unit`,
                label: "Amount of metal per packaged unit",
                hint: "E.g. metal cans, drums, boxes etc.",
                unit: selectOptions("weight"),
                widthLg: 4,
                info: {
                    title: "Multiple packaged units per drum/box etc.",
                    text: "If you use e.g. metal drums, barrels or boxes with which you transport multiple of the packed units specified above, please divide the weight of a single drum/box by the number of packed units (e.g. 5kg bags) loaded into a single drum/box.",
                },
            },
            {
                type: "number",
                name: `${group}.metal_share_recycled`,
                label: "Share recycled material",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "number",
                name: `${group}.metal_use_cycles`,
                label: "Number of use cycles", //maybe put hint in info-button
                hint: "E.g. if drums, boxes etc. are used for multiple transport journeys",
                info: {
                    title: "How to provide use cycles",
                    text: "For example if you are using barrels, cans or boxes, these may have several use cycles (e.g. transported back and forth between your and your buyers facilities) before being discarded. If the exact number is unkown, please provide a rough estimate for the number of transports the material is used for before discarded.",
                },
                widthLg: 3,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Natural textile",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.natural_textile_amount_per_unit`,
                label: "Amount of natural textile per packaged unit",
                hint: "E.g. burlap/jute sacks, cotton bags etc.",
                unit: selectOptions("weight"),
                widthLg: 4,
                info: {
                    title: "Multiple packaged units per bag etc.",
                    text: "If you use e.g. burlap or cotton bags with which you transport multiple of the packed units specified above, please divide the weight of a single drum/box by the number of packed units (e.g. 5kg bags) loaded into a single drum/box.",
                },
            },
            {
                type: "number",
                name: `${group}.natural_textile_use_cycles`,
                label: "Number of use cycles", //maybe put hint in info-button
                hint: "E.g. if burlap or cotton bags etc. are used for multiple transport journeys",
                info: {
                    title: "How to provide use cycles",
                    text: "For example if you are using burlap or cotton bags, these may have several use cycles (e.g. transported back and forth between your and your buyers facilities) before being discarded. If the exact number is unkown, please provide a rough estimate for the number of transports the material is used for before discarded.",
                },
                widthLg: 3,
                styleBreakRowAfter: true,
            },
        ],
    };

    return { group, data, ui, defaults };
};
