import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import InputGroup from "react-bootstrap/InputGroup";

export function InfoButton({ infoTitle, infoText }) {
    return (
        <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose="true"
            overlay={
                <Popover id={`popover-positioned-top`}>
                    <Popover.Header as="h3">{infoTitle}</Popover.Header>
                    <Popover.Body>{infoText}</Popover.Body>
                </Popover>
            }
        >
            <InputGroup.Text className="button-light info-button cursor-pointer">
                <i className="bi bi-question-circle fs-5 text-muted"></i>
            </InputGroup.Text>
        </OverlayTrigger>
    );
}
