import { Schema, addMethod, setLocale } from "yup";
import {
    generateConvertUnit,
    generateDefaultUnits,
    generateGetOption,
    generateResolveFractionUnits,
    generateSelectOptions,
    generateTestFertilizerNRate,
    generateTestTotalsAgainstWeightAreaRate,
    generateTestDieselWithinRangeOption,
    generateYupMethodConditionalOneOfOptions,
    generateYupMethodLimitsWithUnit,
    generateYupMethodOneOfOptions,
    yupMethodNanToUndefined,
    yupMethodRequiredWhen,
} from "../utils/schemaUtils";

export function generateMethods(choiceIndex) {
    const selectOptions = generateSelectOptions(choiceIndex);
    const getOption = generateGetOption(choiceIndex);
    const defaultUnit = generateDefaultUnits(choiceIndex);
    const convertUnit = generateConvertUnit(choiceIndex);
    const resolveFractionUnits = generateResolveFractionUnits(choiceIndex);
    const testTotalsAgainstWeightAreaRate =
        generateTestTotalsAgainstWeightAreaRate(convertUnit);
    const testFertilizerNRate = generateTestFertilizerNRate(convertUnit);
    const testDieselWithinRangeOption = generateTestDieselWithinRangeOption(
        getOption,
        convertUnit
    );

    return {
        selectOptions,
        getOption,
        defaultUnit,
        convertUnit,
        resolveFractionUnits,
        testTotalsAgainstWeightAreaRate,
        testDieselWithinRangeOption,
        testFertilizerNRate,
    };
}

export function setupCustomValidators(choiceIndex, methods) {
    // @ts-ignore
    addMethod(
        Schema,
        "oneOfOptions",
        generateYupMethodOneOfOptions(choiceIndex)
    );
    // @ts-ignore
    addMethod(
        Schema,
        "conditionalOneOfOptions",
        generateYupMethodConditionalOneOfOptions(choiceIndex)
    );
    // @ts-ignore
    addMethod(Schema, "requiredWhen", yupMethodRequiredWhen);
    // @ts-ignore
    addMethod(Schema, "nanToUndefined", yupMethodNanToUndefined);
    // @ts-ignore
    addMethod(
        Schema,
        "limitsWithUnit",
        generateYupMethodLimitsWithUnit(choiceIndex, methods.convertUnit)
    );
}

setLocale({
    mixed: {
        default: "The statement is invalid. Please check.",
        notType: "The statement is invalid. Please check.",
        required: "This field is required.",
    },
    number: {
        min: "The value should not be lower than ${min}.",
        max: "The value should not be higher than ${max}.",
    },
});
