import { createSlice } from "@reduxjs/toolkit";
import { api } from "../services/api";

const initialState = {
    assessment: null,
};

const slice = createSlice({
    name: "assessment",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            api.endpoints.getAssessmentById.matchFulfilled,
            (state, { payload }) => {
                state.assessment = payload;
            }
        );
    },
});

export default slice.reducer;

export const selectCurrentAssessment = (state) => state.assessment.assessment;
