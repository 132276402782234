import { array, number, object, string } from "yup";
import { formatNumber } from "../../../utils/format";
import { fieldOptionLabel } from "../../../utils/forms";
import { monthHalfOptions } from "../../../utils/schemaUtils";

export const organicFertilizationGenericSchema = function (
    { surveyParams, survey },
    { selectOptions, getOption, defaultUnit, convertUnit, testFertilizerNRate }
) {
    const group = "organic_fertilization_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;
    const hideApplicationDate =
        surveyParams?.DISABLE_ORGANIC_FERTILIZER_APPLICATION_DATE === "true";

    const nRateMinKgHa = 0;
    const nRateMaxKgHa =
        parseFloat(surveyParams?.ORGANIC_FERTILIZERS_TOTAL_N_MAX_KG_HA) || 1000;

    const monthHalfOptionsList = monthHalfOptions(
        "fertilization_date",
        survey?.year,
        18
    );

    const data = object({
        fertilizers: array()
            .of(
                object({
                    type: string()
                        .required()
                        .oneOfOptions("organic_fertilizer"),
                    application_rate: number().required().positive(),
                    application_rate__unit: string()
                        .required()
                        .oneOfOptions("weight_area_rate"),
                    nitrification_inhibitor_applied: string()
                        .required()
                        .oneOfOptions("yes_no"),
                    application_method: string()
                        .required()
                        .oneOfOptions("fertilization_method"),
                    ...(!hideApplicationDate && {
                        application_date: string()
                            .notRequired()
                            .oneOfOptions(monthHalfOptionsList, true),
                    }),
                    transport_distance: number().required().min(0),
                    transport_distance__unit: string()
                        .required()
                        .oneOfOptions("distance"),
                })
            )
            .test({
                name: "organic_n_rate",
                test: testFertilizerNRate(
                    nRateMinKgHa,
                    nRateMaxKgHa,
                    getOption,
                    "organic_fertilizer"
                ),
                message:
                    "Nitrogen fertilization may not be less than ${min} or more than ${max} kg/ha.",
                params: { min: nRateMinKgHa, max: nRateMaxKgHa },
            }),
    });

    const nutrientDisplayUnit = defaultUnit("weight_area_rate", unitSystem);

    const defaults = {
        fertilizers__default: {
            application_rate__unit: defaultUnit("weight_area_rate", unitSystem)
                .name,
            transport_distance__unit: defaultUnit("distance", unitSystem).name,
        },
    };

    const ui = {
        type: "section",
        name: group,
        name: group,
        label: "Organic fertilizers",
        label__de: "Organische Düngung",
        badge: "per_area",
        children: [
            {
                type: "modal_repeat",
                name: `${group}.fertilizers`,
                label: "Organic fertilizers",
                label__de: "Organische Düngemittel",
                add_label: "Add fertilizer",
                add_label__de: "Düngemittel hinzufügen",
                styleBreakRowAfter: true,
                tableHeaders: (language) =>
                    language == "de"
                        ? ["Düngemittel", "Menge"]
                        : ["Type", "Application rate"],
                tableRow: (uiSchema, itemData, index, language) => [
                    fieldOptionLabel(
                        getOption("organic_fertilizer", itemData?.type),
                        language
                    ),
                    `${formatNumber(itemData?.application_rate, 2)} ${
                        itemData?.application_rate__unit
                    }`,
                ],
                tableFooter: (data) => {
                    let N_total = 0;
                    for (let row of data) {
                        const fertilizer = getOption(
                            "organic_fertilizer",
                            row?.type
                        );
                        const applicationRate = row?.application_rate || 0;
                        const rateUnit = row?.application_rate__unit;
                        const convertedApplicationRate = convertUnit(
                            "weight_area_rate",
                            applicationRate,
                            rateUnit,
                            nutrientDisplayUnit.name
                        );

                        N_total +=
                            (parseFloat(fertilizer?.nutrients?.n) / 100) *
                                convertedApplicationRate || 0;
                    }
                    const unitLabel = nutrientDisplayUnit.label_short;
                    return [`N: ${formatNumber(N_total, 0)} ${unitLabel}`, ""];
                },
                children: [
                    {
                        type: "select_one",
                        name: "type",
                        label: "Fertilizer",
                        label__de: "Düngemittel",
                        options: selectOptions("organic_fertilizer"),
                        widthLg: 8,
                        styleBreakRowAfter: true,
                    },
                    {
                        type: "number",
                        name: "application_rate",
                        label: "Application rate of material",
                        label__de: "Ausbringmenge des Materials",
                        unit: selectOptions("weight_area_rate"),
                        widthLg: 6,
                    },
                    {
                        type: "select_one",
                        name: "nitrification_inhibitor_applied",
                        label: "Nitrification inhibitor applied?",
                        label__de: "Nitrifikations-Hemmer verwendet?",
                        options: selectOptions("yes_no"),
                        styleSelectHorizontalButtons: true,
                        styleBreakRowAfter: true,
                        widthLg: 6,
                    },
                    {
                        type: "check_info",
                        widthLg: 6,
                        styleBreakRowAfter: true,
                        checkText__de: "Stickstoff N",
                        checkText: "Nitrogen N",
                        checkValue: (watch) => {
                            const fertilizerName = watch("type");
                            const fertilizer = getOption(
                                "organic_fertilizer",
                                fertilizerName
                            );
                            const nitrogen =
                                (watch("application_rate") *
                                    (parseFloat(fertilizer?.nutrients?.n) ||
                                        0)) /
                                100;
                            const nutrient_rate = convertUnit(
                                "weight_area_rate",
                                nitrogen,
                                watch("application_rate__unit"),
                                nutrientDisplayUnit.name
                            );
                            const unit = fieldOptionLabel(nutrientDisplayUnit);

                            return `${formatNumber(
                                nutrient_rate || 0,
                                2
                            )} ${unit} `;
                        },
                    },
                    {
                        type: "select_one",
                        name: "application_method",
                        label: "Application method",
                        label__de: "Ausbringungsmethode",
                        options: selectOptions("fertilization_method"),
                        widthLg: 6,
                    },
                    !hideApplicationDate && {
                        type: "select_one",
                        name: "application_date",
                        label: "Date of application",
                        label__de: "Zeitpunkt der Ausbringung",
                        options: monthHalfOptionsList,
                        widthLg: 6,
                        styleBreakRowAfter: true,
                    },
                    {
                        type: "number",
                        name: "transport_distance",
                        label: "Average transport distance from origin to field",
                        label__de:
                            "Durchschnittliche Transportdistanz vom Ursprung zum Feld",
                        unit: selectOptions("distance"),
                        widthLg: 7,
                    },
                ],
            },
        ],
    };

    return { group, data, ui, defaults };
};
