import { yupResolver } from "@hookform/resolvers/yup";
import { Trans } from "@lingui/react";
import structuredClone from "core-js-pure/actual/structured-clone";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../../features/viewSlice";
import { fieldLabel } from "../../../utils/i18nForms";
import FormFieldRenderer from "../FormFieldRenderer";

export default function ModalRepeatForm({
    handleCancel,
    handleSave,
    item,
    dataSchema,
    uiSchema,
    sectionKey,
    title,
    context,
}) {
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const { data, field, index } = item;
    const form = useForm({
        defaultValues: data,
        resolver: yupResolver(dataSchema),
    });
    const { register, handleSubmit } = form;
    const save = (data) => {
        const newItem = structuredClone(item);
        newItem.data = data;
        handleSave(newItem);
    };

    return (
        <Modal
            show={true}
            onHide={handleCancel}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title || fieldLabel(uiSchema, sectionKey, { context })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormFieldRenderer
                    form={form}
                    dataSchema={dataSchema}
                    uiSchema={uiSchema}
                    context={context}
                    sectionKey={sectionKey}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    <Trans id="modal_repeat.form_cancel" />
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit(save, (e) => console.log(e))}
                >
                    <Trans id="modal_repeat.form_save" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
