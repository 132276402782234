import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { get, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { fieldOptionLabel } from "../../utils/forms";
import { InfoButton } from "./InfoButton";

export function BaseInput({
    name,
    type,
    as,
    asTextarea,
    registerOptions,
    label,
    unit,
    hint,
    placeholder,
    form,
    info,
}) {
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const {
        register,
        trigger,
        formState: { errors },
    } = form ? form : useFormContext();

    let valueAsNumber = false;
    if (type == "number") {
        valueAsNumber = true;
    }

    const isMultiUnit = unit && Array.isArray(unit);

    const error = get(errors, name);

    const controlType = asTextarea ? {} : { type: type || "text" };
    const asElement = asTextarea ? { as: "textarea", rows: 3 } : {};

    return (
        <Form.Group as={as} controlId={name}>
            <Form.Label>{label}</Form.Label>

            <InputGroup>
                {info && (
                    <InfoButton
                        infoTitle={info?.title}
                        infoText={info?.text}
                    ></InfoButton>
                )}
                <Form.Control
                    {...controlType}
                    placeholder={placeholder}
                    {...register(name, {
                        ...registerOptions,
                        valueAsNumber,
                    })}
                    isInvalid={error}
                    aria-label={label}
                    aria-describedby={`${name}-unit`}
                    {...asElement}
                />

                {unit && !isMultiUnit && (
                    <InputGroup.Text id={`${name}-unit`}>
                        {unit}
                    </InputGroup.Text>
                )}
                {unit && isMultiUnit && (
                    <Form.Select
                        id={`${name}-unit`}
                        className="input-group-unit"
                        aria-label={`${label} Unit`}
                        {...register(`${name}__unit`, {
                            deps: [name],
                            onChange: () =>
                                trigger(name, { shouldFocus: true }),
                        })}
                    >
                        {unit.map((u, i) => (
                            <option key={`${name}__unit-${i}`} value={u.name}>
                                {fieldOptionLabel(u, currentLanguage)}
                            </option>
                        ))}
                    </Form.Select>
                )}

                {error && (
                    <Form.Control.Feedback type="invalid">
                        {error?.message}
                    </Form.Control.Feedback>
                )}
            </InputGroup>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
        </Form.Group>
    );
}
