import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import structuredClone from "core-js-pure/actual/structured-clone";
import { useMemo, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { get, useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { reach } from "yup";
import { selectCurrentViewLanguage } from "../../../features/viewSlice";
import { fieldOptionLabel } from "../../../utils/forms";
import { fieldLabel, fieldHint, fieldInfo } from "../../../utils/i18nForms";
import { optionLabel } from "../../../utils/schemaUtils";
import { BaseInput } from "../../elements/BaseInput";
import { BaseSelect } from "../../elements/BaseSelect";
import { DangerousMarkdown } from "../../elements/Markdown";

const NO_CHANGE = "no-change";
const MAX_CHANGES = 4;

export default function ModalRepeatCarbonStockChangesV2({
    dataSchema,
    uiSchema,
    defaultValues,
    sectionKey,
    context,
    parameters,
}) {
    const language = useSelector(selectCurrentViewLanguage);
    const form = useFormContext();
    const { watch, control } = form;

    const { fields, append, remove } = useFieldArray({
        name: uiSchema.name,
        control,
        shouldUnregister: false,
    });

    const subDataSchema = reach(dataSchema, uiSchema.name)?.innerType;
    if (!subDataSchema) {
        console.error("Data schema not found for repeat at path", uiSchema);
    }

    const subDefaultValues = get(defaultValues, `${uiSchema.name}__default`);

    const assessmentYear =
        context?.assessment?.survey?.year || new Date().getFullYear();
    const allYears = [...Array(20).keys()].map((y, i) => assessmentYear - i);

    const { columns, yearField, allocationField, addYearNoteField } =
        parameters;

    const allocationFieldUi = useMemo(() => {
        return uiSchema.children.find((v) => v.name == allocationField);
    }, [allocationField, uiSchema]);

    const addYearNoteFieldUi = useMemo(() => {
        return uiSchema.children.find((v) => v.name == addYearNoteField);
    }, [addYearNoteField, uiSchema]);

    const { columnDefs, latestRowValues, newItemDataTemplate } = useMemo(() => {
        const columnDefs = [];
        const latestRowValues = {}; // used for iterating years
        const newItemDataTemplate = {
            [yearField]: null,
            [allocationField]: 100,
        };

        for (const col of columns) {
            if (!col) continue;
            const fieldUi = uiSchema.children.find(
                (v) => v.name == col?.beforeField
            );
            const name = col?.beforeField;
            const header = fieldLabel(col, sectionKey, { context });
            const headerHint = fieldHint(col, sectionKey);
            const latestValue = watch(col?.latestField);
            const latest = optionLabel(fieldUi, latestValue, language);
            newItemDataTemplate[fieldUi?.name] = NO_CHANGE;
            latestRowValues[col?.beforeField] = latestValue;
            columnDefs.push({
                ui: fieldUi,
                header,
                headerHint,
                latest,
                name,
            });
        }
        return { columnDefs, latestRowValues, newItemDataTemplate };
    }, [columns, uiSchema, context, language]);

    let _previousRowValue = structuredClone(latestRowValues);

    const changeItemsArray = watch(`${uiSchema.name}`)
        .map((f, i) => ({ ...f, index: i })) // add index for lookups (must be done before ordering by year)
        .sort((a, b) => b.year - a.year); // then order by year

    const handleClickAdd = (year) => {
        for (const changeItem of changeItemsArray) {
            if (changeItem.year == year) {
                return; // year already entered
            }
        }

        const newItemData = {
            ...structuredClone(newItemDataTemplate),
            ...subDefaultValues,
            year: year,
        };
        append(newItemData);
    };

    const handleClickReset = (year) => {
        for (const changeItem of changeItemsArray) {
            if (changeItem.year == year) {
                remove(changeItem.index);
            }
        }
    };

    function previousYear(year) {
        let prevYear = assessmentYear - 20;
        for (const changeItem of changeItemsArray) {
            if (changeItem.year < year && changeItem.year > prevYear) {
                prevYear = changeItem.year;
            }
        }
        return prevYear || null;
    }

    function isYearAlreadyEntered(year) {
        for (const changeItem of changeItemsArray) {
            if (changeItem.year == year) {
                return true;
            }
        }
        return false;
    }

    const latestChangeYear = previousYear(assessmentYear + 1);

    const table = (
        <Table
            responsive
            className="sus-carbon-stock-table text-center align-middle"
        >
            <thead>
                <tr>
                    <th style={{ width: "7rem" }}>
                        <Trans id="carbon_stock_changes.table_header_year" />
                    </th>
                    {columnDefs.map((col, i) => (
                        <th key={i}>
                            {col?.header}
                            {col?.headerHint && (
                                <small className="text-muted fw-normal">
                                    <br />
                                    {col?.headerHint}
                                </small>
                            )}
                        </th>
                    ))}
                    <th style={{ width: "4rem" }}></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {latestChangeYear} - {assessmentYear}
                    </td>
                    {columnDefs.map((col, i) => (
                        <td key={i} className="py-4">
                            {col?.latest}
                        </td>
                    ))}
                    <td></td>
                </tr>
                {changeItemsArray.length == 0 && (
                    <tr>
                        <td colSpan={columnDefs.length + 2}>
                            <Trans id="carbon_stock_changes.no_changes_yet" />
                        </td>
                    </tr>
                )}
                {changeItemsArray.map((changeItem) => {
                    const fieldBaseName = `${uiSchema?.name}.${changeItem?.index}`;
                    const year = changeItem?.year;
                    const label = i18n._("carbon_stock_changes.year_before", {
                        year: year,
                    });
                    const mainRow = (
                        <tr key={changeItem?.id}>
                            <th className="fs-5 border-bottom-0">{year}</th>
                            {columnDefs.map((col, columnIndex) => (
                                <td
                                    key={"col-" + columnIndex}
                                    className="sus-carbon-change-cell border-bottom-0"
                                >
                                    <BaseSelect
                                        name={`${fieldBaseName}.${col?.name}`}
                                        label={label}
                                        options={(col?.ui?.options || []).map(
                                            (o) => ({
                                                value: o.name,
                                                label: fieldOptionLabel(
                                                    o,
                                                    language
                                                ),
                                            })
                                        )}
                                        form={form}
                                    />
                                    <BaseInput
                                        name={`${fieldBaseName}.${col?.name}_${allocationField}`}
                                        type={"number"}
                                        label={fieldLabel(
                                            allocationFieldUi,
                                            sectionKey,
                                            { context }
                                        )}
                                        unit={"%"}
                                        form={form}
                                        info={fieldInfo(
                                            allocationFieldUi,
                                            sectionKey
                                        )}
                                    />
                                </td>
                            ))}
                            <td className="text-end align-middle border-bottom-0">
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => handleClickReset(year)}
                                    size="sm"
                                >
                                    🗙
                                </Button>
                            </td>
                        </tr>
                    );
                    for (const col of columnDefs) {
                        // for any practice with a change, memorize it for the next info row
                        if (changeItem[col.name] != NO_CHANGE) {
                            _previousRowValue[col.name] = changeItem[col.name];
                        }
                    }
                    const previousChangeYear = previousYear(year);
                    const intermediateInfoRow = (
                        <tr>
                            <td>
                                {previousChangeYear} - {year}
                            </td>
                            {columnDefs.map((col, columnIndex) => (
                                <td
                                    key={"col-" + columnIndex}
                                    className="sus-carbon-change-cell align-middle py-4"
                                >
                                    {optionLabel(
                                        col?.ui,
                                        _previousRowValue[col?.name],
                                        language
                                    )}
                                </td>
                            ))}
                            <td></td>
                        </tr>
                    );

                    return (
                        <>
                            {mainRow}
                            {intermediateInfoRow}
                        </>
                    );
                })}
            </tbody>
        </Table>
    );

    const max_changes_reached = changeItemsArray.length >= MAX_CHANGES;
    const addYearDropdownRef = useRef(null);
    const addRow = addYearNoteFieldUi && (
        <div className="sus-carbon-stock-add-year">
            <DangerousMarkdown
                text={fieldLabel(addYearNoteFieldUi, sectionKey, { context })}
            />
            <InputGroup className="mt-3">
                <Form.Select
                    name={"add_year"}
                    ref={addYearDropdownRef}
                    disabled={max_changes_reached}
                >
                    {allYears
                        .filter((y) => !isYearAlreadyEntered(y))
                        .map((y, i) => (
                            <option key={y} value={y}>
                                {y}
                            </option>
                        ))}
                </Form.Select>
                <Button
                    variant="primary"
                    disabled={max_changes_reached}
                    onClick={() =>
                        handleClickAdd(addYearDropdownRef.current.value)
                    }
                    size="sm"
                >
                    <Trans id="carbon_stock_changes.add_change" />
                </Button>
            </InputGroup>
            {max_changes_reached && (
                <p>
                    <Trans
                        id="carbon_stock_changes.max_reached"
                        values={{ num_changes: MAX_CHANGES }}
                    />
                </p>
            )}
        </div>
    );

    return (
        <>
            {table}
            {addRow}
        </>
    );
}
