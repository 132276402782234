import { Trans } from "@lingui/react";
import React from "react";

import Container from "react-bootstrap/Container";

function Footer(props) {
    return (
        <Container className="text-center mt-5">
            <p>
                <Trans
                    id="footer.powered_by"
                    components={[
                        <a
                            href="https://www.sustainable.de/"
                            target="_blank"
                        />,
                    ]}
                />
            </p>
        </Container>
    );
}

export default Footer;
