import { useEffect } from "react";
import { ScrollSpy } from "bootstrap";

export function useScrollSpy(target) {
    // Initiate
    useEffect(() => {
        new ScrollSpy(document.body, {
            target: target,
            offset: 300,
        });
    }, []);

    // Update section menu, uses Bootstrap scrollspy
    useEffect(() => {
        const dataSpyList = [].slice.call(
            document.querySelectorAll('[data-bs-spy="scroll"]')
        );
        dataSpyList.forEach(function (dataSpyEl) {
            ScrollSpy.getInstance(dataSpyEl).refresh();
        });
    });
}
