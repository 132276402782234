import { createSlice } from "@reduxjs/toolkit";
import { api } from "../services/api";
import { buildApiMessages } from "../utils/i18n";

const initialState = {
    messages: {},
};

const slice = createSlice({
    name: "i18n",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            api.endpoints.listFormTranslations.matchFulfilled,
            (state, { payload }) => {
                const lang = payload.language;
                const langMessages = {
                    ...(lang in state ? state[lang] : {}),
                    ...buildApiMessages(
                        payload.texts,
                        "form",
                        payload.language
                    ),
                };
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        [payload.language]: {
                            ...state.messages[payload.language],
                            ...langMessages,
                        },
                    },
                };
            }
        );
        builder.addMatcher(
            api.endpoints.listComponentTranslations.matchFulfilled,
            (state, { payload }) => {
                const lang = payload.language;
                const langMessages = {
                    ...(lang in state ? state[lang] : {}),
                    ...buildApiMessages(payload.texts, null, payload.language),
                };
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        [payload.language]: {
                            ...state.messages[payload.language],
                            ...langMessages,
                        },
                    },
                };
            }
        );
    },
});

export default slice.reducer;

export const selectApiTexts = (state) => {
    const s = state.getState();
    const lang = s.view.language;
    if (lang in s.i18n.messages) {
        return { messages: s.i18n.messages[lang], language: lang };
    }
    return { messages: null, language: lang };
};
