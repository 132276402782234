import { createSlice } from "@reduxjs/toolkit";
import { api } from "../services/api";

const initialState = {
    organization: null,
    country: null,
};

const slice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            api.endpoints.getAssessmentById.matchFulfilled,
            (state, { payload }) => {
                state.organization = payload.farm?.name;
                state.country = payload.farm?.country;
            }
        );
    },
});

export default slice.reducer;

export const selectCurrentUser = (state) => state.user;
