import { number, object, string } from "yup";

export const wasteWaterGenericSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit }
) {
    const group = "waste_water_generic";

    const data = object({
        waste_water_produced: string()
            .required()
            .oneOfOptions(selectOptions("yes_no")),
        waste_water_volume: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("waste_water_produced", (v) => v == "yes"),
        waste_water_volume__unit: string()
            .notRequired()
            .oneOfOptions("volume", true)
            .requiredWhen("waste_water_produced", (v) => v == "yes"),
        waste_water_oxygen_demand: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("waste_water_produced", (v) => v == "yes"),
        waste_water_oxygen_demand__unit: string()
            .notRequired()
            .oneOfOptions("weight_volume_rate", true)
            .requiredWhen("waste_water_produced", (v) => v == "yes"),
        oxygen_demand_type: string()
            .notRequired()
            .oneOfOptions("oxygen_demand_type", true)
            .requiredWhen("waste_water_produced", (v) => v == "yes"),
        treatment_process: string()
            .notRequired()
            .oneOfOptions("water_treatment_type", true)
            .requiredWhen("waste_water_produced", (v) => v == "yes"),
        is_temperature_below_15degrees: string()
            .notRequired()
            .oneOfOptions("yes_no", true)
            .requiredWhen("waste_water_produced", (v) => v == "yes"),
    });

    const defaults = {
        waste_water_volume__unit: "litre",
        waste_water_oxygen_demand__unit: "mg / litre",
    };

    const ui = {
        type: "section",
        name: group,
        label: "Waste water",
        label__de: "Abwässer",
        badge: "total",
        children: [
            {
                type: "select_one",
                name: `${group}.waste_water_produced`,
                label: "Does the cultivation or processing of the product generate any waste water on farm?",
                label__de:
                    "Erzeugt der Anbau oder die Nachbearbeitung des Produkts Abwässer?",
                options: selectOptions("yes_no"),
                widthLg: 6,
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.waste_water_volume`,
                label: "Waste water volume",
                label__de: "Abwassermenge",
                unit: selectOptions("volume"),
                widthLg: 4,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.waste_water_produced`) == "yes",
            },
            {
                type: "number",
                name: `${group}.waste_water_oxygen_demand`,
                label: "Waste water oxygen demand",
                label__de: "Sauerstoffbedarf des Abwassers",
                unit: selectOptions("weight_volume_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.waste_water_produced`) == "yes",
            },
            {
                type: "select_one",
                name: `${group}.oxygen_demand_type`,
                label: "Type of oxygen demand",
                label__de: "Typ des Sauerstsoffbedarfs",
                options: selectOptions("oxygen_demand_type"),
                widthLg: 6,
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.waste_water_produced`) == "yes",
            },
            {
                type: "select_one",
                name: `${group}.treatment_process`,
                label: "Treatment process",
                label__de: "Abwasserbehandlung",
                options: selectOptions("water_treatment_type"),
                widthLg: 6,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.waste_water_produced`) == "yes",
            },
            {
                type: "select_one",
                name: `${group}.is_temperature_below_15degrees`,
                label: "Is the annual average temperature at the farm lower than 15°C (59°F)?",
                options: selectOptions("yes_no"),
                widthLg: 4,
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.waste_water_produced`) == "yes",
            },
        ],
    };

    return { group, data, ui, defaults };
};
