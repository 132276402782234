import { useLingui } from "@lingui/react";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectCurrentViewLanguage } from "../features/viewSlice";
import { switchLanguage } from "../utils/i18n";
import AssignmentListContainer from "./survey/AssignmentListContainer";

import Footer from "./Footer";
import FormContainer from "./form/FormContainer";
import Header from "./Header";
import SurveyListContainer from "./survey/SurveyListContainer";
import { isFarmpactDomain } from "../utils/ui";

export default function App() {
    const { i18n } = useLingui();

    // read view language from store and update lingui.js if it changes
    const language = useSelector(selectCurrentViewLanguage);
    useEffect(() => switchLanguage(i18n, language), [language]);

    // change title of webpage depending on domain
    useEffect(() => {
        const isFarmpact = isFarmpactDomain();

        if (isFarmpact) {
            document.title = "farmpact by sustainable";
        } else {
            document.title = "AgGHG by sustainable";
        }
    }, [1]);

    return (
        <div id="wrapper">
            <Header />
            <Routes>
                <Route
                    path="/assessment/:assessmentId"
                    element={<FormContainer />}
                ></Route>
                <Route
                    path="/survey/:surveyId/assignments"
                    element={<AssignmentListContainer />}
                ></Route>
                <Route path="/" element={<SurveyListContainer />} />
            </Routes>
            <Footer />
        </div>
    );
}
