import { yupResolver } from "@hookform/resolvers/yup";
import { Trans, useLingui } from "@lingui/react";
import { useEffect, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import RForm from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { completeForm, initWelcome } from "../../features/form/formSlice";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { buildSchema } from "../../schemas/definitions";
import { generateMethods, setupCustomValidators } from "../../schemas/schema";
import {
    useListComponentTranslationsQuery,
    useListFormTranslationsQuery,
    usePostSubmissionEvaluateMutation,
    usePostSubmissionMutation,
} from "../../services/api";
import {
    useAllAssessmentChoices,
    useAssessment,
    useAuthToken,
    useLatestSubmission,
} from "../../services/hooks";
import { cleanFormErrorsFromRefs, deriveFormBadge } from "../../utils/forms";
import { useScrollSpy } from "../../utils/hooks";
import { fieldLabel } from "../../utils/i18nForms";
import { buildChoiceIndex } from "../../utils/schemaUtils";
import { InfoModal } from "../InfoModal";
import { LoadingSpinner } from "../elements/LoadingSpinner";
import FormFieldRenderer from "./FormFieldRenderer";
import { PrivacyStatement } from "./parts/PrivacyStatement";
import { mergeDeep } from "../../utils/objects";

export default function Form() {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);

    const authToken = useAuthToken();
    const assessment = useAssessment({ authToken });
    const { assessmentData } = assessment;

    const { allChoices, anyChoicesAreLoading, anyChoicesError } =
        useAllAssessmentChoices({
            authToken,
        });

    const formDefName = assessmentData?.survey?.form_def;

    const { formDef, dataSchema } = useMemo(() => {
        if (formDefName && !anyChoicesAreLoading && !anyChoicesError) {
            const choiceIndex = buildChoiceIndex(allChoices);
            const schemaMethods = generateMethods(choiceIndex);
            setupCustomValidators(choiceIndex, schemaMethods);
            const formDef = buildSchema(
                formDefName,
                assessmentData,
                choiceIndex,
                schemaMethods
            );
            return {
                formDef: formDef,
                dataSchema: yupResolver(formDef.dataSchema),
            };
        }
        return { formDef: undefined, yupSchema: undefined };
    }, [formDefName, anyChoicesAreLoading, anyChoicesError]);

    const { isFetching: isLoadingLanguage } = useListFormTranslationsQuery(
        {
            language: currentLanguage,
            sectionKeys: formDef?.sectionKeys,
            textTags: assessmentData?.survey?.text_tags,
        },
        { skip: !formDef?.sectionKeys || formDef?.sectionKeys == 0 }
    );
    useListComponentTranslationsQuery({
        language: currentLanguage,
        componentKeys: ["form_controls"],
    });

    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState();
    const [showInvalidSaveWarning, setInvalidSaveWarning] = useState(false);
    const [showSuccessfulSaveInfo, setSuccessfulSaveInfo] = useState(false);
    const [showSubmissionErrorWarning, setSubmissionErrorWarning] =
        useState(false);
    const [showEvaluationErrorWarning, setEvaluationErrorWarning] =
        useState(false);

    const { submissionData } = useLatestSubmission({ authToken, assessment });

    const formMethods = useForm({
        resolver: dataSchema,
        defaultValues: mergeDeep(formDef?.defaultValues, submissionData?.data),
        mode: "onBlur", // validate fields on blur
    });
    const {
        handleSubmit,
        trigger,
        getValues,
        watch,
        formState: { errors, isValidating, isFormStateSubmitting },
    } = formMethods;

    // Print errors to Console when they change
    useEffect(() => {
        console.log("Validation errors", errors);
    }, [errors]);

    // If existing submission data is available (from previous save), trigger validation once to show errors
    useEffect(() => {
        if (submissionData && !submissionData?.is_prefilled_data) {
            trigger();
            setIsFreshAssignment(false);
        }
    }, [submissionData]);

    const showPrivacyStatement = assessmentData?.survey
        ? assessmentData.survey.show_privacy_statement
        : true;
    const [isFreshAssignment, setIsFreshAssignment] = useState(true);
    const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
    const [showPrivacyStatementModal, setShowPrivacyStatementModal] =
        useState(false);

    // Use scrollspy
    useScrollSpy("#scroll-form-navbar");

    const context = {
        assessment: assessmentData,
    };

    const [
        postSubmission,
        { isLoading: isSubmitting, isError: isSubmissionError },
    ] = usePostSubmissionMutation();

    const [
        postEvaluate,
        {
            isLoading: isEvaluating,
            isSuccess: isEvaluateSuccess,
            isError: isEvaluateError,
        },
    ] = usePostSubmissionEvaluateMutation({
        fixedCacheKey: "evaluate-submission",
    });

    useEffect(() => {
        if (isSubmissionError) setSubmissionErrorWarning(true);
    }, [isSubmissionError]);
    useEffect(() => {
        if (isEvaluateError) setEvaluationErrorWarning(true);
    }, [isEvaluateError]);

    const onValidSubmit = async (data) => {
        const result = await postSubmission({
            data: data,
            authToken: authToken,
            surveyAssignmentId: assessmentData.id,
            isValidComplete: true,
            validationErrors: cleanFormErrorsFromRefs(
                formMethods.formState.errors
            ),
        }).unwrap();

        console.log("Result of POST submission:", result);

        if (assessmentData?.survey.evaluation_inactive == false) {
            const result_evaluate = await postEvaluate({
                authToken: authToken,
                submissionId: result?.id,
            }).unwrap();
            console.log("Result of POST evaluate:", result_evaluate);

            dispatch(completeForm());
        } else {
            setSuccessfulSaveInfo(true);
        }
    };

    const onInvalidSubmit = async (errors) => {
        const result = await postSubmission({
            data: getValues(),
            authToken: authToken,
            surveyAssignmentId: assessmentData.id,
            isValidComplete: false,
            validationErrors: cleanFormErrorsFromRefs(errors),
        }).unwrap();

        console.log("Result of POST submission:", result);
        setInvalidSaveWarning(true);
    };

    const onSubmit = (event) => {
        const privacyStatementInvalid =
            showPrivacyStatement && isFreshAssignment && !isPrivacyAccepted;

        if (privacyStatementInvalid) {
            setShowPrivacyStatementModal(true);
        } else {
            handleSubmit(onValidSubmit, onInvalidSubmit)();
        }
        event.preventDefault();
        return false;
    };

    let loadingMessage = null;
    if (!errorMessage) {
        if (isValidating && isFormStateSubmitting)
            loadingMessage = /*i18n*/ i18n._("loading.validating_data");
        if (isSubmitting)
            loadingMessage = /*i18n*/ i18n._("loading.sending_data");
        if (isEvaluating)
            loadingMessage = /*i18n*/ i18n._("loading.waiting_for_results");
        if (isLoadingLanguage)
            loadingMessage = /*i18n*/ i18n._("loading.generic");
    }

    const _renderSectionBadge = (badgeName) => {
        const badge = deriveFormBadge(badgeName, watch);
        if (badge)
            return (
                <p className="float-end fs-4 fw-normal">
                    <Badge pill bg={badge?.color || "primary"}>
                        {badge?.icon && (
                            <i className={"bi bi-" + badge.icon}></i>
                        )}{" "}
                        {badge?.label}
                    </Badge>
                </p>
            );
        return null;
    };

    return (
        <Container className="mx-auto my-5" fluid="lg">
            {errorMessage && (
                <Alert key="warning" variant="warning" className="my-5">
                    <h5>{errorMessage.message}</h5>
                </Alert>
            )}

            {loadingMessage && <LoadingSpinner label={loadingMessage} />}

            <InfoModal
                title={i18n._("form.main.validation_error_header")}
                body={i18n._("form.main.validation_error_body")}
                show={showInvalidSaveWarning}
                onClose={() => setInvalidSaveWarning(false)}
            />

            <InfoModal
                title={i18n._("form.main.submission_error_header")}
                body={i18n._("form.main.submission_error_body")}
                show={showSubmissionErrorWarning}
                onClose={() => setSubmissionErrorWarning(false)}
            />

            <InfoModal
                title={i18n._("form.main.evaluation_error_header")}
                body={i18n._("form.main.evaluation_error_body")}
                show={showEvaluationErrorWarning}
                onClose={() => setEvaluationErrorWarning(false)}
            />

            <InfoModal
                title={i18n._("form.main.validation_success_header")}
                body={i18n._("form.main.validation_success_body")}
                show={showSuccessfulSaveInfo}
                onClose={() => {
                    setSuccessfulSaveInfo(false);
                    dispatch(initWelcome());
                }}
            />

            <FormProvider {...formMethods}>
                <RForm onSubmit={onSubmit} noValidate>
                    <Row className="gx-5">
                        <Col xs={3} className="border-end d-none d-lg-block">
                            <div
                                id="sticky-section-menu"
                                className="sticky-top"
                            >
                                <p className="fs-4 my-0">
                                    <Trans id="form.main.ghg_inventory_title" />{" "}
                                    {assessmentData?.survey?.year}
                                </p>
                                {!assessmentData?.survey?.title && (
                                    <p className="fs-5">
                                        {assessmentData?.survey?.crop?.name}
                                    </p>
                                )}
                                {assessmentData?.survey?.title && (
                                    <p className="fs-5">
                                        {assessmentData?.survey?.title}
                                    </p>
                                )}
                                <ListGroup
                                    defaultActiveKey="#p0"
                                    variant="flush"
                                    className="my-5"
                                    id="scroll-form-navbar"
                                >
                                    {(formDef?.uiSchema || [])
                                        .filter((s) => s.type == "section")
                                        .map((s, i) => (
                                            <ListGroup.Item
                                                action
                                                href={`#s${i}`}
                                                key={i}
                                            >
                                                {i + 1}.{" "}
                                                {fieldLabel(s, s.name, {
                                                    context,
                                                })}{" "}
                                                {s.name in errors && (
                                                    <>&#9888;</>
                                                )}
                                            </ListGroup.Item>
                                        ))}
                                </ListGroup>
                                <p>
                                    <Button variant="primary" type="submit">
                                        <Trans id="form.main.save_button" />
                                    </Button>{" "}
                                    <Button
                                        variant="outline-primary"
                                        type="button"
                                        className="d-print-none"
                                        onClick={() => window.print()}
                                    >
                                        <Trans id="form.main.print_button" />
                                    </Button>
                                </p>
                            </div>
                        </Col>
                        <Col className="form-content">
                            <div className="d-block d-lg-none">
                                <h1 className="my-0">
                                    <Trans id="form.main.ghg_inventory_title" />{" "}
                                    {assessmentData?.survey?.year}
                                </h1>
                                <h2>{assessmentData?.survey?.crop?.name}</h2>
                            </div>
                            {(formDef?.uiSchema || [])
                                .filter((s) => s.type == "section")
                                .map((s, i) => (
                                    <div
                                        className="form-section border-bottom mb-4 pb-4"
                                        id={`s${i}`}
                                        key={i}
                                    >
                                        {_renderSectionBadge(s?.badge)}
                                        <h2 className="mt-5 mb-3">
                                            {i + 1}.{" "}
                                            {fieldLabel(s, s.name, { context })}
                                        </h2>
                                        <FormFieldRenderer
                                            dataSchema={formDef?.dataSchema}
                                            uiSchema={s}
                                            defaultValues={
                                                formDef?.defaultValues
                                            }
                                            context={context}
                                        />
                                    </div>
                                ))}
                            {showPrivacyStatement && (
                                <div className="form-section mb-4">
                                    <PrivacyStatement
                                        isFreshAssignment={isFreshAssignment}
                                        isPrivacyAccepted={isPrivacyAccepted}
                                        setIsPrivacyAccepted={
                                            setIsPrivacyAccepted
                                        }
                                        showPrivacyStatementModal={
                                            showPrivacyStatementModal
                                        }
                                        setShowPrivacyStatementModal={
                                            setShowPrivacyStatementModal
                                        }
                                        handleSubmit={onSubmit}
                                    />
                                </div>
                            )}
                            <Button variant="primary" type="submit">
                                <Trans id="form.main.save_button" />
                            </Button>{" "}
                            <Button
                                variant="outline-primary"
                                type="button"
                                className="d-print-none"
                                onClick={() => window.print()}
                            >
                                <Trans id="form.main.print_button" />
                            </Button>
                        </Col>
                    </Row>
                </RForm>
            </FormProvider>
        </Container>
    );
}
