import { number, object, string } from "yup";
import { cropLabel } from "../../../utils/crops";
import { formatNumber } from "../../../utils/format";
import { unitShortLabel } from "../../../utils/schemaUtils";

export const cropGenericSchema = function (
    { surveyParams },
    {
        selectOptions,
        getOption,
        defaultUnit,
        resolveFractionUnits,
        testTotalsAgainstWeightAreaRate,
    }
) {
    const group = "crop_generic";

    // The surveyParams can be used to configure a survey in the admin space of the app. Use the exact spelling of the phrase after surveyParams.? to set the params in the admin view.
    const isAbsolute = surveyParams?.CROP_YIELD_ABSOLUTE == "true";
    const yieldMinTHa =
        parseFloat(surveyParams?.CROP_FRESH_YIELD_MIN_T_HA) || 0;
    const yieldMaxTHa =
        parseFloat(surveyParams?.CROP_FRESH_YIELD_MAX_T_HA) || 100000;

    const enableFieldHarvestedCropPart =
        surveyParams?.ENABLE_FIELD_HARVESTED_CROP_PART == "true";
    const enableFieldWildCollection =
        surveyParams?.ENABLE_FIELD_WILD_COLLECTION == "true";
    const disableFieldCoProducts =
        surveyParams?.DISABLE_FIELD_COPRODUCTS == "true";
    const disableFieldNetYield =
        surveyParams?.DISABLE_FIELD_NET_YIELD == "true";
    const enableFieldCroppingSystem =
        surveyParams?.ENABLE_FIELD_CROPPING_SYSTEM == "true";
    const enableFieldCropRotation =
        surveyParams?.ENABLE_FIELD_CROP_ROTATION == "true";

    let data = object({
        area: number().required().positive(),
        area__unit: string().required().oneOfOptions("area"),
        ...(!isAbsolute && {
            yield_gross_per_area: number()
                .required()
                .positive()
                .limitsWithUnit("weight_area_rate", "yield_gross_per_area", {
                    unit: "tonne / ha",
                    min: yieldMinTHa,
                    max: yieldMaxTHa,
                }),
            yield_gross_per_area__unit: string()
                .required()
                .oneOfOptions("weight_area_rate"),
            yield_net_per_area: number()
                .required()
                .positive()
                .limitsWithUnit("weight_area_rate", "yield_net_per_area", {
                    unit: "tonne / ha",
                    min: yieldMinTHa,
                    max: yieldMaxTHa,
                }),
            yield_net_per_area__unit: string()
                .required()
                .oneOfOptions("weight_area_rate"),
        }),
        ...(enableFieldCroppingSystem && {
            cropping_system: string()
                .oneOfOptions("cropping_system", true)
                .requiredWhen(
                    "is_wild_collected",
                    (is_wild_collected) => is_wild_collected != "yes"
                ),
            has_marketable_other_crops: string()
                .oneOfOptions(selectOptions("yes_no"), true)
                .requiredWhen(
                    ["is_wild_collected", "cropping_system"],
                    (is_wild_collected, cropping_system) =>
                        is_wild_collected != "yes" &&
                        [
                            "mixed_cropping",
                            "agroforestry",
                            "mixed_cropping_agroforestry",
                        ].includes(cropping_system)
                ),
            value_share_other_crops: number()
                .min(0)
                .max(99)
                .nanToUndefined()
                .requiredWhen("has_marketable_other_crops", (v) => v == "yes"),
        }),
        ...(isAbsolute && {
            yield_gross: number()
                .required()
                .positive()
                .test({
                    name: "yield_absolute_limits",
                    test: testTotalsAgainstWeightAreaRate(
                        yieldMinTHa,
                        yieldMaxTHa,
                        "yield_gross",
                        "area"
                    ),
                    message:
                        "Area yield may not be lower than ${min} or higher than ${max} t/ha.",
                    params: { min: yieldMinTHa, max: yieldMaxTHa },
                }),
            yield_gross__unit: string().required().oneOfOptions("weight"),
            yield_net: number()
                .required()
                .positive()
                .test({
                    name: "yield_absolute_limits",
                    test: testTotalsAgainstWeightAreaRate(
                        yieldMinTHa,
                        yieldMaxTHa,
                        "yield_net",
                        "area"
                    ),
                    message:
                        "Area yield may not be lower than ${min} or higher than ${max} t/ha.",
                    params: { min: yieldMinTHa, max: yieldMaxTHa },
                }),
            yield_net__unit: string().required().oneOfOptions("weight"),
        }),
        ...(enableFieldHarvestedCropPart && {
            harvested_plant_part: string()
                .required()
                .oneOfOptions(selectOptions("harvested_plant_part")),
        }),
        ...(enableFieldWildCollection && {
            is_wild_collected: string()
                .required()
                .oneOfOptions(selectOptions("yes_no")),
            harvested_ecosystem: string()
                .oneOfOptions("harvested_ecosystem", true)
                .requiredWhen("is_wild_collected", (v) => v == "yes"),
            other_ecosystem_description: string().requiredWhen(
                "harvested_ecosystem",
                (v) => v == "other"
            ),
        }),
        ...(!disableFieldCoProducts && {
            has_coproducts: string()
                .required()
                .oneOfOptions(selectOptions("yes_no")),
            coproducts_relative_value: number()
                .min(0)
                .max(99)
                .nanToUndefined()
                .notRequired()
                .requiredWhen("has_coproducts", (v) => v == "yes"),
            coproducts_description: string().notRequired(),
        }),
        ...(enableFieldCropRotation && {
            is_crop_rotation: string()
                .required()
                .oneOfOptions(selectOptions("yes_no")),
            crop_rotation_crops: number()
                .min(2)
                .max(15)
                .nanToUndefined()
                .notRequired()
                .requiredWhen("is_crop_rotation", (v) => v == "yes"),
        }),
    });

    if (disableFieldNetYield) {
        // If Net Yield field is disabled, gross yield is automatically used as fallback value via a transform
        data = data.transform((obj, originalObj) => {
            if (isAbsolute) {
                return {
                    ...obj,
                    yield_net: obj["yield_gross"],
                    yield_net__unit: obj["yield_gross__unit"],
                };
            } else {
                return {
                    ...obj,
                    yield_net_per_area: obj["yield_gross_per_area"],
                    yield_net_per_area__unit: obj["yield_gross_per_area__unit"],
                };
            }
        });
    }

    const defaults = {
        area__unit: defaultUnit("area", surveyParams?.UNIT_SYSTEM).name,
        yield_gross__unit: defaultUnit("weight", surveyParams?.UNIT_SYSTEM)
            .name,
        yield_net__unit: defaultUnit("weight", surveyParams?.UNIT_SYSTEM).name,
        yield_gross_per_area__unit: defaultUnit(
            "weight_area_rate",
            surveyParams?.UNIT_SYSTEM
        ).name,
        yield_net_per_area__unit: defaultUnit(
            "weight_area_rate",
            surveyParams?.UNIT_SYSTEM
        ).name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Cultivation",
        label__de: "Anbau",
        children: [
            {
                type: "number",
                name: `${group}.area`,
                label: "Cultivated area for {{crop_name}}",
                label__de: "Anbaufläche für {{crop_name}}",
                unit: selectOptions("area"),
                hint: "Without flower strips",
                hint__de: "Ohne Blühstreifen",
                widthLg: 4,
            },
            !isAbsolute && {
                type: "number",
                name: `${group}.yield_gross_per_area`,
                label: "Fresh yield",
                label__de: "Frischerntemenge",
                hint: "Before drying, sorting, grading, etc.",
                hint__de: "Vor Trocknung, Sortierung, etc.",
                unit: selectOptions("weight_area_rate"),
                widthLg: 4,
                checkText__de: "Errechnete Gesamt-Ernte",
                checkText: "Calculated total yield",
                checkValue: (watch) => {
                    const total =
                        watch(`${group}.yield_gross_per_area`) *
                            watch(`${group}.area`) || 0;
                    const fractionUnits = resolveFractionUnits(
                        "weight_area_rate",
                        watch(`${group}.yield_gross_per_area__unit`)
                    );
                    if (!fractionUnits) return "-";
                    const { numeratorUnit } = fractionUnits;

                    return `${formatNumber(total, 2)} ${numeratorUnit}`;
                },
            },
            !isAbsolute &&
                !disableFieldNetYield && {
                    type: "number",
                    name: `${group}.yield_net_per_area`,
                    label: "Final product amount",
                    label__de: "Menge des Endprodukts",
                    hint: "After on-farm drying, sorting, grading, etc.",
                    hint__de: "Nach Trocknung, Sortierung, etc.",
                    unit: selectOptions("weight_area_rate"),
                    widthLg: 4,
                    styleBreakRowAfter: true,
                    checkText__de: "Errechnete Gesamt-Ernte",
                    checkText: "Calculated total yield",
                    checkValue: (watch) => {
                        const total =
                            watch(`${group}.yield_net_per_area`) *
                                watch(`${group}.area`) || 0;
                        const fractionUnits = resolveFractionUnits(
                            "weight_area_rate",
                            watch(`${group}.yield_net_per_area__unit`)
                        );
                        if (!fractionUnits) return "-";
                        const { numeratorUnit } = fractionUnits;

                        return `${formatNumber(total, 2)} ${numeratorUnit}`;
                    },
                },
            isAbsolute && {
                type: "number",
                name: `${group}.yield_gross`,
                label: "Fresh yield",
                label__de: "Frischerntemenge",
                hint: "Before drying, sorting, grading, etc.",
                hint__de: "Vor Trocknung, Sortierung, etc.",
                unit: selectOptions("weight"),
                widthLg: 4,
                checkText__de: "Errechneter Flächenertrag",
                checkText: "Calculated area yield",
                checkValue: (watch) => {
                    if (watch(`${group}.area`, 0) == 0) return "-";
                    const rate =
                        watch(`${group}.yield_gross`, 0) /
                            watch(`${group}.area`, 0) || 0;
                    const yield_unit = unitShortLabel(
                        getOption("weight", watch(`${group}.yield_gross__unit`))
                    );
                    const area_unit = unitShortLabel(
                        getOption("area", watch(`${group}.area__unit`))
                    );
                    const unit = `${yield_unit} / ${area_unit}`;
                    return `${formatNumber(rate, 2)} ${unit}`;
                },
            },
            isAbsolute &&
                !disableFieldNetYield && {
                    type: "number",
                    name: `${group}.yield_net`,
                    label: "Final product amount",
                    label__de: "Menge des Endprodukts",
                    hint: "After on-farm drying, sorting, grading, etc.",
                    hint__de: "Nach Trocknung, Sortierung, etc.",
                    unit: selectOptions("weight"),
                    widthLg: 4,
                    styleBreakRowAfter: true,
                    checkText__de: "Errechneter Flächenertrag",
                    checkText: "Calculated area yield",
                    checkValue: (watch) => {
                        if (watch(`${group}.area`, 0) == 0) return "-";
                        const rate =
                            watch(`${group}.yield_net`, 0) /
                                watch(`${group}.area`, 0) || 0;
                        const yield_unit = unitShortLabel(
                            getOption(
                                "weight",
                                watch(`${group}.yield_net__unit`)
                            )
                        );
                        const area_unit = unitShortLabel(
                            getOption("area", watch(`${group}.area__unit`))
                        );
                        const unit = `${yield_unit} / ${area_unit}`;
                        return `${formatNumber(rate, 2)} ${unit}`;
                    },
                },
            enableFieldHarvestedCropPart && {
                type: "select_one",
                name: `${group}.harvested_plant_part`,
                label: "Harvested plant part",
                label__de: "Geernteter Pflanzenteil",
                options: selectOptions("harvested_plant_part"),
                widthLg: 4,
            },
            enableFieldWildCollection && {
                type: "select_one",
                name: `${group}.is_wild_collected`,
                label: "Is the crop collected wild?",
                label__de: "Wird das Produkt wild gesammelt?",
                options: selectOptions("yes_no"),
                widthLg: 4,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
                info: {
                    title: "What is wild collection?",
                    text: "If the product is harvested from plants growing naturally and for which no cultivation practices, such as seeding, fertilization, pesticide application etc. are performed, it is derived from wild collection.",
                    title__de: "Was ist Wildsammlung?",
                    text__de:
                        "Erntegut von Flächen, die keinen Landbewirtschaftungspraktiken wie Aussaat, Bodenbearbeitung, Düngemittelausbringung, Schnitt etc. unterliegen, stammt aus Wildsammlung.",
                },
            },

            enableFieldWildCollection && {
                type: "select_one",
                name: `${group}.harvested_ecosystem`,
                label: "From which ecosystem are you harvesting {{crop_name}}?",
                options: selectOptions("harvested_ecosystem"),
                widthLg: 5,
                condition: (watch) =>
                    watch(`${group}.is_wild_collected`) == "yes",
            },

            enableFieldWildCollection && {
                type: "text",
                name: `${group}.other_ecosystem_description`,
                label: "Description of other ecosystem",
                widthLg: 5,
                condition: (watch) =>
                    watch(`${group}.is_wild_collected`) == "yes" &&
                    watch(`${group}.harvested_ecosystem`) == "other",
            },

            enableFieldCroppingSystem && {
                type: "note",
                label: "Cropping system",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
                condition: (watch) =>
                    watch(`${group}.is_wild_collected`) != "yes",
            },
            enableFieldCroppingSystem && {
                type: "select_one",
                name: `${group}.cropping_system`,
                label: "Cropping system",
                hint: "Is the crop grown exclusively as a monoculture, or at least partially in a mixed cropping or agroforestry system?",
                options: selectOptions("cropping_system"),
                widthLg: 6,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    !enableFieldWildCollection ||
                    watch(`${group}.is_wild_collected`) != "yes",
            },
            enableFieldCroppingSystem && {
                type: "select_one",
                name: `${group}.has_marketable_other_crops`,
                label: "Do you produce any other marketable crops on the mixed cropping or agroforestry system besides {{crop_name}}?",
                hint: "E.g., if you grow lentils on the same field as wheat, with wheat being the main product, and you also sell the lentils",
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                widthLg: 4,
                condition: (watch) =>
                    (!enableFieldWildCollection ||
                        watch(`${group}.is_wild_collected`) != "yes") &&
                    [
                        "mixed_cropping",
                        "agroforestry",
                        "mixed_cropping_agroforestry",
                    ].includes(watch(`${group}.cropping_system`)),
            },
            enableFieldCroppingSystem && {
                type: "number",
                name: `${group}.value_share_other_crops`,
                label: "Value share of other marketable crops grown on same field(s) as {{crop_name}}",
                hint: 'Only include amounts of other crops that are grown on same field(s) as {{crop_name}}. Click on "?" for further explanation.',
                unit: "%",
                widthLg: 4,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    (!enableFieldWildCollection ||
                        watch(`${group}.is_wild_collected`) != "yes") &&
                    [
                        "mixed_cropping",
                        "agroforestry",
                        "mixed_cropping_agroforestry",
                    ].includes(watch(`${group}.cropping_system`)) &&
                    watch(`${group}.has_marketable_other_crops`) == "yes",
                //condition: (watch) => watch(`${group}.cropping_system`) == "mixed_cropping" || watch(`${group}.cropping_system`) == "agroforestry" || watch(`${group}.cropping_system`) == "mixed_cropping_agroforestry",
                info: {
                    title: "Example of determining value share",
                    text: "Wheat is grown on 4 hectares, 2 hectares of which are mixed-cropping. The total value of the amount of wheat and its co-product straw grown on the 4 hectares is 7,000$. The value of the other crops grown on the 2 hectares mixed cropping system is 3,000$. The total value of all products from the 4 hectares amounts to 10,000$. The value share of other products to be entered here is therefore 30%.",
                },
            },

            !disableFieldCoProducts && {
                type: "note",
                label: "Co-products",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
            },
            !disableFieldCoProducts && {
                type: "select_one",
                name: `${group}.has_coproducts`,
                label: "Do any marketable co-products occur from the production of the crop?",
                label__de:
                    "Entstehen beim Anbau der Kultur vermarktbare Nebenprodukte?",
                hint: "Examples: sold or self-used straw, forages or other materials",
                hint__de:
                    "Beispiele: verkauftes oder selbst genutztes Stroh, Futter oder andere Materialien",
                options: selectOptions("yes_no"),
                widthLg: 4,
                styleSelectHorizontalButtons: true,
            },
            !disableFieldCoProducts && {
                type: "number",
                name: `${group}.coproducts_relative_value`,
                label: "Value share of co-products from growing {{crop_name}}",
                label__de: "Wertanteil der Nebenprodukte",
                hint: "Example: 10% straw and 90% grain",
                hint__de: "Beispiel: 10% Stroh und 90% Getreide",
                unit: "%",
                widthLg: 4,
                condition: (watch) => watch(`${group}.has_coproducts`) == "yes",
                checkText__de: "Errechneter Wertanteil des Hauptprodukts",
                checkText: "Calculated value share of main product",
                checkValue: (watch) => {
                    const val = watch(`${group}.coproducts_relative_value`, 0);
                    if (val < 0 || val > 100) return "-";
                    return `${formatNumber(
                        100 -
                            (watch(`${group}.coproducts_relative_value`, 0) ||
                                0),
                        0
                    )} %`;
                },
            },
            !disableFieldCoProducts && {
                type: "text",
                name: `${group}.coproducts_description`,
                label: "Name of co-products",
                label__de: "Bezeichnung der Nebenprodukte",
                widthLg: 4,
                styleBreakRowAfter: true,
                condition: (watch) => watch(`${group}.has_coproducts`) == "yes",
            },
            enableFieldCropRotation && {
                type: "note",
                label: "Crop rotation",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
            },
            enableFieldCropRotation && {
                type: "select_one",
                name: `${group}.is_crop_rotation`,
                label: "Is {{crop_name}} grown as part of a crop rotation?",
                hint: "Select no if {{crop_name}} is grown on the same plot year after year.",
                options: selectOptions("yes_no"),
                widthLg: 5,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
            },
            enableFieldCropRotation && {
                type: "number",
                name: `${group}.crop_rotation_crops`,
                label: "How many different crops are in the crop rotation?",
                hint: "Number should include {{crop_name}}",
                widthLg: 5,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.is_crop_rotation`) == "yes",
            },
        ],
    };

    return { group, data, ui, defaults };
};
