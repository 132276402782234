import { Trans, useLingui } from "@lingui/react";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import {
    useGetAssessmentStatisticsByIdQuery,
    useGetSurveyAssignmentStatisticsByIdQuery,
} from "../../services/api";
import { useAuthToken } from "../../services/hooks";
import { formatNumber } from "../../utils/format";
import {
    ghgInventorySummary,
    mapEmissionSource,
    totalEmissionsPerHectare,
    totalEmissionsPerTonne,
} from "../../utils/ghgInventory";
import { LoadingSpinner } from "../elements/LoadingSpinner";
import StackedBarChart from "../elements/StackedBarChart";
import { obtainChartColor } from "../../utils/charts";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { cropLabel } from "../../utils/crops";

export default function AssignmentBenchmarkChart({ assignment }) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const authToken = useAuthToken();

    const [referenceUnit, setReferenceUnit] = useState("per_kg"); // or "per_ha"

    const query = authToken // choose API endpoint depending on token auth or logged in user
        ? useGetAssessmentStatisticsByIdQuery
        : useGetSurveyAssignmentStatisticsByIdQuery;
    const {
        data: statistics,
        error,
        isLoading,
    } = query({
        id: assignment.id,
        authToken: authToken,
    });

    const prepareStatisticsDataForChart = (emissionList) => {
        const emissionsObj = {};
        if (!emissionList) return emissionsObj;

        for (let e of emissionList) {
            if (!(e.emission_source in emissionsObj))
                emissionsObj[e.emission_source] = 0;

            emissionsObj[e.emission_source] += e.emissions_co2e_kg;
        }
        return emissionsObj;
    };
    const preparedData = useMemo(() => {
        return {
            self_emissions: {
                per_kg: prepareStatisticsDataForChart(
                    statistics?.self_emissions?.per_kg?.details
                ),
                per_ha: prepareStatisticsDataForChart(
                    statistics?.self_emissions?.per_ha?.details
                ),
            },
            average_emissions: {
                per_kg: prepareStatisticsDataForChart(
                    statistics?.average_emissions?.per_kg?.details
                ),
                per_ha: prepareStatisticsDataForChart(
                    statistics?.average_emissions?.per_ha?.details
                ),
            },
            top_emissions: {
                per_kg: prepareStatisticsDataForChart(
                    statistics?.top_emissions?.per_kg?.details
                ),
                per_ha: prepareStatisticsDataForChart(
                    statistics?.top_emissions?.per_ha?.details
                ),
            },
        };
    }, [statistics]);

    const chartData = useMemo(() => {
        const allEmissionSources = Object.keys({
            ...preparedData?.self_emissions[referenceUnit],
            ...preparedData?.average_emissions[referenceUnit],
            ...preparedData?.top_emissions[referenceUnit],
        });
        const data = [
            { x: "Own", ...preparedData?.self_emissions[referenceUnit] },
            {
                x: "Average*",
                ...preparedData?.average_emissions[referenceUnit],
            },
            { x: "Top*", ...preparedData?.top_emissions[referenceUnit] },
        ];
        const datasets = allEmissionSources.map((e, i) => {
            const color = obtainChartColor(i);
            return {
                label: mapEmissionSource(e, i18n),
                data: data,
                backgroundColor: color?.backgroundColor,
                borderColor: color?.borderColor,
                borderWidth: 1,
                parsing: {
                    yAxisKey: e,
                },
            };
        });
        return {
            labels: ["Own", "Average*", "Top*"],
            datasets: datasets,
        };
    }, [preparedData, referenceUnit]);

    const noDataAvailableYet =
        !isLoading && (error || !statistics?.average_emissions);

    return (
        <>
            {isLoading && <LoadingSpinner inline={true} />}
            {noDataAvailableYet && (
                <p>
                    Noch nicht genug Daten für einen Vergleich verfügbar. Kommen
                    Sie später wieder und versuchen es erneut.
                </p>
            )}

            {!isLoading && !noDataAvailableYet && (
                <>
                    <p>
                        In der nachstehenden Vergleichsgrafik werden die
                        Emissionen eingeordnet. Neben Ihrer eigenen THG-Bilanz
                        wird die durchschnittliche Bilanz der teilnehmenden
                        Lieferanten gezeigt. Die Grafik zeigt außerdem, welche
                        THG-Bilanz die besten 20% der Lieferanten erreichen
                        (80%-Perzentil).
                    </p>
                    <div style={{ maxWidth: 6000 }} className="mt-4">
                        <h4 className="mb-0">Emissionen</h4>
                        <div className="w-100"></div>
                        <ButtonGroup
                            aria-label="Basic example"
                            size="sm"
                            className="my-1 float-end"
                        >
                            <Button
                                variant="outline-secondary"
                                onClick={() => setReferenceUnit("per_kg")}
                                active={referenceUnit == "per_kg"}
                            >
                                Per Tonne
                            </Button>
                            <Button
                                variant="outline-secondary"
                                onClick={() => setReferenceUnit("per_ha")}
                                active={referenceUnit == "per_ha"}
                            >
                                Per Hectare
                            </Button>
                        </ButtonGroup>
                        <h5 className="my-1">
                            kg CO<sub>2</sub>e{" "}
                            <Trans id="form_result.emission_by_source.per_tonne" />{" "}
                            {cropLabel(
                                assignment?.survey?.crop,
                                currentLanguage
                            )}
                        </h5>
                        <StackedBarChart data={chartData} />
                    </div>
                </>
            )}
        </>
    );
}
