import Button from "react-bootstrap/Button";
import RForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { getText } from "../../../utils/i18n";
import { Trans } from "@lingui/react";
import { DangerousMarkdown } from "../../elements/Markdown";

const PrivacyStatementCheckbox = ({
    isPrivacyAccepted,
    handleCheckboxChange,
}) => {
    return (
        <RForm.Check type={"checkbox"} id={`privacy-accepted`}>
            <RForm.Check.Input
                type={"checkbox"}
                checked={isPrivacyAccepted}
                onChange={handleCheckboxChange}
            />
            <RForm.Check.Label>
                <DangerousMarkdown
                    text={
                        getText(
                            "form_controls",
                            "",
                            "I agree with the data collection and with the privacy statement."
                        )?.message
                    }
                />
            </RForm.Check.Label>
        </RForm.Check>
    );
};

export const PrivacyStatement = ({
    isFreshAssignment,
    isPrivacyAccepted,
    setIsPrivacyAccepted,
    showPrivacyStatementModal,
    setShowPrivacyStatementModal,
    handleSubmit,
}) => {
    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            setIsPrivacyAccepted(true);
        } else {
            setIsPrivacyAccepted(false);
        }
    };

    const handleModalClose = (event) => {
        setShowPrivacyStatementModal(false);
    };

    if (!isFreshAssignment) {
        return;
    }

    return (
        <>
            <PrivacyStatementCheckbox
                isPrivacyAccepted={isPrivacyAccepted}
                handleCheckboxChange={handleCheckboxChange}
            />
            <Modal show={showPrivacyStatementModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            getText("form_controls", "", "Consent required")
                                ?.message
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PrivacyStatementCheckbox
                        isPrivacyAccepted={isPrivacyAccepted}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        <Trans id="modal_repeat.form_cancel" />
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={!isPrivacyAccepted}
                    >
                        <Trans id="form.main.save_button" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
