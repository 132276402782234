import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App.js";
import { store } from "./store";
import { LANGUAGE } from "./config";
import { switchLanguage } from "./utils/i18n";
import { useEffect } from "react";

const appDiv = document.getElementById("app");

const LanguageDetector = () => {
    const language = LANGUAGE;
    useEffect(() => switchLanguage(i18n, language), [language]);
    return;
};

const root = createRoot(appDiv); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <LanguageDetector />
        <I18nProvider i18n={i18n}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </I18nProvider>
    </Provider>
);
