import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export function InfoModal({ title, body, show, onClose }) {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
