import { boolean, number, object, string } from "yup";

export const wasteOffFarmProcessingSchema = function (
    { surveyParams, farm },
    { selectOptions }
) {
    const group = "waste_off_farm_processing";

    const data = object({
        generic_field_name: string().required(),
    });

    const defaults = {};

    const ui = {
        type: "section",
        name: group,
        label__de: "Generic Section Title",
        label: "Waste",
        children: [
            {
                type: "text",
                name: `${group}.generic_field_name`,
                label: "Generic Field",
                label__de: "Generisches Feld",
                widthLg: 5,
                styleBreakRowAfter: true,
            },
        ],
    };

    return { group, data, ui, defaults };
};
