import { number, object, string } from "yup";

export const energyGenericSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit }
) {
    const group = "energy_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const data = object({
        // multiselect field: cultivation steps involving machine use
        diesel_field: number().min(0).required(),
        diesel_field__unit: string().required().oneOfOptions("volume"),
        petrol_field: number().min(0).nanToUndefined().notRequired(),
        petrol_field__unit: string().notRequired().oneOfOptions("volume"),
        electricity_field: number().min(0).nanToUndefined().notRequired(),
        electricity_field__unit: string().notRequired().oneOfOptions("energy"),
        is_irrigated: string().required().oneOfOptions(selectOptions("yes_no")),
        is_irrigation_energy_known: string()
            .requiredWhen("is_irrigated", (v) => v == "yes")
            .oneOfOptions(selectOptions("yes_no"), true),
        diesel_irrigation: number().min(0).nanToUndefined().notRequired(),
        diesel_irrigation__unit: string().notRequired().oneOfOptions("volume"),
        electricity_irrigation: number().min(0).nanToUndefined().notRequired(),
        electricity_irrigation__unit: string()
            .notRequired()
            .oneOfOptions("energy"),
        irrigation_amount: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .requiredWhen(
                ["is_irrigated", "is_irrigation_energy_known"],
                (is_irrigated, is_irrigation_energy_known) =>
                    is_irrigated == "yes" && is_irrigation_energy_known == "no"
            ),
        irrigation_amount__unit: string()
            .notRequired()
            .conditionalOneOfOptions(
                "volume",
                ["is_irrigated", "is_irrigation_energy_known"],
                (is_irrigated, is_irrigation_energy_known) =>
                    is_irrigated == "yes" && is_irrigation_energy_known == "no"
            ),
        irrigation_water_source: string().conditionalOneOfOptions(
            "irrigation_water_source",
            ["is_irrigated", "is_irrigation_energy_known"],
            (is_irrigated, is_irrigation_energy_known) =>
                is_irrigated == "yes" && is_irrigation_energy_known == "no"
        ),
        irrigation_energy_source: string().conditionalOneOfOptions(
            "irrigation_energy_source",
            ["is_irrigated", "is_irrigation_energy_known"],
            (is_irrigated, is_irrigation_energy_known) =>
                is_irrigated == "yes" && is_irrigation_energy_known == "no"
        ),
        irrigation_method: string().conditionalOneOfOptions(
            "irrigation_method",
            ["is_irrigated", "is_irrigation_energy_known"],
            (is_irrigated, is_irrigation_energy_known) =>
                is_irrigated == "yes" && is_irrigation_energy_known == "no"
        ),
        has_onfarm_processing: string()
            .required()
            .oneOfOptions(selectOptions("yes_no")),
        diesel_processing: number().min(0).nanToUndefined().notRequired(),
        diesel_processing__unit: string().notRequired().oneOfOptions("volume"),
        petrol_processing: number().min(0).nanToUndefined().notRequired(),
        petrol_processing__unit: string().notRequired().oneOfOptions("volume"),
        electricity_processing: number().min(0).nanToUndefined().notRequired(),
        electricity_processing__unit: string()
            .notRequired()
            .oneOfOptions("energy"),
        coal_processing: number().min(0).nanToUndefined().notRequired(),
        coal_processing__unit: string().notRequired().oneOfOptions("weight"),
        natural_gas_processing: number().min(0).nanToUndefined().notRequired(),
        natural_gas_processing__unit: string()
            .notRequired()
            .oneOfOptions("energy"),
        oil_processing: number().min(0).nanToUndefined().notRequired(),
        oil_processing__unit: string().notRequired().oneOfOptions("volume"),
        propane_processing: number().min(0).nanToUndefined().notRequired(),
        propane_processing__unit: string().notRequired().oneOfOptions("volume"),
        biodiesel_processing: number().min(0).nanToUndefined().notRequired(),
        biodiesel_processing__unit: string()
            .notRequired()
            .oneOfOptions("volume"),
        bioethanol_processing: number().min(0).nanToUndefined().notRequired(),
        bioethanol_processing__unit: string()
            .notRequired()
            .oneOfOptions("volume"),
        wood_pellets_processing: number().min(0).nanToUndefined().notRequired(),
        wood_pellets_processing__unit: string()
            .notRequired()
            .oneOfOptions("weight"),
        fuel_wood_processing: number().min(0).nanToUndefined().notRequired(),
        fuel_wood_processing__unit: string()
            .notRequired()
            .oneOfOptions("weight"),

        biofuel_sustainably_sourced: string()
            .notRequired()
            .oneOfOptions("bioenergy_origin", true),
        wood_sustainably_sourced: string()
            .notRequired()
            .oneOfOptions("bioenergy_origin", true),

        electricity_purchased_renewable_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        electricity_generated_pv_wind_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
    });

    const defaults = {
        diesel_field__unit: defaultUnit("volume", unitSystem).name,
        petrol_field__unit: defaultUnit("volume", unitSystem).name,
        electricity_field__unit: defaultUnit("energy", unitSystem).name,
        diesel_irrigation__unit: defaultUnit("volume", unitSystem).name,
        electricity_irrigation__unit: defaultUnit("energy", unitSystem).name,
        irrigation_amount__unit: defaultUnit("volume", unitSystem).name,
        diesel_processing__unit: defaultUnit("volume", unitSystem).name,
        petrol_processing__unit: defaultUnit("volume", unitSystem).name,
        electricity_processing__unit: defaultUnit("energy", unitSystem).name,
        coal_processing__unit: defaultUnit("weight", unitSystem).name,
        natural_gas_processing__unit: defaultUnit("energy", unitSystem).name,
        oil_processing__unit: defaultUnit("volume", unitSystem).name,
        propane_processing__unit: defaultUnit("volume", unitSystem).name,
        biodiesel_processing__unit: defaultUnit("volume", unitSystem).name,
        bioethanol_processing__unit: defaultUnit("volume", unitSystem).name,
        wood_pellets_processing__unit: defaultUnit("weight", unitSystem).name,
        fuel_wood_processing__unit: defaultUnit("weight", unitSystem).name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Energy consumption",
        label__de: "Energieverbrauch",
        children: [
            {
                type: "number",
                name: `${group}.diesel_field`,
                label: "Diesel consumption of field machines",
                label__de: "Dieselverbrauch mit Feldmaschinen",
                hint: "Tractor/machine usage for all steps from cultivation of preceding cover crop until on-farm handling of crop post-harvest",
                hint__de:
                    "Alle Feldarbeiten inkl. vorheriger Zwischenfrucht und bis Ernte der Hauptkultur",
                unit: selectOptions("volume"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.petrol_field`,
                label: "Petrol/Gas consumption of field machines",
                label__de: "Benzinverbrauch mit Feldmaschinen",
                hint: "Tractor/machine usage for all steps from cultivation of preceding cover crop until on-farm handling of crop post-harvest",
                hint__de:
                    "Alle Feldarbeiten inkl. vorheriger Zwischenfrucht und bis Ernte der Hauptkultur",
                unit: selectOptions("volume"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.electricity_field`,
                label: "Electricity consumption of field equipment",
                label__de: "Stromverbrauch für Feldarbeiten",
                unit: selectOptions("energy"),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Irrigation",
                label__de: "Bewässerung",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
            },
            {
                type: "select_one",
                name: `${group}.is_irrigated`,
                label: "Do you irrigate the crop?",
                label__de: "Bewässern Sie die Kultur?",
                options: selectOptions("yes_no"),
                widthLg: 4,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
            },
            {
                type: "select_one",
                name: `${group}.is_irrigation_energy_known`,
                label: "Do you know the consumed energy amounts for irrigation?",
                label__de: "Kennen Sie die Energieverbräuche zur Bewässerung?",
                options: selectOptions("yes_no"),
                widthLg: 6,
                condition: (watch) => watch(`${group}.is_irrigated`) == "yes",
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
            },
            {
                type: "number",
                name: `${group}.diesel_irrigation`,
                label: "Diesel consumption for irrigation",
                label__de: "Dieselverbrauch für Bewässerung",
                hint: "E.g., for pumps",
                hint__de: "Zum Beispiel für Pumpen",
                unit: selectOptions("volume"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.is_irrigated`) == "yes" &&
                    watch(`${group}.is_irrigation_energy_known`) == "yes",
            },
            {
                type: "number",
                name: `${group}.electricity_irrigation`,
                label: "Electricity consumption for irrigation",
                label__de: "Stromverbrauch für Bewässerung",
                hint: "E.g., for pumps",
                hint__de: "Zum Beispiel für Pumpen",
                unit: selectOptions("energy"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.is_irrigated`) == "yes" &&
                    watch(`${group}.is_irrigation_energy_known`) == "yes",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.irrigation_amount`,
                label: "Irrigation amount",
                label__de: "Bewässerungsmenge",
                unit: selectOptions("volume"),
                widthLg: 3,
                condition: (watch) =>
                    watch(`${group}.is_irrigated`) == "yes" &&
                    watch(`${group}.is_irrigation_energy_known`) == "no",
                // TODO: make check calculation work with new unit system
                /*checkText__de: "Errechnete Bewässerungsmenge je Fläche",
                checkText: "Calculated irrigation amount per area",
                checkValue: (watch) => {
                    if (watch("crop.area", 0) == 0) return "-";
                    return `${formatNumber(
                        watch(`${group}.irrigation_amount`, 0) /
                            watch("crop.area", 0) || 0,
                        0
                    )} m³/ha`;
                },*/
                styleBreakRowAfter: false,
            },
            {
                type: "select_one",
                name: `${group}.irrigation_water_source`,
                label: "Irrigation water source",
                label__de: "Wasserquelle für Bewässerung",
                options: selectOptions("irrigation_water_source"),
                widthLg: 3,
                condition: (watch) =>
                    watch(`${group}.is_irrigated`) == "yes" &&
                    watch(`${group}.is_irrigation_energy_known`) == "no",
            },
            {
                type: "select_one",
                name: `${group}.irrigation_energy_source`,
                label: "Irrigation energy source",
                label__de: "Energiequelle für Bewässerung",
                options: selectOptions("irrigation_energy_source"),
                widthLg: 3,
                condition: (watch) =>
                    watch(`${group}.is_irrigated`) == "yes" &&
                    watch(`${group}.is_irrigation_energy_known`) == "no",
            },
            {
                type: "select_one",
                name: `${group}.irrigation_method`,
                label: "Irrigation method",
                label__de: "Bewässerungsmethode",
                options: selectOptions("irrigation_method"),
                widthLg: 3,
                condition: (watch) =>
                    watch(`${group}.is_irrigated`) == "yes" &&
                    watch(`${group}.is_irrigation_energy_known`) == "no",
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "On-farm processing and storage",
                label__de: "Verarbeitung und Lagerung auf dem Betrieb",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
            },
            {
                type: "select_one",
                name: `${group}.has_onfarm_processing`,
                label: "Do you treat or process the crop on your farm, e.g., drying, grading, sorting or washing of the crop?",
                label__de:
                    "Behandeln oder verarbeiten Sie die Kultur auf dem Hof, z.B. Trocknen, Sortieren oder Waschen der Ernte?",
                options: selectOptions("yes_no"),
                widthLg: 7,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
            },
            {
                type: "number",
                name: `${group}.diesel_processing`,
                label: "Diesel consumption for processing",
                label__de: "Dieselverbrauch für Verarbeitung",
                unit: selectOptions("volume"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.petrol_processing`,
                label: "Petrol/Gas consumption for processing",
                label__de: "Benzinverbrauch für Verarbeitung",
                unit: selectOptions("volume"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.electricity_processing`,
                label: "Electricity consumption for processing",
                label__de: "Stromverbrauch für Verarbeitung",
                unit: selectOptions("energy"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.coal_processing`,
                label: "Coal usage",
                label__de: "Kohleverbrauch",
                unit: selectOptions("weight"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.natural_gas_processing`,
                label: "Natural gas consumption",
                label__de: "Erdgasverbrauch",
                unit: selectOptions("energy"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.oil_processing`,
                label: "(Fuel) Oil consumption",
                label__de: "(Heiz-)Ölverbrauch",
                unit: selectOptions("volume"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.propane_processing`,
                label: "Propane consumption",
                label__de: "Propangas-Verbrauch",
                unit: selectOptions("volume"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.biodiesel_processing`,
                label: "Biodiesel usage",
                label__de: "Biodieselverbrauch",
                unit: selectOptions("volume"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.bioethanol_processing`,
                label: "Bioethanol consumption",
                label__de: "Bioethanolverbrauch",
                unit: selectOptions("volume"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.wood_pellets_processing`,
                label: "Wood pellets consumption",
                label__de: "Holzpellets-Verbrauch",
                unit: selectOptions("weight"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "number",
                name: `${group}.fuel_wood_processing`,
                label: "Fuel wood consumption",
                label__de: "Feuerholz-Verbrauch",
                unit: selectOptions("weight"),
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes",
            },
            {
                type: "select_one",
                name: `${group}.biofuel_sustainably_sourced`,
                label: "Is the used biodiesel/bioethanol from a sustainable origin?",
                label__de:
                    "Stammt der Biodiesel/das Bioethanol aus nachhaltigen Anbau?",
                options: selectOptions("bioenergy_origin"),
                widthLg: 6,
                styleSelectHorizontalButtons: false,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes" &&
                    (watch(`${group}.biodiesel_processing`) > 0 ||
                        watch(`${group}.bioethanol_processing`) > 0),
            },
            {
                type: "select_one",
                name: `${group}.wood_sustainably_sourced`,
                label: "Are the used wood pellets/fuel wood from a sustainable origin?",
                label__de:
                    "Stammt Feuerholz/Holzpellets aus nachhaltigen Anbau?",
                options: selectOptions("bioenergy_origin"),
                widthLg: 6,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: false,
                condition: (watch) =>
                    watch(`${group}.has_onfarm_processing`) == "yes" &&
                    (watch(`${group}.wood_pellets_processing`) > 0 ||
                        watch(`${group}.fuel_wood_processing`) > 0),
            },
            {
                type: "note",
                label: "Electricity source",
                label__de: "Stromquelle",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
                condition: (watch) =>
                    [
                        watch(`${group}.electricity_field`) > 0,
                        watch(`${group}.electricity_irrigation`) > 0,
                        watch(`${group}.electricity_processing`) > 0,
                        watch(`${group}.irrigation_energy_source`) ==
                            "electric",
                    ].includes(true),
            },
            {
                type: "number",
                name: `${group}.electricity_purchased_renewable_share`,
                label: "Percentage of electricity from renewable tariff",
                label__de: "Prozentteil des Stroms aus einem Grünstromvertrag",
                unit: "%",
                widthLg: 4,
                condition: (watch) =>
                    [
                        watch(`${group}.electricity_field`) > 0,
                        watch(`${group}.electricity_irrigation`) > 0,
                        watch(`${group}.electricity_processing`) > 0,
                        watch(`${group}.irrigation_energy_source`) ==
                            "electric",
                    ].includes(true),
            },
            {
                type: "number",
                name: `${group}.electricity_generated_pv_wind_share`,
                label: "Percentage of self-generated electricity from PV or wind",
                label__de:
                    "Prozentteil selbst-erzeugten Stroms aus PV oder Wind",
                unit: "%",
                widthLg: 4,
                condition: (watch) =>
                    [
                        watch(`${group}.electricity_field`) > 0,
                        watch(`${group}.electricity_irrigation`) > 0,
                        watch(`${group}.electricity_processing`) > 0,
                        watch(`${group}.irrigation_energy_source`) ==
                            "electric",
                    ].includes(true),
            },
        ],
    };

    return { group, data, ui, defaults };
};
