import { Trans, useLingui } from "@lingui/react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { usePostFarmMutation } from "../../services/api";
import { LoadingSpinner } from "../elements/LoadingSpinner";
import FarmForm from "../farm/FarmForm";

export default function AddFarmModal({
    show,
    setShow,
    addToSurveyId,
    resetList,
    isFarm = true,
}) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);

    const i18nKeyPrefix = isFarm ? "farms" : "supplier";

    const dispatch = useDispatch();

    const form = useForm({
        mode: "onBlur", // validate fields on blur
    });
    const { handleSubmit, watch } = form;

    useEffect(() => {
        if (!show) {
            form.reset({ name: "", country: watch("country") }); // reset form on modal close
        }
    }, [show]);

    const [postFarm, { isLoading: isSubmitting }] = usePostFarmMutation();

    const onValidSubmit = async (data) => {
        const result = await postFarm({
            name: data?.name,
            country: data?.country,
            addToSurveyId: addToSurveyId,
        }).unwrap();
        setShow(false);
        resetList();
    };

    const onInvalidSubmit = async (errors) => {
        // do nothing, render invalid states
    };

    const submitHandler = () => handleSubmit(onValidSubmit, onInvalidSubmit)();

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            {isSubmitting && <LoadingSpinner />}
            <Modal.Header closeButton>
                <Modal.Title>
                    <Trans id={`${i18nKeyPrefix}.form.modal_title`} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    defaultActiveKey="create_new"
                    id="fill-tab-example"
                    className="mb-3"
                >
                    <Tab
                        eventKey="create_new"
                        title={i18n._(`${i18nKeyPrefix}.form.create_new_tab`)}
                    >
                        <p>
                            <Trans
                                id={`${i18nKeyPrefix}.form.create_new_tab_text`}
                            />
                        </p>
                        <FarmForm
                            farm={null}
                            form={form}
                            submitHandler={submitHandler}
                        />
                    </Tab>
                    {/* <Tab eventKey="add_existing" title="Add existing farm" >
                        Add existing
                    </Tab> */}
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    <Trans
                        id={`${i18nKeyPrefix}.form.create_new_cancel_button`}
                    />
                </Button>
                <Button variant="primary" onClick={submitHandler}>
                    <Trans
                        id={`${i18nKeyPrefix}.form.create_new_save_button`}
                    />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
