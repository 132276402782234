import {
    useGetAssessmentByIdQuery,
    useGetAssessmentChoicesByIdQuery,
    useGetAssessmentFertilizersByIdQuery,
    useGetAssessmentUnitsByIdQuery,
    useGetCurrentUserQuery,
    useGetSubmissionByIdQuery,
} from "./api";

import {
    useInRouterContext,
    useParams,
    useSearchParams,
} from "react-router-dom";

export function useAuthToken() {
    if (useInRouterContext()) {
        const [searchParams] = useSearchParams();
        return searchParams.get("token");
    }
    return "";
}

export function useAssessment({ authToken }) {
    const urlParams = useParams();
    const {
        data: assessmentData,
        error: assessmentError,
        isLoading: assessmentIsLoading,
    } = useGetAssessmentByIdQuery({
        id: urlParams.assessmentId,
        authToken: authToken,
    });

    return {
        assessmentData,
        assessmentError,
        assessmentIsLoading,
    };
}

export function useAssessmentChoices({ authToken }) {
    const urlParams = useParams();
    const {
        data: choicesData,
        error: choicesError,
        isLoading: choicesIsLoading,
    } = useGetAssessmentChoicesByIdQuery({
        id: urlParams.assessmentId,
        authToken: authToken,
    });

    return {
        choicesData,
        choicesError,
        choicesIsLoading,
    };
}

export function useAssessmentFertilizers({ authToken }) {
    const urlParams = useParams();
    const {
        data: fertilizersData,
        error: fertilizersError,
        isLoading: fertilizersIsLoading,
    } = useGetAssessmentFertilizersByIdQuery({
        id: urlParams.assessmentId,
        authToken: authToken,
    });

    return {
        fertilizersData,
        fertilizersError,
        fertilizersIsLoading,
    };
}

export function useAssessmentUnits({ authToken }) {
    const urlParams = useParams();
    const {
        data: unitsData,
        error: unitsError,
        isLoading: unitsIsLoading,
    } = useGetAssessmentUnitsByIdQuery({
        id: urlParams.assessmentId,
        authToken: authToken,
    });

    return {
        unitsData,
        unitsError,
        unitsIsLoading,
    };
}

export function useAllAssessmentChoices({ authToken }) {
    const { choicesData, choicesIsLoading, choicesError } =
        useAssessmentChoices({
            authToken,
        });
    const { fertilizersData, fertilizersIsLoading, fertilizersError } =
        useAssessmentFertilizers({
            authToken,
        });
    const { unitsData, unitsIsLoading, unitsError } = useAssessmentUnits({
        authToken,
    });

    return {
        allChoices: [
            ...(choicesData || []),
            ...(fertilizersData || []),
            ...(unitsData || []),
        ],
        anyChoicesError: choicesError || fertilizersError || unitsError,
        anyChoicesAreLoading:
            choicesIsLoading || fertilizersIsLoading || unitsIsLoading,
    };
}

export function useSubmission({ authToken, submissionId }) {
    const {
        data: submissionData,
        error: submissionError,
        isLoading: submissionIsLoading,
    } = useGetSubmissionByIdQuery(
        {
            id: submissionId,
            authToken: authToken,
        },
        { skip: !submissionId } // do not execute request when we don't actually have a submission ID
    );

    return {
        submissionData,
        submissionError,
        submissionIsLoading,
    };
}

export function useLatestSubmission({ authToken, assessment }) {
    const { assessmentData, assessmentError, assessmentIsLoading } = assessment;
    const latest_submission = assessmentData?.latest_submission;

    return useSubmission({ authToken, submissionId: latest_submission?.id });
}

export function useSelfUser() {
    const {
        data: userData,
        error: userError,
        isLoading: userIsLoading,
    } = useGetCurrentUserQuery();

    return { userData, userError, userIsLoading };
}

export function useRequireAuthenticated() {
    const { userData, userIsLoading } = useSelfUser();

    if (!userIsLoading && (!userData || !("email" in userData))) {
        window.location.replace("/auth/login/");
        return false;
    }
    return true;
}
