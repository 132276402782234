import { Trans, useLingui } from "@lingui/react";
import structuredClone from "core-js-pure/actual/structured-clone";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { get, useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { reach } from "yup";
import { selectCurrentViewLanguage } from "../../../features/viewSlice";
import { formatNumber } from "../../../utils/format";
import { optionLabel } from "../../../utils/schemaUtils";
import ModalRepeatForm from "./ModelRepeatForm";

const NEW_ITEM = {
    data: {
        land_use_before: "no-change",
        tillage_before: "no-change",
        c_input_before: "no-change",
        year: null,
    },
    field: { id: "new-item" },
    index: -1,
};

export default function ModalRepeatCarbonStockChanges({
    dataSchema,
    uiSchema,
    defaultValues,
    context,
    parameters,
}) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const {
        watch,
        formState: { errors },
    } = useFormContext();
    const { fields, append, update, remove } = useFieldArray({
        name: uiSchema.name,
    });
    const subDataSchema = reach(dataSchema, uiSchema.name)?.innerType;
    if (!subDataSchema) {
        console.error("Data schema not found for repeat at path", uiSchema);
    }

    const subDefaultValues = get(defaultValues, `${uiSchema.name}__default`);

    const assessmentYear =
        context?.assessment?.survey?.year || new Date().getFullYear();
    const yearRange = [...Array(20).keys()].map((y, i) => assessmentYear - i);

    const { latest_land_use, latest_tillage, latest_c_input } = parameters;

    const [activeRepeatItem, setActiveRepeatItem] = useState(null);
    const handleCancel = () => setActiveRepeatItem(null);
    const handleShowModal = (item) => setActiveRepeatItem(item);
    const handleAddItem = (year) => {
        const newItem = structuredClone(NEW_ITEM);
        newItem.data = { ...newItem.data, year: year, ...subDefaultValues };
        setActiveRepeatItem(newItem);
    };
    const handleSaveItem = (item) => {
        if (item.index === -1) {
            append(item.data);
        } else {
            update(item.index, item.data);
        }
        setActiveRepeatItem(null);
    };

    const uiSchemaLandUse = uiSchema.children.find(
        (v) => v.name == "land_use_before"
    );
    const uiSchemaTillage = uiSchema.children.find(
        (v) => v.name == "tillage_before"
    );
    const uiSchemaCInput = uiSchema.children.find(
        (v) => v.name == "c_input_before"
    );

    // Temporary variables for iterating years
    let _landUse = latest_land_use;
    let _tillage = latest_tillage;
    let _cInput = latest_c_input;

    const yearBasedItems = {};
    for (let i = 0; i < fields.length; i++) {
        const yearData = watch(`${uiSchema.name}.${i}`);
        yearBasedItems[yearData.year] = {
            data: yearData,
            field: fields[i],
            index: i,
        };
    }

    const handleClickYear = (year) => {
        if (year in yearBasedItems) {
            const item = yearBasedItems[year];
            handleShowModal(item);
        } else {
            handleAddItem(year);
        }
    };

    return (
        <>
            {activeRepeatItem && (
                <ModalRepeatForm
                    dataSchema={subDataSchema}
                    uiSchema={uiSchema}
                    item={activeRepeatItem}
                    handleCancel={handleCancel}
                    handleSave={handleSaveItem}
                    title={`Landnutzung bis ${activeRepeatItem?.data?.year}`}
                />
            )}
            <p>
                <Trans id="carbon_stock_changes.instructions" />
            </p>
            <div></div>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>
                            <Trans id="carbon_stock_changes.table_header_year" />
                        </th>
                        <th>
                            <Trans id="carbon_stock_changes.table_header_land_use" />
                        </th>
                        <th>
                            <Trans id="carbon_stock_changes.table_header_tillage" />
                        </th>
                        <th>
                            <Trans id="carbon_stock_changes.table_header_c_input" />
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            <Trans id="carbon_stock_changes.current_status" />
                        </th>
                        <td>
                            {optionLabel(
                                uiSchemaLandUse,
                                latest_land_use,
                                currentLanguage
                            )}
                        </td>
                        <td>
                            {optionLabel(
                                uiSchemaTillage,
                                latest_tillage,
                                currentLanguage
                            )}
                        </td>
                        <td>
                            {optionLabel(
                                uiSchemaCInput,
                                latest_c_input,
                                currentLanguage
                            )}
                        </td>
                        <td></td>
                    </tr>
                    {yearRange.map((year, index) => {
                        let allocation = "";
                        let previousLandUse = "";
                        let previousTillage = "";
                        let previousCInput = "";
                        if (year in yearBasedItems) {
                            const itemData = yearBasedItems[year].data;
                            previousLandUse =
                                itemData.land_use_before != "no-change"
                                    ? itemData.land_use_before
                                    : "";
                            previousTillage =
                                itemData.tillage_before != "no-change"
                                    ? itemData.tillage_before
                                    : "";
                            previousCInput =
                                itemData.c_input_before != "no-change"
                                    ? itemData.c_input_before
                                    : "";
                            allocation = i18n.t(
                                "carbon_stock_changes.allocation_share",
                                {
                                    allocation_share: formatNumber(
                                        itemData.allocation_share,
                                        0
                                    ),
                                }
                            );
                        }
                        const comp = (
                            <tr
                                key={year}
                                onClick={() => handleClickYear(year)}
                            >
                                <th>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleClickYear(year)}
                                        size="sm"
                                    >
                                        <b>🢒 {year}</b>
                                    </Button>
                                </th>
                                <td style={{ height: "100%" }}>
                                    {previousLandUse && (
                                        <span>
                                            <Trans
                                                id="carbon_stock_changes.year_starting"
                                                values={{ year }}
                                            />{" "}
                                            {optionLabel(
                                                uiSchemaLandUse,
                                                _landUse,
                                                currentLanguage
                                            )}
                                            <br />
                                            <Trans
                                                id="carbon_stock_changes.year_until"
                                                values={{ year }}
                                            />{" "}
                                            {optionLabel(
                                                uiSchemaLandUse,
                                                previousLandUse,
                                                currentLanguage
                                            )}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {previousTillage && (
                                        <span>
                                            <Trans
                                                id="carbon_stock_changes.year_starting"
                                                values={{ year }}
                                            />{" "}
                                            {optionLabel(
                                                uiSchemaTillage,
                                                _tillage,
                                                currentLanguage
                                            )}
                                            <br />
                                            <Trans
                                                id="carbon_stock_changes.year_until"
                                                values={{ year }}
                                            />{" "}
                                            {optionLabel(
                                                uiSchemaTillage,
                                                previousTillage,
                                                currentLanguage
                                            )}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {previousCInput && (
                                        <span>
                                            <Trans
                                                id="carbon_stock_changes.year_starting"
                                                values={{ year }}
                                            />{" "}
                                            {optionLabel(
                                                uiSchemaCInput,
                                                _cInput,
                                                currentLanguage
                                            )}
                                            <br />
                                            <Trans
                                                id="carbon_stock_changes.year_until"
                                                values={{ year }}
                                            />{" "}
                                            {optionLabel(
                                                uiSchemaCInput,
                                                previousCInput,
                                                currentLanguage
                                            )}
                                        </span>
                                    )}
                                </td>
                                <td>{allocation}</td>
                            </tr>
                        );
                        if (year in yearBasedItems) {
                            _landUse =
                                previousLandUse != ""
                                    ? previousLandUse
                                    : _landUse;
                            _tillage =
                                previousTillage != ""
                                    ? previousTillage
                                    : _tillage;
                            _cInput =
                                previousCInput != "" ? previousCInput : _cInput;
                        }
                        return comp;
                    })}
                </tbody>
            </Table>
        </>
    );
}
