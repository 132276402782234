import { Trans, useLingui } from "@lingui/react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import {
    useListProductsQuery,
    usePostSurveyMutation,
    usePutSurveyMutation,
} from "../../services/api";
import { LoadingSpinner } from "../elements/LoadingSpinner";
import SurveyForm from "./SurveyForm";

export default function AddEditSurveyModal({
    show,
    closeModal,
    existingSurvey = null,
}) {
    const { i18n } = useLingui();

    const form = useForm({
        mode: "onBlur", // validate fields on blur
    });
    const { handleSubmit, watch } = form;

    const { data: productList } = useListProductsQuery({});

    useEffect(() => {
        if (!show) {
            form.reset(); // reset form on modal close
        }
    }, [show]);

    useEffect(() => {
        if (show && existingSurvey && productList) {
            form.reset({
                title: existingSurvey.title,
                year: `${existingSurvey.year}`,
                product: existingSurvey.crop.id,
                raw_material: existingSurvey.raw_material_name,
            });
        }
    }, [show, existingSurvey, productList]);

    const [postSurvey, { isLoading: isCreating }] = usePostSurveyMutation();
    const [putSurvey, { isLoading: isUpdating }] = usePutSurveyMutation();

    const onValidSubmit = async (data) => {
        if (!existingSurvey) {
            await postSurvey({
                title: data?.title,
                product: data?.product,
                raw_material: data?.raw_material,
                year: data?.year,
            }).unwrap();
        } else {
            await putSurvey({
                id: existingSurvey.id,
                title: data?.title,
                product: data?.product,
                raw_material: data?.raw_material,
                year: data?.year,
            }).unwrap();
        }
        closeModal();
    };

    const onInvalidSubmit = async (errors) => {
        // do nothing, render invalid states
    };

    const submitHandler = () => handleSubmit(onValidSubmit, onInvalidSubmit)();

    return (
        <>
            {(isCreating || isUpdating) && <LoadingSpinner />}
            <Modal show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {!existingSurvey && (
                            <Trans id="surveys.form.modal_create_title" />
                        )}
                        {existingSurvey && (
                            <Trans id="surveys.form.modal_update_title" />
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!existingSurvey && (
                        <p>
                            <Trans id="surveys.form.create_new_text" />
                        </p>
                    )}
                    <SurveyForm
                        survey={null}
                        form={form}
                        submitHandler={submitHandler}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        <Trans id="surveys.form.create_new_cancel_button" />
                    </Button>
                    <Button variant="primary" onClick={submitHandler}>
                        {!existingSurvey && (
                            <Trans id="surveys.form.create_new_save_button" />
                        )}
                        {existingSurvey && (
                            <Trans id="surveys.form.update_save_button" />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
