import { Trans, useLingui } from "@lingui/react";
import { Fragment } from "react";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { cropLabel } from "../../utils/crops";
import { formatNumber } from "../../utils/format";
import {
    ghgInventorySummary,
    totalEmissionsPerTonne,
    totalEmissionsPerHectare,
} from "../../utils/ghgInventory";

export default function EmissionsTable({
    assignment,
    evaluation = null,
    emissionDetails = null,
}) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);

    const latestEvaluation = evaluation
        ? evaluation
        : assignment?.latest_evaluation;

    const summary = ghgInventorySummary(
        latestEvaluation,
        emissionDetails,
        i18n,
        currentLanguage
    );

    const totalPerTonne = totalEmissionsPerTonne(latestEvaluation);
    const totalPerHa = totalEmissionsPerHectare(latestEvaluation);
    const totalPerTonneInclRemovals = totalEmissionsPerTonne(
        latestEvaluation,
        true
    );
    const totalPerHaInclRemovals = totalEmissionsPerHectare(
        latestEvaluation,
        true
    );

    const coProductEmissions =
        "co_product" in summary ? summary["co_product"] : null;

    function renderDetails(summary_result) {
        const r = summary_result;
        return r.details.map((d, index) => (
            <tr key={index} className="small text-muted">
                <td className="px-4 py-1">{d.label}</td>
                <td className="text-end">{formatNumber(d.tonne, 2)}</td>
                <td className="text-end">{formatNumber(d.hectare, 0)}</td>
                {d.tonne >= 0 && (
                    <td className="text-end">
                        {totalPerTonne
                            ? formatNumber((d.tonne / totalPerTonne) * 100, 0)
                            : "-"}
                        %
                    </td>
                )}
                {d.tonne < 0 && <td className="text-end">-</td>}
            </tr>
        ));
    }

    return (
        <Table hover size="sm">
            <thead className="table-light">
                <tr className="align-bottom">
                    <th>
                        <h4>
                            {cropLabel(
                                assignment?.survey?.crop,
                                currentLanguage
                            )}
                        </h4>
                        <Trans id="form_result.emission_by_source.table.emission_source" />
                    </th>
                    <td className="text-end">
                        <b>
                            <Trans id="form_result.emission_by_source.table.per_tonne" />
                        </b>
                        <br />
                        kg CO<sub>2</sub>e
                    </td>
                    <td className="text-end">
                        <b>
                            <Trans id="form_result.emission_by_source.table.per_hectare" />
                        </b>
                        <br />
                        kg CO<sub>2</sub>e
                    </td>
                    <th className="text-end">
                        <Trans id="form_result.emission_by_source.table.share" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(summary)
                    .filter((r_key) => r_key != "co_product")
                    .map((r_key, index) => {
                        const r = summary[r_key];
                        const has_details = r.details && r.details.length > 0;
                        return (
                            <Fragment key={index}>
                                <tr key={index}>
                                    <td>{r.label}</td>
                                    <td className="text-end">
                                        {formatNumber(r.tonne, 2)}
                                    </td>
                                    <td className="text-end">
                                        {formatNumber(r.hectare, 0)}
                                    </td>
                                    {r.tonne >= 0 && (
                                        <td className="text-end">
                                            {totalPerTonne
                                                ? formatNumber(
                                                      (r.tonne /
                                                          totalPerTonne) *
                                                          100,
                                                      0
                                                  )
                                                : "-"}
                                            %
                                        </td>
                                    )}
                                    {r.tonne < 0 && (
                                        <td className="text-end">-</td>
                                    )}
                                </tr>
                                {has_details && renderDetails(r)}
                            </Fragment>
                        );
                    })}
            </tbody>
            <tfoot className="table-light">
                <tr>
                    <th>
                        <Trans id="form_result.emission_by_source.table_footer.total" />
                    </th>
                    <th className="text-end">
                        {formatNumber(totalPerTonne, 2)}
                    </th>
                    <th className="text-end">{formatNumber(totalPerHa, 0)}</th>
                    <td className="text-end">100%</td>
                </tr>
                {totalPerHa != totalPerHaInclRemovals && (
                    <tr>
                        <td>
                            <Trans id="form_result.emission_by_source.table_footer.total_incl_removals" />
                        </td>
                        <td className="text-end">
                            {formatNumber(totalPerTonneInclRemovals, 2)}
                        </td>
                        <td className="text-end">
                            {formatNumber(totalPerHaInclRemovals, 0)}
                        </td>
                        <td className="text-end"></td>
                    </tr>
                )}
                {coProductEmissions && (
                    <tr>
                        <td>{coProductEmissions.label}</td>
                        <td className="text-end">
                            {formatNumber(coProductEmissions.tonne, 2)}
                        </td>
                        <td className="text-end">
                            {formatNumber(coProductEmissions.hectare, 0)}
                        </td>
                        <td className="text-end"></td>
                    </tr>
                )}
            </tfoot>
        </Table>
    );
}
