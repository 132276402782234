export function obtainChartColor(i = 0) {
    const colors = [
        // ["rgba(255, 99, 132, 0.2)", "rgba(255, 99, 132, 1)"],
        // ["rgba(54, 162, 235, 0.2)", "rgba(54, 162, 235, 1)"],
        // ["rgba(255, 206, 86, 0.2)", "rgba(255, 206, 86, 1)"],
        // ["rgba(75, 192, 192, 0.2)", "rgba(75, 192, 192, 1)"],
        // ["rgba(153, 102, 255, 0.2)", "rgba(153, 102, 255, 1)"],
        // ["rgba(255, 159, 64, 0.2)", "rgba(255, 159, 64, 1)"],
        ["#91B9A9cc", "#fff"],
        ["#FFD166cc", "#fff"],
        ["#274540cc", "#fff"],
        ["#E35B3Bcc", "#fff"],
        ["#E2EBEAcc", "#fff"],
        ["#660066cc", "#fff"],
        ["#C00052cc", "#fff"],
        ["#457B9Dcc", "#fff"],
    ];
    const index = i % colors.length;
    return { backgroundColor: colors[index][0], borderColor: colors[index][1] };
}
