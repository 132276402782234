import { Trans, useLingui } from "@lingui/react";
import Alert from "react-bootstrap/Alert";
import RForm from "react-bootstrap/Form";
import { FormProvider, get } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { useListCountriesQuery } from "../../services/api";
import { LoadingSpinner } from "../elements/LoadingSpinner";

export default function FarmForm({ farm, form, submitHandler }) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);

    const dispatch = useDispatch();

    const {
        handleSubmit,
        trigger,
        getValues,
        register,
        formState: { errors, isValidating, isFormStateSubmitting },
    } = form;

    const { data: countries, isLoading: countriesLoading } =
        useListCountriesQuery({});

    // const [postSubmission, { isLoading: isSubmitting }] =
    //     usePostSubmissionMutation();

    function isCountryValid(country) {
        for (const c of countries) {
            if (c.name == country) return true;
        }
        return false;
    }

    return (
        <FormProvider {...form}>
            {false && errorMessage && (
                <Alert key="warning" variant="warning" className="my-5">
                    <h5>{errorMessage.message}</h5>
                </Alert>
            )}

            {false && loadingMessage && (
                <LoadingSpinner label={loadingMessage} />
            )}

            <RForm
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                    return false;
                }}
                noValidate
            >
                <RForm.Group className="mb-3" controlId="name">
                    <RForm.Label>
                        <Trans id="farms.form.farm_name" />
                    </RForm.Label>
                    <RForm.Control
                        type="text"
                        isInvalid={get(errors, "name")}
                        {...register("name", {
                            required: true,
                            maxLength: 100,
                            minLength: 3,
                            setValueAs: (v) => v.trim(),
                        })}
                    />
                </RForm.Group>
                <RForm.Group className="mb-3" controlId="country">
                    <RForm.Label>
                        <Trans id="farms.form.farm_country" />
                    </RForm.Label>
                    <RForm.Select
                        isInvalid={get(errors, ".country")}
                        {...register("country", {
                            validate: isCountryValid,
                            required: true,
                        })}
                    >
                        {countriesLoading && (
                            <option value={""}>
                                <Trans id="loading.generic" />
                            </option>
                        )}
                        <option value={""}>
                            <Trans id="elements.please_select"></Trans>
                        </option>

                        {(countries || []).map((c, i) => (
                            <option key={i} value={c.name}>
                                {c.label}
                            </option>
                        ))}
                    </RForm.Select>
                </RForm.Group>
            </RForm>
        </FormProvider>
    );
}
