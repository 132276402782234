import { Trans, useLingui } from "@lingui/react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { useGetSurveyStatusByIdQuery } from "../../services/api";
import { useRequireAuthenticated } from "../../services/hooks";
import { formatDateTime, formatNumber } from "../../utils/format";

export default function SurveyGridItem({ survey }) {
    useRequireAuthenticated();
    const { i18n } = useLingui();

    const {
        data: status,
        error: statusError,
        isLoading: statusLoading,
    } = useGetSurveyStatusByIdQuery({ id: survey.id });

    const farmCount = status?.assigned_farm_count;
    const completeCount = status?.valid_complete_farm_count;
    const progress = ((farmCount && completeCount / farmCount) || 0) * 100;

    return (
        <Card>
            <Card.Header>
                {survey?.classifier == "DEMO" && (
                    <Badge bg="warning" className="float-end fs-6">
                        <Trans id="survey_list.demo" />
                    </Badge>
                )}
                <strong>{survey?.crop?.name}</strong>
            </Card.Header>
            <Card.Body>
                <span className="display-4 float-end lh-1 mt-0">
                    {survey?.year}
                </span>
                <Card.Title className="pt-2">
                    {survey?.title && survey.title}
                    {!survey?.title && `${survey?.crop?.name}, ${survey?.year}`}
                </Card.Title>
                {!status && statusLoading && (
                    <Card.Text>
                        <Trans id="loading.generic" />
                    </Card.Text>
                )}
                {status && (
                    <>
                        <div className="clearfix w-100 mt-3"></div>
                        <Row className="mt-3">
                            <Col>
                                <span className="text-uppercase small fw-bold">
                                    <Trans id="survey_list.latest_data_receipt" />
                                </span>
                                <br />
                                {status?.latest_submission_date
                                    ? formatDateTime(
                                          status?.latest_submission_date
                                      )
                                    : "-"}
                            </Col>
                            <Col>
                                <span className="text-uppercase small fw-bold">
                                    {survey?.type == "FARM" ? (
                                        <Trans id="survey_list.farms" />
                                    ) : (
                                        <Trans id="survey_list.suppliers" />
                                    )}
                                </span>
                                <br />
                                <span className="fs-5yx">
                                    {formatNumber(farmCount, 0)}
                                </span>
                            </Col>
                        </Row>
                        <div className="clearfix"></div>
                        <p className="mt-3">
                            <span className="text-uppercase small fw-bold">
                                <Trans id="survey_list.progress" />
                            </span>
                            <br />
                            <ProgressBar
                                variant="primary"
                                now={progress}
                                label={
                                    progress
                                        ? `${completeCount} / ${farmCount}`
                                        : ""
                                }
                            />
                        </p>
                    </>
                )}
                <Link to={`/survey/${survey?.id}/assignments`}>
                    <Button variant="outline-primary">
                        <Trans id="survey_list.details" />
                    </Button>
                </Link>
            </Card.Body>
        </Card>
    );
}
