export function cropLabel(crop, language) {
    if (!crop || !("translations" in crop) || !crop.translations?.length)
        return crop?.name;

    for (const translation of crop.translations)
        if (translation?.language == language) {
            return translation.label;
        }

    return crop.name;
}
