export function formatNumber(num, numDecimals) {
    return Number(num).toLocaleString(undefined, {
        maximumFractionDigits: numDecimals,
        minimumFractionDigits: numDecimals,
    });
}

export function formatDateTime(dateStr) {
    return new Date(Date.parse(dateStr)).toLocaleString();
}

export function zeroPad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}
