import { array, number, object, string } from "yup";

export const carbonStockGenericSchema = function (
    { surveyParams },
    { selectOptions }
) {
    const group = "carbon_stock_generic";

    const data = object({
        latest_land_use: string().required().oneOfOptions("land_use_type"),
        latest_tillage: string().required().oneOfOptions("tillage_type"),
        latest_c_input: string()
            .required()
            .oneOfOptions("c_input_type")
            .default("medium-c-input"),
        change_occured: string().required().oneOfOptions("yes_no"),
        changes: array().of(
            object({
                year: number().integer().required(),
                land_use_before: string()
                    .required()
                    .oneOfOptions(
                        selectOptions("carbon_stock_no_change").concat(
                            selectOptions("land_use_type")
                        )
                    ),
                tillage_before: string()
                    .required()
                    .oneOfOptions(
                        selectOptions("carbon_stock_no_change").concat(
                            selectOptions("tillage_type")
                        )
                    ),
                c_input_before: string()
                    .required()
                    .oneOfOptions(
                        selectOptions("carbon_stock_no_change").concat(
                            selectOptions("c_input_type")
                        )
                    ),
                allocation_share: number().required().min(0).max(100),
            })
        ),
        forest_type: string()
            .notRequired()
            .oneOfOptions(selectOptions("forest_type"), true)
            .requiredWhen("changes", (changes) =>
                (changes || []).some(
                    (c) => c.land_use_before == "native-forest"
                )
            ),
        forest_age: number()
            .notRequired()
            .min(0)
            .max(1000)
            .requiredWhen("changes", (changes) =>
                (changes || []).some(
                    (c) => c.land_use_before == "native-forest"
                )
            ),
    });

    const defaults = {
        latest_land_use: surveyParams?.CFT_CURRENT_LAND_USE,
        changes: [],
        changes__default: {
            allocation_share: 100,
        },
    };

    const ui = {
        type: "section",
        name: group,
        label: "Land use",
        label__de: "Landnutzung",
        children: [
            {
                type: "select_one",
                name: `${group}.latest_land_use`,
                label: "Latest land use",
                label__de: "Aktuelle Landnutzung",
                options: selectOptions("land_use_type"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.latest_tillage`,
                label: "Latest tillage practice",
                label__de: "Aktuelle Bodenbearbeitung",
                options: selectOptions("tillage_type"),
                widthLg: 4,
                // This info title and text may need to be improved. It mostly serves as an example implementation with an attempt to fill it with text that can be used already.
                info: {
                    title: "Tillage varieties",
                    text: "Full tillage consists of conventional deep plowing with full inversion of the soil. Reduced tillage means that soil is plowed at shallow depths without complete inversion. No-till is practiced when the soil is not plowed and not inverted.",
                    title__de: "Bodenbearbeitung",
                    text__de:
                        "Volle Bodenbearbeitung steht für das konventionelle, tiefe Pflügen des Bodens in welchem die Bodenschichten vollkommen verkehrt werden. Reduzierte Bodenbearbeitung findet nur in der oberen Bodenschicht statt bzw. führt zu keiner vollkommenen Vermischung der Bodenschichten. Bei Direktsaat kommt kein Pflug zum Einsatz.",
                },
            },
            {
                type: "select_one",
                name: `${group}.latest_c_input`,
                label: "Latest C input",
                label__de: "Aktueller Kohlenstoff-Eintrag",
                options: selectOptions("c_input_type"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.change_occured`,
                label: "Did any land use or practice change occur within the 20 years before the assessed year?",
                label__de:
                    "Haben sich Landnutzung, Bodenbearbeitung oder Kohlenstoff-Eintrag in den letzten 20 Jahren geändert?",
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                widthLg: 7,
            },
            {
                type: "model_repeat__carbon_stock_changes",
                name: `${group}.changes`,
                label: "Changes",
                label__de: "Changes",
                condition: (watch) => {
                    const val = watch(`${group}.change_occured`);
                    return val == "yes";
                },
                parameters: (watch) => ({
                    latest_land_use: watch(`${group}.latest_land_use`),
                    latest_tillage: watch(`${group}.latest_tillage`),
                    latest_c_input: watch(`${group}.latest_c_input`),
                }),
                children: [
                    {
                        type: "number",
                        name: "year",
                        label: "Year",
                        condition: () => false,
                    },
                    {
                        type: "select_one",
                        name: "land_use_before",
                        label: "Land use before selected year",
                        label__de: "Landnutzung vor dem ausgewählten Jahr",
                        options: selectOptions("carbon_stock_no_change").concat(
                            selectOptions("land_use_type")
                        ),
                        widthLg: 4,
                    },
                    {
                        type: "select_one",
                        name: "tillage_before",
                        label: "Tillage practice before selected year",
                        label__de: "Bodenbearbeitung vor dem ausgewählten Jahr",
                        options: selectOptions("carbon_stock_no_change").concat(
                            selectOptions("tillage_type")
                        ),
                        widthLg: 4,
                    },
                    {
                        type: "select_one",
                        name: "c_input_before",
                        label: "C input before selected year",
                        label__de:
                            "Kohlenstoff-Eintrag vor dem ausgewählten Jahr",
                        options: selectOptions("carbon_stock_no_change").concat(
                            selectOptions("c_input_type")
                        ),
                        widthLg: 4,
                    },
                    {
                        type: "number",
                        name: "allocation_share",
                        label: "Allocatable share of changes",
                        label__de: "Anteilige Zuordnung der Änderungen",
                        hint: "E.g., the affected crop area share of the change",
                        hint__de:
                            "Zum Beispiel der betroffene Flächenanteil der Änderung",
                        unit: "%",
                        widthLg: 4,
                    },
                ],
            },
            {
                type: "note",
                label: "Forest before land use change",
                label__de: "Wald vor der Landnutzungsänderung",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.land_use_before == "native-forest"
                    ),
            },
            {
                type: "note",
                label: "You have entered a land use change that involved a forest. Please provide details about the former forest's type and age.",
                label__de:
                    "Sie haben eine Landnutzungsänderung angegeben, die Wald involviert. Bitte geben Sie Art und Alter des ehemaligen Waldes an.",
                styleClassName: "mt-0",
                widthLg: 12,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.land_use_before == "native-forest"
                    ),
            },
            {
                type: "select_one",
                name: `${group}.forest_type`,
                label: "Forest type before land use change",
                label__de: "Waldart vor Landnutzungsänderung",
                options: selectOptions("forest_type"),
                widthLg: 3,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.land_use_before == "native-forest"
                    ),
            },
            {
                type: "number",
                name: `${group}.forest_age`,
                label: "Forest age at land use change",
                label__de: "Alter des Waldes bei Landnutzungsänderung",
                unit: "Years",
                unit__de: "Jahre",
                widthLg: 3,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.land_use_before == "native-forest"
                    ),
            },
        ],
    };

    return { group, data, ui, defaults };
};
