import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import structuredClone from "core-js-pure/actual/structured-clone";
import { useMemo, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { get, useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { reach } from "yup";
import { selectCurrentViewLanguage } from "../../../features/viewSlice";
import { fieldLabel } from "../../../utils/i18nForms";
import { DangerousMarkdown } from "../../elements/Markdown";
import FormFieldRenderer from "../FormFieldRenderer";

const MAX_CHANGES = 4;

export default function ModalRepeatCarbonStockChangesSimple({
    dataSchema,
    uiSchema,
    defaultValues,
    sectionKey,
    context,
    parameters,
}) {
    const language = useSelector(selectCurrentViewLanguage);
    const form = useFormContext();
    const { watch, control } = form;

    const { fields, append, remove } = useFieldArray({
        name: uiSchema.name,
        control,
        shouldUnregister: false,
    });

    const subDataSchema = reach(dataSchema, uiSchema.name)?.innerType;
    if (!subDataSchema) {
        console.error("Data schema not found for repeat at path", uiSchema);
    }

    const subDefaultValues = get(defaultValues, `${uiSchema.name}__default`);

    const assessmentYear =
        context?.assessment?.survey?.year || new Date().getFullYear();
    const allYears = [...Array(20).keys()].map((y, i) => assessmentYear - i);

    const { yearField, allocationField, addYearNoteField, yearNoteField } =
        parameters;

    const newItemDataTemplate = {
        [yearField]: null,
        [allocationField]: 100,
    };

    const yearNoteFieldUi = useMemo(() => {
        return uiSchema.children.find((v) => v.name == yearNoteField);
    }, [allocationField, uiSchema]);

    const addYearNoteFieldUi = useMemo(() => {
        return uiSchema.children.find((v) => v.name == addYearNoteField);
    }, [addYearNoteField, uiSchema]);

    const changeItemsArray = watch(`${uiSchema.name}`)
        .map((f, i) => ({ ...f, index: i })) // add index for lookups (must be done before ordering by year)
        .sort((a, b) => b.year - a.year); // then order by year

    const handleClickAdd = (year) => {
        for (const changeItem of changeItemsArray) {
            if (changeItem.year == year) {
                return; // year already entered
            }
        }

        const newItemData = {
            ...structuredClone(newItemDataTemplate),
            ...subDefaultValues,
            year: year,
        };
        append(newItemData);
    };

    const handleClickReset = (year) => {
        for (const changeItem of changeItemsArray) {
            if (changeItem.year == year) {
                remove(changeItem.index);
            }
        }
    };

    function previousYear(year) {
        let prevYear = assessmentYear - 20;
        for (const changeItem of changeItemsArray) {
            if (changeItem.year < year && changeItem.year > prevYear) {
                prevYear = changeItem.year;
            }
        }
        return prevYear || null;
    }

    function isYearAlreadyEntered(year) {
        for (const changeItem of changeItemsArray) {
            if (changeItem.year == year) {
                return true;
            }
        }
        return false;
    }

    const yearSections = (
        <>
            {changeItemsArray.map((changeItem) => {
                const fieldNamePrefix = `${uiSchema?.name}.${changeItem?.index}.`;
                const year = changeItem?.year;
                const label = i18n._("carbon_stock_changes.year_before", {
                    year: year,
                });
                const yearContext = {
                    ...context,
                    textArgs: { item_year: year },
                };
                return (
                    <Row className="mt-3">
                        <Col lg={10}>
                            <FormFieldRenderer
                                key={changeItem?.id}
                                form={form}
                                dataSchema={dataSchema}
                                uiSchema={uiSchema}
                                context={yearContext}
                                sectionKey={sectionKey}
                                fieldNamePrefix={fieldNamePrefix}
                            />
                        </Col>
                        <Col lg={2}>
                            <Button
                                variant="outline-secondary"
                                onClick={() => handleClickReset(year)}
                                size="sm"
                            >
                                🗙 Remove year
                            </Button>
                        </Col>
                    </Row>
                );
            })}
        </>
    );

    const max_changes_reached = changeItemsArray.length >= MAX_CHANGES;
    const addYearDropdownRef = useRef(null);
    const addRow = addYearNoteFieldUi && (
        <div className="sus-carbon-stock-add-year">
            <DangerousMarkdown
                text={fieldLabel(addYearNoteFieldUi, sectionKey, { context })}
            />
            <InputGroup className="mt-3">
                <Form.Select
                    name={"add_year"}
                    ref={addYearDropdownRef}
                    disabled={max_changes_reached}
                >
                    {allYears
                        .filter((y) => !isYearAlreadyEntered(y))
                        .map((y, i) => (
                            <option key={y} value={y}>
                                {y}
                            </option>
                        ))}
                </Form.Select>
                <Button
                    variant="primary"
                    disabled={max_changes_reached}
                    onClick={() =>
                        handleClickAdd(addYearDropdownRef.current.value)
                    }
                    size="sm"
                >
                    <Trans id="carbon_stock_changes.add_change" />
                </Button>
            </InputGroup>
            {max_changes_reached && (
                <p>
                    <Trans
                        id="carbon_stock_changes.max_reached"
                        values={{ num_changes: MAX_CHANGES }}
                    />
                </p>
            )}
        </div>
    );

    return (
        <>
            {yearSections}
            {addRow}
        </>
    );
}
