import { array, number, object, string } from "yup";
import { formatNumber } from "../../../utils/format";
import { fieldOptionLabel } from "../../../utils/forms";
import {
    getCustomNPKContents,
    monthHalfOptions,
} from "../../../utils/schemaUtils";

export const mineralFertilizationGenericSchema = function (
    { survey, surveyParams },
    { selectOptions, getOption, defaultUnit, convertUnit, testFertilizerNRate }
) {
    const group = "mineral_fertilization_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;
    const hideApplicationDate =
        surveyParams?.DISABLE_MINERAL_FERTILIZER_APPLICATION_DATE === "true";

    const nRateMinKgHa = 0;
    const nRateMaxKgHa =
        parseFloat(surveyParams?.MINERAL_FERTILIZERS_TOTAL_N_MAX_KG_HA) || 1000;

    const monthHalfOptionsList = monthHalfOptions(
        "fertilization_date",
        survey?.year,
        18
    );

    const data = object({
        fertilizers: array()
            .of(
                object({
                    type: string()
                        .required()
                        .oneOfOptions("mineral_fertilizer"),
                    application_rate: number().required().positive(),
                    application_rate__unit: string()
                        .required()
                        .oneOfOptions("weight_area_rate"),
                    nitrification_inhibitor_applied: string().oneOfOptions(
                        "yes_no",
                        true
                    ),
                    application_method: string()
                        .required()
                        .oneOfOptions("fertilization_method_minerals"),
                    ...(!hideApplicationDate && {
                        application_date: string()
                            .notRequired()
                            .oneOfOptions(monthHalfOptionsList, true),
                    }),
                    production_region: string()
                        .required()
                        .oneOfOptions("fertilizer_production_region"),
                    custom_N_ammonium_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100)
                        .test({
                            name: "custom-N-sum-max-100",
                            test: function (value, yupContext) {
                                const custom_N_ammonium_share = value;
                                const custom_N_nitrate_share =
                                    yupContext?.parent?.custom_N_nitrate_share;
                                const custom_N_urea_share =
                                    yupContext?.parent?.custom_N_urea_share;
                                if (
                                    custom_N_ammonium_share +
                                        custom_N_nitrate_share +
                                        custom_N_urea_share >
                                    100
                                )
                                    return false;

                                return true;
                            },
                            message:
                                "Ammonia, nitrate and urea combined may not exceed 100%.",
                        }),
                    custom_N_nitrate_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100),
                    custom_N_urea_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100),
                    custom_P_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100),
                    custom_P_share__unit: string()
                        .required()
                        .oneOfOptions("phosphorous_nutrient"),
                    custom_K_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100),
                    custom_K_share__unit: string()
                        .required()
                        .oneOfOptions("potassium_nutrient"),
                })
            )
            .test({
                name: "mineral_n_rate_test",
                test: testFertilizerNRate(
                    nRateMinKgHa,
                    nRateMaxKgHa,
                    getOption,
                    "mineral_fertilizer"
                ),
                message:
                    "Nitrogen fertilization may not be less than ${min} or more than ${max} kg/ha.",
                params: { min: nRateMinKgHa, max: nRateMaxKgHa },
            }),
    });

    const nutrientDisplayUnit = defaultUnit("weight_area_rate", unitSystem);

    const defaults = {
        fertilizers__default: {
            application_rate__unit: defaultUnit("weight_area_rate", unitSystem)
                .name,
            custom_P_share__unit: defaultUnit(
                "phosphorous_nutrient",
                unitSystem
            ).name,
            custom_K_share__unit: defaultUnit("potassium_nutrient", unitSystem)
                .name,
        },
    };

    const ui = {
        type: "section",
        name: group,
        label: "Mineral fertilizers",
        label__de: "Mineraldüngung",
        badge: "per_area",
        children: [
            {
                type: "modal_repeat",
                name: `${group}.fertilizers`,
                label: "Mineral fertilizer",
                label__de: "Mineralische Düngemittel",
                add_label: "Add fertilizer",
                add_label__de: "Düngemittel hinzufügen",
                styleBreakRowAfter: true,
                tableHeaders: (language) =>
                    language == "de"
                        ? ["Düngemittel", "Menge"]
                        : ["Type", "Application rate"],
                tableRow: (uiSchema, itemData, index, language) => [
                    fieldOptionLabel(
                        getOption("mineral_fertilizer", itemData?.type),
                        language
                    ),
                    `${formatNumber(itemData?.application_rate, 2)} ${
                        itemData?.application_rate__unit
                    }`,
                ],
                tableFooter: (data) => {
                    let N_total = 0;
                    let P2O5_total = 0;
                    let K2O_total = 0;

                    for (let row of data) {
                        const fertilizer = getOption(
                            "mineral_fertilizer",
                            row?.type
                        );
                        const applicationRate = row?.application_rate || 0;
                        const rateUnit = row?.application_rate__unit;
                        const convertedApplicationRate = convertUnit(
                            "weight_area_rate",
                            applicationRate,
                            rateUnit,
                            nutrientDisplayUnit.name
                        );

                        if (fertilizer.name == "Custom") {
                            const { n, p2o5, k2o } = getCustomNPKContents(
                                row,
                                convertUnit
                            );

                            N_total +=
                                (n / 100) * convertedApplicationRate || 0;
                            P2O5_total +=
                                (p2o5 / 100) * convertedApplicationRate || 0;
                            K2O_total +=
                                (k2o / 100) * convertedApplicationRate || 0;
                        } else {
                            N_total +=
                                (parseFloat(fertilizer?.nutrients?.n) / 100) *
                                    convertedApplicationRate || 0;
                            P2O5_total +=
                                (parseFloat(fertilizer?.nutrients?.p2o5) /
                                    100) *
                                    convertedApplicationRate || 0;
                            K2O_total +=
                                (parseFloat(fertilizer?.nutrients?.k2o) / 100) *
                                    convertedApplicationRate || 0;
                        }
                    }

                    const unitLabel = nutrientDisplayUnit.label_short;
                    return [
                        `N: ${formatNumber(
                            N_total,
                            0
                        )} ${unitLabel} | P₂O₅: ${formatNumber(
                            P2O5_total,
                            0
                        )} ${unitLabel} | K₂O: ${formatNumber(
                            K2O_total,
                            0
                        )} ${unitLabel}`,
                    ];
                },
                children: [
                    {
                        type: "select_one",
                        name: "type",
                        label: "Fertilizer",
                        label__de: "Düngemittel",
                        options: selectOptions("mineral_fertilizer"),
                        widthLg: 9,
                        styleBreakRowAfter: true,
                    },
                    {
                        type: "number",
                        name: "application_rate",
                        label: "Product amount applied",
                        label__de: "Ausbringmenge des Produkts",
                        unit: selectOptions("weight_area_rate"),
                        widthLg: 5,
                    },
                    {
                        type: "select_one",
                        name: "nitrification_inhibitor_applied",
                        label: "Nitrification inhibitor applied?",
                        label__de: "Nitrifikations-Hemmer verwendet?",
                        options: selectOptions("yes_no"),
                        styleSelectHorizontalButtons: true,
                        styleBreakRowAfter: true,
                        widthLg: 6,
                    },
                    {
                        type: "check_info",
                        widthLg: 4,
                        condition: (watch) => watch("type") != "Custom",
                        checkText__de: "Stickstoff N",
                        checkText: "Nitrogen N",
                        checkValue: (watch) => {
                            const fertilizerName = watch("type");
                            const fertilizer = getOption(
                                "mineral_fertilizer",
                                fertilizerName
                            );
                            const nitrogen =
                                (watch("application_rate") *
                                    (parseFloat(fertilizer?.nutrients?.n) ||
                                        0)) /
                                100;
                            const nutrient_rate = convertUnit(
                                "weight_area_rate",
                                nitrogen,
                                watch("application_rate__unit"),
                                nutrientDisplayUnit.name
                            );
                            const unit = fieldOptionLabel(nutrientDisplayUnit);

                            return `${formatNumber(
                                nutrient_rate || 0,
                                2
                            )} ${unit} `;
                        },
                    },
                    {
                        type: "check_info",
                        widthLg: 4,
                        condition: (watch) => watch("type") != "Custom",
                        checkText__de: "Phosphor P₂O₅",
                        checkText: "Phosphorous P₂O₅",
                        checkValue: (watch) => {
                            const fertilizerName = watch("type");
                            const fertilizer = getOption(
                                "mineral_fertilizer",
                                fertilizerName
                            );
                            const phosphorus =
                                (watch("application_rate") *
                                    (parseFloat(fertilizer?.nutrients?.p2o5) ||
                                        0)) /
                                100;
                            const nutrient_rate = convertUnit(
                                "weight_area_rate",
                                phosphorus,
                                watch("application_rate__unit"),
                                nutrientDisplayUnit.name
                            );
                            const unit = fieldOptionLabel(nutrientDisplayUnit);

                            return `${formatNumber(
                                nutrient_rate || 0,
                                2
                            )} ${unit} `;
                        },
                    },
                    {
                        type: "check_info",
                        widthLg: 4,
                        condition: (watch) => watch("type") != "Custom",
                        checkText__de: "Kalium K₂O",
                        checkText: "Potassium K₂O",
                        checkValue: (watch) => {
                            const fertilizerName = watch("type");
                            const fertilizer = getOption(
                                "mineral_fertilizer",
                                fertilizerName
                            );
                            let potassium =
                                (watch("application_rate") *
                                    (parseFloat(fertilizer?.nutrients?.k2o) ||
                                        0)) /
                                100;
                            const nutrient_rate = convertUnit(
                                "weight_area_rate",
                                potassium,
                                watch("application_rate__unit"),
                                nutrientDisplayUnit.name
                            );
                            const unit = fieldOptionLabel(nutrientDisplayUnit);

                            return `${formatNumber(
                                nutrient_rate || 0,
                                2
                            )} ${unit} `;
                        },
                    },
                    {
                        type: "select_one",
                        name: "application_method",
                        label: "Application method",
                        label__de: "Ausbringungsmethode",
                        options: selectOptions("fertilization_method_minerals"),
                        widthLg: 6,
                    },
                    !hideApplicationDate && {
                        type: "select_one",
                        name: "application_date",
                        label: "Date of application",
                        label__de: "Zeitpunkt der Ausbringung",
                        options: monthHalfOptionsList,
                        widthLg: 6,
                    },
                    {
                        type: "select_one",
                        name: "production_region",
                        label: "Fertilizer production region",
                        label__de: "Herstellungsregion des Düngers",
                        options: (watch) => {
                            const fertilizerTypeName = watch("type");
                            if (!fertilizerTypeName) return [];
                            const fertilizer = getOption(
                                "mineral_fertilizer",
                                fertilizerTypeName
                            );
                            const fertilizerProductionRegions =
                                fertilizer.production_regions;
                            return fertilizerProductionRegions;
                        },
                        widthLg: 6,
                    },
                    {
                        type: "note",
                        label: "Nutrient contents of custom NPK fertilizer",
                        label__de: "Nährstoffgehalte des eigenen NPK-Düngers",
                        condition: (watch) => watch("type") == "Custom",
                        styleClassName: "fs-4 mt-4",
                        widthLg: 12,
                    },
                    {
                        type: "number",
                        name: "custom_N_ammonium_share",
                        label: "Ammonium N content",
                        label__de: "Ammonium-N-Gehalt",
                        unit: "% N",
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 3,
                    },
                    {
                        type: "number",
                        name: "custom_N_nitrate_share",
                        label: "Nitrate N content",
                        label__de: "Nitrat-N-Gehalt",
                        unit: "% N",
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 3,
                    },
                    {
                        type: "number",
                        name: "custom_N_urea_share",
                        label: "Urea N content",
                        label__de: "Harnstoff-N-Gehalt",
                        unit: "% N",
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 3,
                    },
                    {
                        type: "check_info",
                        widthLg: 3,
                        condition: (watch) => watch("type") == "Custom",
                        styleBreakRowAfter: true,
                        checkText__de: "Gesamt-Stickstoffgehalt",
                        checkText: "Total nitrogen content",
                        checkValue: (watch) => {
                            const nitrogen =
                                (watch("custom_N_ammonium_share") || 0) +
                                (watch("custom_N_nitrate_share") || 0) +
                                (watch("custom_N_urea_share") || 0);

                            return `${formatNumber(nitrogen || 0, 2)} %`;
                        },
                    },
                    {
                        type: "number",
                        name: "custom_P_share",
                        label: "Phosphorous content",
                        label__de: "Phosphor-Gehalt",
                        unit: selectOptions("phosphorous_nutrient"),
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 4,
                    },
                    {
                        type: "number",
                        name: "custom_K_share",
                        label: "Potassium content",
                        label__de: "Kalium-Gehalt",
                        unit: selectOptions("potassium_nutrient"),
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 4,
                    },
                ],
            },
        ],
    };

    return { group, data, ui, defaults };
};
