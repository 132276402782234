import { getText } from "./i18n";

export function cleanFormErrorsFromRefs(errors) {
    // Hook-Form's error object contains HTML-refs which can not be JSON-stringified.
    // Therefore, they are recursively removed using this function.
    if (errors && errors.constructor == Object) {
        const errorsNew = { ...errors };
        if ("ref" in errorsNew) {
            delete errorsNew["ref"];
        }
        for (let key in errorsNew) {
            errorsNew[key] = cleanFormErrorsFromRefs(errorsNew[key]);
        }
        return errorsNew;
    }
    return errors;
}

export function fieldOptionLabel(option, language) {
    if (option && "labels" in option && language in (option.labels || {})) {
        return option.labels[language];
    }
    return option?.label;
}

export function deriveFormBadge(badge, watch) {
    const badgeNew = badge instanceof Function ? badge(watch) : badge;

    if (badgeNew == "per_area") {
        return {
            label: getText("form_controls", "badge", "⌀ Average over crop area")
                ?.message,
            color: "primary",
        };
    }
    if (badgeNew == "total") {
        return {
            label: getText("form_controls", "badge", "∑ Total for crop")
                ?.message,
            color: "warning",
        };
    }
    if (badgeNew == "over_crop_rotation") {
        return {
            icon: "arrow-clockwise",
            label: getText(
                "form_controls",
                "badge",
                "Over entire crop rotation"
            )?.message,
            color: "warning",
        };
    }
}
