import { createSlice } from "@reduxjs/toolkit";
import { LANGUAGE } from "../config";

const initialState = {
    language: LANGUAGE,
};

const slice = createSlice({
    name: "view",
    initialState: initialState,
    reducers: {
        changeViewLanguage: (state, action) => {
            console.info(`Changing language: ${action.payload.language}`);
            state.language = action.payload.language;
        },
    },
});

export default slice.reducer;

export const { changeViewLanguage } = slice.actions;

export const selectCurrentViewLanguage = (state) => state.view.language;
