import { number, object, string } from "yup";

export const supplyChainHerbalSchema = function (
    { surveyParams, farm },
    { selectOptions }
) {
    const group = "supply_chain_herbal";

    const enableFieldStratum = surveyParams?.ENABLE_FIELD_STRATUM == "true";

    const data = object({
        dataset_level: string().oneOfOptions("dataset_level").required(),
        number_farms: number()
            .min(1)
            .nanToUndefined()
            .notRequired()
            .requiredWhen(
                "dataset_level",
                (v) =>
                    v == "group_of_farms" || v == "average_dataset_for_stratum"
            ),
        ...(enableFieldStratum && {
            stratum: string().oneOfOptions("stratum").required(),
        }),
    });

    const defaults = {};

    const ui = {
        type: "section",
        name: group,
        label: "Supply chain info",
        children: [
            {
                type: "note",
                label: "The information entered in this section serves to get an understanding of the supply chain of {{crop_name}} as well as how the dataset you're entering relates to this supply chain.",
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.dataset_level`,
                label: "Data is entered for...",
                options: selectOptions("dataset_level"),
                widthLg: 6,
                info: {
                    title: "Selecting the correct level",
                    text: "Please select from the list whether you are entering data for a single farm, a group of farms, etc.",
                },
                styleBreakRowAfter: true,
            },
            enableFieldStratum && {
                type: "select_one",
                name: `${group}.stratum`,
                label: "Stratum",
                hint: "...that farm or group of farms is part of or that average dataset represents.",
                options: selectOptions("stratum"),
                widthLg: 6,
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.number_farms`,
                label: "Number of farms covered",
                hint: "Number of farms in group or in stratum, depending on whether group or stratum was selected above",
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.dataset_level`) == "group_of_farms" ||
                    watch(`${group}.dataset_level`) ==
                        "average_dataset_for_stratum",
            },
        ],
    };

    return { group, data, ui, defaults };
};
