import { number, object, string } from "yup";
import { cropLabel } from "../../../utils/crops";

export const coverCropGenericSchema = function ({}, { selectOptions }) {
    const group = "cover_crop_generic";

    const data = object({
        cover_crop_preceded: string().required(),
        cover_crop_share_leguminous: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("cover_crop_preceded", (v) => v == "yes"),
        cover_crop_share_area: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("cover_crop_preceded", (v) => v == "yes"),
    });

    const ui = {
        type: "section",
        name: group,
        label: "Cover crop",
        label__de: "Zwischenfruchtanbau",
        badge: "per_area",
        children: [
            {
                type: "select_one",
                name: `${group}.cover_crop_preceded`,
                label: "Did you grow a cover crop for green manure before {{crop_name}}?",
                label__de: (context, language) =>
                    `Haben Sie vor {{crop_name}} eine Zwischenfrucht zur Gründüngung angebaut?`,
                options: selectOptions("yes_no"),
                widthLg: 6,
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.cover_crop_share_leguminous`,
                label: "Share of legumes in seed mix",
                label__de: "Anteil Leguminosen in Saatmischung",
                unit: "%",
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.cover_crop_preceded`) == "yes",
            },
            {
                type: "number",
                name: `${group}.cover_crop_share_area`,
                label: "Share of area on which cover crop was grown",
                label__de: "Anteil der Fläche mit Zwischenfrucht",
                unit: "%",
                widthLg: 4,
                condition: (watch) => {
                    const val = watch(`${group}.cover_crop_preceded`);
                    return val == "yes";
                },
            },
        ],
    };

    return { group, data, ui };
};
