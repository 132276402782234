import { number, object, string } from "yup";

export const cropProtectionGenericSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit }
) {
    const group = "crop_protection_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const pesticideEachMaxKgHa =
        parseFloat(surveyParams?.PESTICIDES_EACH_MAX_KG_HA) || 100;

    const data = object({
        amount_herbicides: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .limitsWithUnit("weight_area_rate", "amount_herbicides", {
                unit: "kg / ha",
                min: 0,
                max: pesticideEachMaxKgHa,
            }),
        amount_herbicides__unit: string()
            .notRequired()
            .oneOfOptions("weight_area_rate"),
        amount_insecticides: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .limitsWithUnit("weight_area_rate", "amount_insecticides", {
                unit: "kg / ha",
                min: 0,
                max: pesticideEachMaxKgHa,
            }),
        amount_insecticides__unit: string()
            .notRequired()
            .oneOfOptions("weight_area_rate"),
        amount_fungicides: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .limitsWithUnit("weight_area_rate", "amount_fungicides", {
                unit: "kg / ha",
                min: 0,
                max: pesticideEachMaxKgHa,
            }),
        amount_fungicides__unit: string()
            .notRequired()
            .oneOfOptions("weight_area_rate"),
        amount_other_pesticides: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .limitsWithUnit("weight_area_rate", "amount_other_pesticides", {
                unit: "kg / ha",
                min: 0,
                max: pesticideEachMaxKgHa,
            }),
        amount_other_pesticides__unit: string()
            .notRequired()
            .oneOfOptions("weight_area_rate"),
    });

    const defaults = {
        amount_herbicides__unit: defaultUnit("weight_area_rate", unitSystem)
            .name,
        amount_insecticides__unit: defaultUnit("weight_area_rate", unitSystem)
            .name,
        amount_fungicides__unit: defaultUnit("weight_area_rate", unitSystem)
            .name,
        amount_other_pesticides__unit: defaultUnit(
            "weight_area_rate",
            unitSystem
        ).name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Crop protection",
        label__de: "Pflanzenschutz",
        badge: "per_area",
        children: [
            {
                type: "note",
                label: "Please provide the average product amounts, not the amounts of active ingredients. For liquid products, 1 litre may be assumed to equal 1 kilogram.",
                label__de:
                    "Bitte durchschnittliche Produktmengen angeben, nicht die Menge der aktiven Substanzen. Bei flüssigen Produkten kann 1 L als 1 kg angenommen werden.",
                widthLg: 12,
            },
            {
                type: "number",
                name: `${group}.amount_herbicides`,
                label: "How much herbicide was applied on average?",
                label__de:
                    "Wie viel Herbizid wurde durchschnittlich ausgebracht?",
                unit: selectOptions("weight_area_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.amount_insecticides`,
                label: "How much insecticide was applied on average?",
                label__de:
                    "Wie viel Insektizid wurde durchschnittlich ausgebracht?",
                unit: selectOptions("weight_area_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.amount_fungicides`,
                label: "How much fungicide was applied on average?",
                label__de:
                    "Wie viel Fungizid wurde durchschnittlich ausgebracht?",
                unit: selectOptions("weight_area_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.amount_other_pesticides`,
                label: "How much of other pesticides was applied on average?",
                label__de:
                    "Wie viel sonstige Pflanzenschutzmittel wurden durchschnittlich ausgebracht?",
                unit: selectOptions("weight_area_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
            },
        ],
    };

    return { group, data, ui, defaults };
};
