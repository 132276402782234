import { Trans } from "@lingui/react";
import structuredClone from "core-js-pure/actual/structured-clone";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { get, useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { reach } from "yup";
import { selectCurrentViewLanguage } from "../../../features/viewSlice";
import { fieldAddButtonLabel } from "../../../utils/i18nForms";
import ModalRepeatForm from "./ModelRepeatForm";

const NEW_ITEM = {
    data: {},
    field: { id: "new-item" },
    index: -1,
};

export default function ModalRepeat({
    dataSchema,
    uiSchema,
    defaultValues,
    context,
    sectionKey,
}) {
    const currentLanguage = useSelector(selectCurrentViewLanguage);

    const {
        watch,
        trigger,
        formState: { errors },
    } = useFormContext();
    const { fields, append, update, remove } = useFieldArray({
        name: uiSchema.name,
    });
    const subDataSchema = reach(dataSchema, uiSchema.name)?.innerType;
    if (!subDataSchema) {
        console.error("Data schema not found for repeat at path", uiSchema);
    }

    const subDefaultValues = get(defaultValues, `${uiSchema.name}__default`);

    const [activeRepeatItem, setActiveRepeatItem] = useState(null);
    const handleCancel = () => setActiveRepeatItem(null);
    const handleShowModal = (item) => {
        return setActiveRepeatItem({
            ...item,
            data: { ...subDefaultValues, ...item.data },
        });
    };
    const handleAddItem = () => {
        const newItem = structuredClone(NEW_ITEM);
        newItem.data = { ...newItem.data, ...subDefaultValues };
        setActiveRepeatItem(newItem);
    };
    const handleSaveItem = (item) => {
        if (item.index === -1) {
            append(item.data);
        } else {
            update(item.index, item.data);
            trigger(uiSchema.name);
        }
        setActiveRepeatItem(null);
    };

    const is_empty_list = !fields || fields.length === 0;

    const errorList = get(errors, uiSchema.name) || [];
    function hasErrorAtIndex(index) {
        if (!Array.isArray(errorList)) return null;
        if (errorList && errorList.length > index && errorList[index]) {
            return errorList[index];
        }
        return null;
    }

    const errorInTotal =
        errorList && !Array.isArray(errorList) ? errorList?.message : null;

    const tableHeaders =
        (uiSchema.tableHeaders && uiSchema.tableHeaders(currentLanguage)) || [];
    const tableRow = uiSchema.tableRow || (() => tableHeaders.map(() => ""));
    const tableFooters =
        uiSchema.tableFooter &&
        uiSchema.tableFooter(
            fields.map((f, i) => watch(`${uiSchema.name}.${i}`))
        );

    return (
        <>
            {activeRepeatItem && (
                <ModalRepeatForm
                    dataSchema={subDataSchema}
                    uiSchema={uiSchema}
                    item={activeRepeatItem}
                    context={context}
                    sectionKey={sectionKey}
                    handleCancel={handleCancel}
                    handleSave={handleSaveItem}
                />
            )}
            {is_empty_list && (
                <p>
                    <Trans id="modal_repeat.non_found" />
                </p>
            )}
            {!is_empty_list && (
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Nr.</th>
                            {tableHeaders.map((h, i) => (
                                <th key={i}>{h}</th>
                            ))}
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((field, index) => {
                            const itemData = watch(`${uiSchema.name}.${index}`);
                            const item = {
                                data: itemData,
                                field,
                                index,
                            };

                            return (
                                <tr key={field.id}>
                                    <td>
                                        {index + 1}{" "}
                                        {hasErrorAtIndex(index) && <>&#9888;</>}
                                    </td>
                                    {tableRow(
                                        uiSchema,
                                        itemData,
                                        index,
                                        currentLanguage
                                    ).map((value, index) => (
                                        <td key={index}>{value}</td>
                                    ))}
                                    <td>
                                        <ButtonGroup aria-label="Item actions">
                                            <Button
                                                variant="primary"
                                                onClick={() =>
                                                    handleShowModal(item)
                                                }
                                                size="sm"
                                            >
                                                <Trans id="modal_repeat.row_edit" />
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                onClick={() =>
                                                    remove(item.index)
                                                }
                                                size="sm"
                                            >
                                                <Trans id="modal_repeat.row_delete" />
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    {uiSchema.tableFooter && (
                        <tfoot>
                            <tr>
                                <td></td>
                                {tableFooters.map((f, i) => (
                                    <td key={i}>{f}</td>
                                ))}
                                <td />
                                <td />
                            </tr>
                        </tfoot>
                    )}
                </Table>
            )}
            {errorInTotal && <p className={"text-danger"}>{errorInTotal}</p>}
            <Row className="mb-3 align-items-end">
                <Col lg={4}>
                    <Button variant="primary" onClick={handleAddItem} size="sm">
                        {fieldAddButtonLabel(uiSchema, sectionKey) || (
                            <Trans id="modal_repeat.row_add" />
                        )}
                    </Button>
                </Col>
            </Row>
        </>
    );
}
