import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Tooltip,
    Colors,
} from "chart.js";
import React, { useEffect, useMemo, useRef } from "react";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    BarController,
    CategoryScale,
    LinearScale,
    BarElement
);

const StackedBarChart = ({ data }) => {
    const chartRef = useRef(null); // Reference to the canvas element
    const chartInstanceRef = useRef(null); // Reference to the chart instance

    useEffect(() => {
        // Clean up the previous chart instance when the component unmounts or rerenders
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        const options = {
            responsive: true,
            aspectRatio: 2.5,
            plugins: {
                legend: {
                    position: "bottom",
                    // maxWidth: 300,
                    align: "start",
                    labels: {
                        font: { size: 12 },
                        usePointStyle: true,
                        pointStyle: "rectRounded",
                    },
                },
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: true,
                    },
                },
                y: {
                    stacked: true,
                    grid: {
                        display: false,
                    },
                },
            },
        };

        // Create a new chart instance
        if (chartRef.current) {
            if (!chartInstanceRef.current) {
                chartInstanceRef.current = new ChartJS(chartRef.current, {
                    type: "bar",
                    data: data,
                    options: options,
                });
            } else {
                chartInstanceRef.current.data = data;
                chartInstanceRef.current.update("none");
            }
        }
    }, [data]);

    return (
        <div>
            <canvas ref={chartRef}></canvas>
        </div>
    );
};

export default StackedBarChart;
