import { array, number, object, string } from "yup";
import { cropLabel } from "../../../utils/crops";

export const landManagementGenericSchema = function (
    { surveyParams, survey },
    { selectOptions }
) {
    const group = "land_management_generic";

    const year = survey?.year;

    const data = object({
        latest_tillage: string().required().oneOfOptions("tillage_type"),
        tillage_changed: string().required().oneOfOptions("yes_no"),
        latest_residues_high: string().required().oneOfOptions("yes_no"),
        residues_high_changed: string().required().oneOfOptions("yes_no"),
        latest_organic_fertilization: string()
            .required()
            .oneOfOptions("yes_no"),
        organic_fertilization_changed: string()
            .required()
            .oneOfOptions("yes_no"),
        latest_cover_crops: string().required().oneOfOptions("yes_no"),
        cover_crops_changed: string().required().oneOfOptions("yes_no"),
        changes: array().of(
            object({
                year: number().integer().required().min(1990).max(2030),
                tillage_before: string()
                    .required()
                    .oneOfOptions(
                        selectOptions("carbon_stock_no_change").concat(
                            selectOptions("tillage_type")
                        )
                    ),
                tillage_before_allocation: number()
                    .required()
                    .min(0.01)
                    .max(100),
                residues_high_before: string()
                    .required()
                    .oneOfOptions(
                        selectOptions("carbon_stock_no_change").concat(
                            selectOptions("yes_no")
                        )
                    ),
                residues_high_before_allocation: number()
                    .required()
                    .min(0.01)
                    .max(100),
                organic_fertilization_before: string()
                    .required()
                    .oneOfOptions(
                        selectOptions("carbon_stock_no_change").concat(
                            selectOptions("yes_no")
                        )
                    ),
                organic_fertilization_before_allocation: number()
                    .required()
                    .min(0.01)
                    .max(100),
                cover_crops_before: string()
                    .required()
                    .oneOfOptions(
                        selectOptions("carbon_stock_no_change").concat(
                            selectOptions("yes_no")
                        )
                    ),
                cover_crops_before_allocation: number()
                    .required()
                    .min(0.01)
                    .max(100),
            })
        ),
    });

    const defaults = {
        tillage_changed: "no",
        residues_high_changed: "no",
        organic_fertilization_changed: "no",
        cover_crops_changed: "no",
        changes: [],
        changes__default: {
            tillage_before: "no-change",
            tillage_before_allocation: 100,
            residues_high_before: "no-change",
            residues_high_before_allocation: 100,
            organic_fertilization_before: "no-change",
            organic_fertilization_before_allocation: 100,
            cover_crops_before: "no-change",
            cover_crops_before_allocation: 100,
        },
    };

    const ui = {
        type: "section",
        name: group,
        label: "Land management",
        label__de: "Bewirtschaftung",
        badge: "over_crop_rotation",
        children: [
            {
                type: "note",
                label: "The following section relates to **all cultivated lands** of your farm, i.e. not only {{crop_name}}.",
                label__de:
                    "Der folgende Abschnitt bezieht sich auf **alle bewirtschafteten Flächen** Ihres Betriebs, d.h. nicht nur auf {{crop_name}}.",
                widthLg: 8,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "#####Tillage",
                label__de: "#####Bodenbearbeitung",
                styleClassName: "mb-0",
            },
            {
                type: "note",
                label: "#####Crop residues",
                label__de: "#####Erntereste",
                styleClassName: "mb-0",
            },
            {
                type: "note",
                label: "#####Organic inputs",
                label__de: "#####Organische Düngung",
                styleClassName: "mb-0",
            },
            {
                type: "note",
                label: "#####Cover crops",
                label__de: "#####Zwischenfrüchte",
                styleClassName: "mb-0",
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.latest_tillage`,
                label: "What is the dominating tillage practice in your crop rotation?",
                label__de:
                    "Welche Bodenbearbeitung findet in der Fruchtfolge vorrangig statt?",
                hint: "Min. every 3 years",
                hint__de: "Min. alle 3 Jahre",
                options: selectOptions("tillage_type"),
                widthLg: 3,
            },
            {
                type: "select_one",
                name: `${group}.latest_residues_high`,
                label: "Are crop residues in the crop rotation primarily left on the field?",
                label__de:
                    "Verbleiben die Erntereste der Fruchtfolgeglieder überwiegend auf dem Feld?",
                hint: "Min. half of crops",
                hint__de: "Min. Hälfte der Kulturen",
                options: selectOptions("yes_no"),
                widthLg: 3,
            },
            {
                type: "select_one",
                name: `${group}.latest_organic_fertilization`,
                label: "Is at least 20% of the crop rotation's nitrogen demand covered by organic fertilization?",
                label__de:
                    "Wird mindestens 20% des Stickstoffbedarfs der Fruchtfolge durch organische Düngung gedeckt?",
                options: selectOptions("yes_no"),
                widthLg: 3,
            },
            {
                type: "select_one",
                name: `${group}.latest_cover_crops`,
                label: "Is substantial biomass from catch crops, undersown crops, etc. introduced into the soil?",
                label__de:
                    "Wird nennenswert Biomasse aus Zwischenfrüchten, Untersaaten, etc. in den Boden eingebracht?",
                hint: "Min. every 3 years",
                hint__de: "Min. alle 3 Jahre",
                options: selectOptions("yes_no"),
                widthLg: 3,
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.tillage_changed`,
                label: "Have you changed this practice between {{start_year}} and {{end_year}}?",
                label__de:
                    "Haben Sie diese Praxis zwischen {{start_year}} und {{end_year}} verändert?",
                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                widthLg: 3,
            },
            {
                type: "select_one",
                name: `${group}.residues_high_changed`,
                label: "Have you changed this practice between {{start_year}} and {{end_year}}?",
                label__de:
                    "Haben Sie diese Praxis zwischen {{start_year}} und {{end_year}} verändert?",
                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                widthLg: 3,
            },
            {
                type: "select_one",
                name: `${group}.organic_fertilization_changed`,
                label: "Have you changed this practice between {{start_year}} and {{end_year}}?",
                label__de:
                    "Haben Sie diese Praxis zwischen {{start_year}} und {{end_year}} verändert?",
                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                widthLg: 3,
            },
            {
                type: "select_one",
                name: `${group}.cover_crops_changed`,
                label: "Have you changed this practice between {{start_year}} and {{end_year}}?",
                label__de:
                    "Haben Sie diese Praxis zwischen {{start_year}} und {{end_year}} verändert?",
                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                widthLg: 3,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "You entered that your land management changed in the past 20 years. In the following table, please enter these changed and respective years.",
                label__de:
                    "Sie haben angegeben, dass Sie in den letzten 20 Jahren die Bewirtschaftung auf Ihrem Betrieb verändert haben. Bitte tragen Sie in der nachfolgenden Tabelle ein, in welchen Jahren sich Ihre Bewirtschaftung geändert hat.",
                widthLg: 12,
                condition: (watch) => {
                    const values = [
                        watch(`${group}.tillage_changed`),
                        watch(`${group}.residues_high_changed`),
                        watch(`${group}.organic_fertilization_changed`),
                        watch(`${group}.cover_crops_changed`),
                    ];
                    return values.indexOf("yes") != -1;
                },
                styleBreakRowAfter: true,
            },
            {
                type: "model_repeat__carbon_stock_changes_v2",
                name: `${group}.changes`,
                label: "Changes",
                label__de: "Changes",
                widsthLg: 7,
                styleBreakRowAfter: true,
                condition: (watch) => {
                    const values = [
                        watch(`${group}.tillage_changed`),
                        watch(`${group}.residues_high_changed`),
                        watch(`${group}.organic_fertilization_changed`),
                        watch(`${group}.cover_crops_changed`),
                    ];
                    return values.indexOf("yes") != -1;
                },
                parameters: (watch) => ({
                    columns: [
                        watch(`${group}.tillage_changed`) == "yes" && {
                            label: "Tillage",
                            label__de: "Bodenbearbeitung",
                            hint: "Min. every 3 years",
                            hint__de: "Min. alle 3 Jahre",
                            latestField: `${group}.latest_tillage`,
                            beforeField: "tillage_before",
                        },
                        watch(`${group}.residues_high_changed`) == "yes" && {
                            label: "Crop residues",
                            label__de: "Erntereste",
                            hint: "Min. half of crops left on field",
                            hint__de:
                                "Erntereste von min. der Hälfte der Kulturen verbleiben auf Feld",
                            latestField: `${group}.latest_residues_high`,
                            beforeField: "residues_high_before",
                        },
                        watch(`${group}.organic_fertilization_changed`) ==
                            "yes" && {
                            label: "Organic inputs",
                            label__de: "Organische Düngung",
                            hint: "Min. 20% of N covered by organic inputs",
                            hint__de:
                                "Min. 20% des N-Bedarfs durch org. Düngung",
                            latestField: `${group}.latest_organic_fertilization`,
                            beforeField: "organic_fertilization_before",
                        },
                        watch(`${group}.cover_crops_changed`) == "yes" && {
                            label: "Cover crops",
                            label__de: "Zwischenfrüchte",
                            hint: "Min. every 3 years",
                            hint__de: "Min. alle 3 Jahre",
                            latestField: `${group}.latest_cover_crops`,
                            beforeField: "cover_crops_before",
                        },
                    ],
                    yearField: "year",
                    allocationField: "allocation",
                    addYearNoteField: "add_year_note",
                }),
                children: [
                    {
                        type: "number",
                        name: "year",
                        label: "Year",
                        condition: () => false,
                    },
                    {
                        type: "select_one",
                        name: "tillage_before",
                        label: "Tillage before",
                        label__de: "Bodenbearbeitung vorher",
                        options: selectOptions("carbon_stock_no_change").concat(
                            selectOptions("tillage_type")
                        ),
                        widthLg: 4,
                    },
                    {
                        type: "select_one",
                        name: "residues_high_before",
                        label: "Residues high before",
                        label__de: "Erntereste hoch vorher",
                        options: selectOptions("carbon_stock_no_change").concat(
                            selectOptions("yes_no")
                        ),
                        widthLg: 4,
                    },
                    {
                        type: "select_one",
                        name: "organic_fertilization_before",
                        label: "Organic fertilization before",
                        label__de: "Organische Düngung vorher",
                        options: selectOptions("carbon_stock_no_change").concat(
                            selectOptions("yes_no")
                        ),
                        widthLg: 4,
                    },
                    {
                        type: "select_one",
                        name: "cover_crops_before",
                        label: "Cover crops before",
                        label__de: "Zwischenfrüchte vorher",
                        options: selectOptions("carbon_stock_no_change").concat(
                            selectOptions("yes_no")
                        ),
                        widthLg: 4,
                    },
                    {
                        type: "number",
                        name: "allocation",
                        label: "Subfield",
                        label__de: "Teilfläche",
                        hint: "E.g., the affected crop area share of the change",
                        hint__de:
                            "Zum Beispiel der betroffene Flächenanteil der Änderung",
                        unit: "%",
                        info: {
                            title: "Change on part of your fields",
                            text: "If the change only occured on a part of your arable fields, please enter the percentage of fields that were affected.",
                            title__de: "Veränderung auf einer Teilfläche",
                            text__de:
                                "Wenn die Veränderung nur auf einem Teil Ihres Ackerlands stattgefunden hat, geben Sie hier den Prozentteil der betroffenen Fläche an.",
                        },
                        widthLg: 4,
                    },
                    {
                        type: "note",
                        name: "add_year_note",
                        label: "Select a year in which a practice change occured",
                        label__de:
                            "Wählen Sie ein Jahr, um eine Bewirtschaftungsveränderung hinzuzufügen",
                    },
                ],
            },
            {
                type: "note",
                label: "Forest before land use change",
                label__de: "Wald vor der Landnutzungsänderung",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.tillage_before == "native-forest"
                    ),
            },
            {
                type: "note",
                label: "You have entered a land use change that involved a forest. Please provide details about the former forest's type and age.",
                label__de:
                    "Sie haben eine Landnutzungsänderung angegeben, die Wald involviert. Bitte geben Sie Art und Alter des ehemaligen Waldes an.",
                styleClassName: "mt-0",
                widthLg: 12,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.tillage_before == "native-forest"
                    ),
            },
            {
                type: "select_one",
                name: `${group}.forest_type`,
                label: "Forest type before land use change",
                label__de: "Waldart vor Landnutzungsänderung",
                options: selectOptions("forest_type"),
                widthLg: 3,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.tillage_before == "native-forest"
                    ),
            },
            {
                type: "number",
                name: `${group}.forest_age`,
                label: "Forest age at land use change",
                label__de: "Alter des Waldes bei Landnutzungsänderung",
                unit: "Years",
                unit__de: "Jahre",
                widthLg: 3,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.tillage_before == "native-forest"
                    ),
            },
        ],
    };

    return { group, data, ui, defaults };
};
