import { array, object, string } from "yup";
import { mineralFertilizationGenericSchema } from "./generic";

export const mineralFertilizationSugarbeetSchema = function (context, methods) {
    const { survey } = context;
    const { selectOptions, getOption } = methods;

    const { group, data, ui, defaults } = mineralFertilizationGenericSchema(
        context,
        methods
    );

    const uiUreaseInhibitor = {
        type: "select_one",
        name: "urease_inhibitor_applied",
        label: "Urease inhibitor applied?",
        label__de: "Urease-Hemmer verwendet?",
        options: selectOptions("yes_no"),
        styleSelectHorizontalButtons: true,
        styleBreakRowAfter: true,
        widthLg: 3,
    };

    const fertilizersCopy = data.fields.fertilizers.innerType.clone();
    const dataNew = data.concat(
        object({
            fertilizers: array()
                .of(
                    fertilizersCopy.shape({
                        urease_inhibitor_applied: string().oneOfOptions(
                            "yes_no",
                            true
                        ),
                    })
                )
                .concat(data.fields.fertilizers),
        })
    );

    const sectionChildren = ui["children"];
    const fertilizerRepeat = sectionChildren.find(
        (c) => c["name"] == `${group}.fertilizers`
    );
    const fertilizerChildren = fertilizerRepeat["children"];
    const indexOfNitrificationInhibitor = fertilizerChildren.findIndex(
        (c) => c["name"] == "nitrification_inhibitor_applied"
    );

    // Insert field after nitrification inhibitor
    fertilizerChildren.splice(
        indexOfNitrificationInhibitor + 1,
        0,
        uiUreaseInhibitor
    );

    // Do not break row after nitrification inhibitor
    fertilizerChildren[
        indexOfNitrificationInhibitor
    ].styleBreakRowAfter = false;
    fertilizerChildren[indexOfNitrificationInhibitor].widthLg = 3;

    return { group, data: dataNew, ui, defaults };
};
