import { number, object, string } from "yup";
import { cropLabel } from "../../../utils/crops";

export const coverCropSugarbeetSchema = function ({}, { selectOptions }) {
    const group = "cover_crop_sugarbeet";

    const data = object({
        cover_crop_preceded: string().required(),
        cover_crop_share_leguminous: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("cover_crop_preceded", (v) => v == "yes"),
        cover_crop_share_area: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("cover_crop_preceded", (v) => v == "yes"),
        cover_crop_frozen: string()
            .notRequired()
            .oneOfOptions("yes_no", true)
            .requiredWhen("cover_crop_preceded", (v) => v == "yes"),
        cover_crop_incorporation_season: string()
            .notRequired()
            .oneOfOptions("cover_crop_incorporation_season", true)
            .requiredWhen(
                ["cover_crop_preceded", "cover_crop_frozen"],
                (cover_crop_preceded, cover_crop_frozen) =>
                    cover_crop_preceded == "yes" && cover_crop_frozen == "no"
            ),
    });

    const ui = {
        type: "section",
        name: group,
        label: "Green manure",
        label__de: "Gründüngungszwischenfrucht",
        children: [
            {
                type: "select_one",
                name: `${group}.cover_crop_preceded`,
                label: "Did you grow a cover crop for green manure before {{crop_name}}?",
                label__de:
                    "Haben Sie vor {{crop_name}} eine Zwischenfrucht zur Gründüngung angebaut?",
                options: selectOptions("yes_no"),
                widthLg: 6,
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.cover_crop_share_leguminous`,
                label: "Share of legumes in seed mix",
                label__de: "Anteil Leguminosen in Saatmischung",
                unit: "%",
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.cover_crop_preceded`) == "yes",
            },
            {
                type: "number",
                name: `${group}.cover_crop_share_area`,
                label: "Share of area on which cover crop was grown",
                label__de: "Anteil der Fläche mit Zwischenfrucht",
                unit: "%",
                widthLg: 4,
                styleBreakRowAfter: true,
                condition: (watch) => {
                    const val = watch(`${group}.cover_crop_preceded`);
                    return val == "yes";
                },
            },
            {
                type: "select_one",
                name: `${group}.cover_crop_frozen`,
                label: "Did the cover crop freeze off?",
                label__de: "Ist die Zwischenfrucht abgefroren?",
                options: selectOptions("yes_no"),
                widthLg: 4,
                styleSelectHorizontalButtons: true,
                condition: (watch) => {
                    const val = watch(`${group}.cover_crop_preceded`);
                    return val == "yes";
                },
            },
            {
                type: "select_one",
                name: `${group}.cover_crop_incorporation_season`,
                label: "When did you incorporate the cover crop?",
                label__de: "Wann wurde die Zwischenfrucht eingearbeitet?",
                options: selectOptions("cover_crop_incorporation_season"),
                widthLg: 4,
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.cover_crop_preceded`) == "yes" &&
                    watch(`${group}.cover_crop_frozen`) == "no",
            },
        ],
    };

    return { group, data, ui };
};
