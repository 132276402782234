import { configureStore } from "@reduxjs/toolkit";
import assessmentReducer from "./features/assessmentSlice";
import formReducer from "./features/form/formSlice";
import userReducer from "./features/userSlice";
import viewReducer from "./features/viewSlice";
import i18nReducer from "./features/i18nSlice";
import { api } from "./services/api";

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        form: formReducer,
        user: userReducer,
        view: viewReducer,
        i18n: i18nReducer,
        assessment: assessmentReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});
