import { Trans } from "@lingui/react";
import Form from "react-bootstrap/Form";
import { get, useFormContext } from "react-hook-form";
import InputGroup from "react-bootstrap/InputGroup";
import { InfoButton } from "./InfoButton";

export function BaseSelect({ name, options, label, unit, hint, form, info }) {
    const {
        register,
        trigger,
        formState: { errors },
    } = form ? form : useFormContext();

    const error = get(errors, name);

    return (
        <Form.Group controlId={name}>
            <Form.Label>
                {label}
                {unit && <> ({unit})</>}
            </Form.Label>
            <InputGroup>
                {info && (
                    <InfoButton
                        infoTitle={info.title}
                        infoText={info.text}
                    ></InfoButton>
                )}
                <Form.Select
                    aria-label={label}
                    name={name}
                    isInvalid={error}
                    {...register(name, { onChange: () => trigger(name) })}
                >
                    <option value={""}>
                        <Trans id="elements.please_select"></Trans>
                    </option>
                    {options.map((o, i) => (
                        <option key={`${name}-${i}`} value={o.value}>
                            {o.label}
                        </option>
                    ))}
                </Form.Select>
            </InputGroup>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
            <Form.Control.Feedback type="invalid">
                {error?.message}
            </Form.Control.Feedback>
        </Form.Group>
    );
}
