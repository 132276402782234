import { selectApiTexts } from "../features/i18nSlice";
import { setCookie } from "./cookies";
import { store } from "../store";
import { LANG_DEFAULT_FALLBACK, LANG_SUPPORTED } from "../constants";

function getMessages(language) {
    if (language === "en") {
        return require("../locales/en/messages").messages;
    }
    if (language === "de") {
        return require("../locales/de/messages").messages;
    }
    if (language === "fr") {
        return require("../locales/fr/messages").messages;
    }
    if (language === "pl") {
        return require("../locales/pl/messages").messages;
    }
    console.error(`Language not found: ${language}`);
}

export function switchLanguage(i18n, language) {
    if (language in LANG_SUPPORTED) {
        i18n.load(language, getMessages(language));
        i18n.activate(language);
    } else {
        const def = LANG_DEFAULT_FALLBACK();
        i18n.load(def, getMessages(def));
        i18n.activate(def);
    }
    setCookie("lang", language, 365 * 1.5);
}

export function buildApiMessages(textList, componentKey, language) {
    const messages = {};

    // Legend:
    // sec = section_key
    // ori = original_text
    // tra = translation object {lang -> text}

    for (let text of textList) {
        const compKey = componentKey || text.com;
        if (!(compKey in messages)) {
            messages[compKey] = {};
        }
        if (!(text.sec in messages[compKey])) {
            messages[compKey][text.sec] = {};
        }

        if (language in text.tra) {
            // workaround for inconsistent line breaks.
            // in code, definitions are usually using \n linebreaks.
            // django admin makes \r\n linebreaks which breaks the translation lookup.
            const original = `${text.ori}`.replaceAll("\r\n", "\n");
            messages[compKey][text.sec][original] = text.tra[language];
        }
    }

    return messages;
}

export function getText(componentKey, sectionKey, original) {
    const { messages, language } = selectApiTexts(store);

    if (messages == null)
        return {
            message: null,
            language: language,
        };

    return {
        message: messages?.[componentKey]?.[sectionKey]?.[original] || original,
        language: language,
    };
}
