import { number, object, string } from "yup";

export const transportInternationalSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit }
) {
    const group = "transport_international";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const data = object({
        destination_country: string().required().oneOfOptions("country"),
        destination_address: string().required(),
        distance_road: number().min(0).nanToUndefined().required(),
        distance_rail: number().min(0).nanToUndefined().notRequired(),
        distance_sea: number().min(0).nanToUndefined().notRequired(),
    });

    const defaults = {
        distance_farm_buyer__unit: defaultUnit("distance", unitSystem).name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Transport",
        label__de: "Transport",
        children: [
            {
                type: "note",
                label: "In the following section, please enter the one-way transport distances of the harvested crop to the buyer. Indicate distances for each transport mode along the way. Transport to the buyer may be multi-modal, e.g., 30 km truck transport from farm to collection point/processor, then 230 km railway transport to the sea port, and  5600 km shipping to the country of the buyer.",
                widthLg: 12,
            },
            {
                type: "select_one",
                name: `${group}.destination_country`,
                label: "Country of destination",
                hint: "Where is your product shipped to?",
                widthLg: 5,
                options: selectOptions("country"),
            },
            {
                type: "text",
                name: `${group}.destination_address`,
                label: "Postcode/ZIP Code of destination",
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.distance_road`,
                label: "Transporting distance on roads (one-way, e.g., HGV from farm to buyer or to port)",
                hint: "Example: 34 km from farm gate to wholesaler or to sea port plus 120 km from destination sea port to buyer",
                unit: selectOptions("distance"),
                widthLg: 4,
                info: {
                    title: "Distances to buyer",
                    text: "Please enter the one-way road distance from the farm or crop collection point (e.g. in case of wild collection) to the drop-off point of the buyer of the crop. This might be a warehouse or processing facility.",
                },
            },
            {
                type: "number",
                name: `${group}.distance_rail`,
                label: "Transporting distance on rails (one-way, e.g., railway from collection point to port)",
                hint: "Only if railways are used, enter 0 otherwise. Example: 230 km from collection point or to sea port",
                unit: selectOptions("distance"),
                widthLg: 4,
                info: {
                    title: "Distances to buyer",
                    text: "Please enter the one-way rail distances that the crop is being transported via railways.",
                },
            },
            {
                type: "number",
                name: `${group}.distance_sea`,
                label: "Transporting distance on sea (one-way, e.g., container shipping from port to country of destination)",
                hint: "Only if sea transports are used, enter 0 otherwise. Example: 3200 km from sea port of origin to port of the buyer",
                unit: selectOptions("distance"),
                widthLg: 4,
                info: {
                    title: "Distance to buyer",
                    text: "Please enter the one-way shipping distances that the crop is being shipped.",
                },
            },
        ],
    };

    return { group, data, ui, defaults };
};
