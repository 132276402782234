import { object, string } from "yup";

export const sectionMetaFields = function (
    group,
    { surveyParams, farm },
    { selectOptions }
) {
    const data = object({
        section_data_quality: string().required().oneOfOptions("data_quality"),
        section_user_note: string().notRequired(),
    });

    const defaults = {};

    const ui = {
        children: [
            {
                type: "select_one",
                name: `${group}.section_data_quality`,
                label: "Data quality of section",
                label__de: "Datenqualität des Abschnitts",
                widthLg: { span: 6, offset: 3 },
                options: selectOptions("data_quality"),
                styleSelectHorizontalButtons: true,
                styleClassName: "mt-5 horizontal-btn-align-end",
            },
            {
                type: "text",
                name: `${group}.section_user_note`,
                label: "Note",
                label__de: "Notiz",
                hint: "Has no effect on results",
                hint__de: "Hat keinen Einfluss auf Ergebnisse",
                widthLg: { span: 3 },
                styleClassName: "mt-5",
            },
        ],
    };

    return { data, ui, defaults };
};
