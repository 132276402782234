import { array, number, object, string } from "yup";
import { cropLabel } from "../../../utils/crops";

export const landManagementSimpleArableSchema = function (
    { surveyParams, survey },
    { selectOptions }
) {
    const group = "land_management_simple_arable";

    const YEARS_AGO = [10, 20];
    const year = survey?.year;

    const data = object({
        tillage_latest: string().required().oneOfOptions("tillage_type"),
        tillage_changed: string().required().oneOfOptions("yes_no"),
        ...YEARS_AGO.reduce(
            (prev, curr) => ({
                ...prev,
                [`tillage_${curr}y_ago`]: string()
                    .notRequired()
                    .requiredWhen("tillage_changed", (v) => v == "yes")
                    .oneOfOptions("tillage_type", true),
                [`tillage_${curr}y_ago_area_share`]: number()
                    .nanToUndefined()
                    .notRequired()
                    .requiredWhen("tillage_changed", (v) => v == "yes")
                    .min(0.01)
                    .max(100),
            }),
            {}
        ),
        residues_latest: string()
            .required()
            .oneOfOptions("land_residue_management"),
        residues_changed: string().required().oneOfOptions("yes_no"),
        ...YEARS_AGO.reduce(
            (prev, curr) => ({
                ...prev,
                [`residues_${curr}y_ago`]: string()
                    .notRequired()
                    .requiredWhen("residues_changed", (v) => v == "yes")
                    .oneOfOptions("land_residue_management", true),
                [`residues_${curr}y_ago_area_share`]: number()
                    .nanToUndefined()
                    .notRequired()
                    .requiredWhen("residues_changed", (v) => v == "yes")
                    .min(0.01)
                    .max(100),
            }),
            {}
        ),
        organic_fertilization_latest: string()
            .required()
            .oneOfOptions("yes_no"),
        organic_fertilization_changed: string()
            .required()
            .oneOfOptions("yes_no"),
        ...YEARS_AGO.reduce(
            (prev, curr) => ({
                ...prev,
                [`organic_fertilization_${curr}y_ago_area_share`]: number()
                    .nanToUndefined()
                    .notRequired()
                    .requiredWhen(
                        "organic_fertilization_changed",
                        (v) => v == "yes"
                    )
                    .min(0)
                    .max(100),
            }),
            {}
        ),
        cover_crops_latest: string().required().oneOfOptions("yes_no"),
        cover_crops_changed: string().required().oneOfOptions("yes_no"),
        ...YEARS_AGO.reduce(
            (prev, curr) => ({
                ...prev,
                [`cover_crops_${curr}y_ago_area_share`]: number()
                    .nanToUndefined()
                    .notRequired()
                    .requiredWhen("cover_crops_changed", (v) => v == "yes")
                    .min(0)
                    .max(100),
            }),
            {}
        ),
    });

    const defaults = {
        tillage_changed: "no",
        residues_changed: "no",
        organic_fertilization_changed: "no",
        cover_crops_changed: "no",
    };

    const ui = {
        type: "section",
        name: group,
        label: "Land management",
        label__de: "Bewirtschaftung",
        children: [
            {
                type: "note",
                label: "The following section relates to **all cultivated arable lands** of your farm, i.e. not only {{crop_name}}.",
                label__de:
                    "Der folgende Abschnitt bezieht sich auf **alle bewirtschafteten Ackerflächen** Ihres Betriebs, d.h. nicht nur auf {{crop_name}}.",
                widthLg: 8,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "####Tillage",
                label__de: "####Bodenbearbeitung",
                styleBreakRowAfter: true,
                styleClassName: "mt-3 mb-2",
            },
            {
                type: "select_one",
                name: `${group}.tillage_latest`,
                label: "What is the main type of tillage on your arable land?",
                label__de:
                    "Welche Bodenbearbeitung findet auf Ihren Ackerflächen vorrangig statt?",
                hint: "If reduced tillage or no-till is practiced at a minimum once every 3 years, the respective tillage type shall be selected as the main type.",
                hint__de:
                    "Sollte reduzierte Bodenbearbeitung oder Direktsaat mindestens alle 3 Jahre angewendet werden, ist die jeweilige Bodenbearbeitungsmethode als vorrangig auszuwählen.",
                options: selectOptions("tillage_type"),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.tillage_changed`,
                label: "Imagine the period from {{start_year}} to {{end_year}}. Has your main tillage practice changed during this period?",
                label__de:
                    "Stellen Sie sich den Zeitraum von {{start_year}} bis {{end_year}} vor. Hat sich Ihre vorrangige Bodenbearbeitung in diesem Zeitraum geändert?",
                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                widthLg: 6,
                styleBreakRowAfter: true,
            },
            ...YEARS_AGO.map((yearAgo) => [
                {
                    type: "note",
                    label: "#####Practice in {{year}}",
                    label__de: "#####Praxis in {{year}}",
                    args: { year: year - yearAgo },
                    condition: (watch) =>
                        watch(`${group}.tillage_changed`) == "yes",
                    styleBreakRowAfter: true,
                    styleClassName: "mt-3 mb-0",
                },
                {
                    type: "select_one",
                    name: `${group}.tillage_${yearAgo}y_ago`,
                    label: "In {{year}}, what was the main tillage practice?",
                    label__de:
                        "Wie war die vorrangige Bodenbearbeitung im Jahr {{year}}?",
                    args: { year: year - yearAgo },
                    options: selectOptions("tillage_type"),
                    condition: (watch) =>
                        watch(`${group}.tillage_changed`) == "yes",
                    widthLg: 4,
                },
                {
                    type: "number",
                    name: `${group}.tillage_${yearAgo}y_ago_area_share`,
                    label: "On approximately what percentage of your arable land have you used this type of tillage in {{year}}?",
                    label__de:
                        "Auf ungefähr wie viel Prozent Ihrer Ackerfläche haben Sie diese Bodenbearbeitung in {{year}} angewandt?",
                    args: { year: year - yearAgo },
                    unit: "%",
                    condition: (watch) =>
                        watch(`${group}.tillage_changed`) == "yes",
                    widthLg: 5,
                    styleBreakRowAfter: true,
                },
            ]).reduce((prev, curr) => [...prev, ...curr]),

            {
                type: "note",
                label: "####Crop residues",
                label__de: "####Erntereste",
                styleBreakRowAfter: true,
                styleClassName: "mt-5 mb-2",
            },
            {
                type: "select_one",
                name: `${group}.residues_latest`,
                label: "Imagine all your crops. Do most of your crop residues remain in the field?",
                label__de:
                    "Stellen Sie sich alle Ihre Anbaukulturen vor. Verbleiben die Erntereste Ihrer Anbaukulturen überwiegend auf dem Feld?",
                hint: "Minimum half of crops",
                hint__de: "Mindestens Hälfte der Kulturen",
                options: selectOptions("land_residue_management"),
                styleBreakRowAfter: true,
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.residues_changed`,
                label: "Imagine the period from {{start_year}} to {{end_year}}. Has your crop residue management changed during this period?",
                label__de:
                    "Stellen Sie sich den Zeitraum von {{start_year}} bis {{end_year}} vor. Hat sich Ihr Ernterestemanagement in diesem Zeitraum geändert?",
                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                widthLg: 6,
            },
            ...YEARS_AGO.map((yearAgo) => [
                {
                    type: "note",
                    label: "#####Practice in {{year}}",
                    label__de: "#####Praxis in {{year}}",
                    args: { year: year - yearAgo },
                    condition: (watch) =>
                        watch(`${group}.residues_changed`) == "yes",
                    styleBreakRowAfter: true,
                    styleClassName: "mt-3 mb-0",
                },
                {
                    type: "select_one",
                    name: `${group}.residues_${yearAgo}y_ago`,
                    label: "In {{year}}, what was your crop residue management?",
                    label__de:
                        "Im Jahr {{year}}, wie war Ihr Ernterestemanagement?",
                    args: { year: year - yearAgo },
                    options: selectOptions("land_residue_management"),
                    condition: (watch) =>
                        watch(`${group}.residues_changed`) == "yes",
                    widthLg: 4,
                },
                {
                    type: "number",
                    name: `${group}.residues_${yearAgo}y_ago_area_share`,
                    label: "On approximately what percentage of your arable land have you used this type of residue management?",
                    label__de:
                        "Auf ungefähr wie viel Prozent Ihrer Ackerfläche haben Sie dieses Ernterestemanagement angewandt?",
                    unit: "%",
                    condition: (watch) =>
                        watch(`${group}.residues_changed`) == "yes",
                    widthLg: 5,
                    styleBreakRowAfter: true,
                },
            ]).reduce((prev, curr) => [...prev, ...curr]),
            {
                type: "note",
                label: "####Organic inputs",
                label__de: "####Organische Düngung",
                styleBreakRowAfter: true,
                styleClassName: "mt-5 mb-2",
            },
            {
                type: "select_one",
                name: `${group}.organic_fertilization_latest`,
                label: "Do you regularly use organic fertilizers on your arable land?",
                label__de:
                    "Wird auf Ihren Ackerflächen regelmäßig organisch gedüngt?",
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.organic_fertilization_changed`,
                label: "Imagine the period from {{start_year}} to {{end_year}}. Has the proportion of your arable land that is regularly fertilized organically changed during this period?",
                label__de:
                    "Stellen Sie sich den Zeitraum von {{start_year}} bis {{end_year}} vor. Hat sich der Anteil Ihrer Ackerfläche, der regelmäßig organisch gedüngt wird, in diesem Zeitraum geändert?",

                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                widthLg: 6,
            },
            ...YEARS_AGO.map((yearAgo) => [
                {
                    type: "note",
                    label: "#####Practice in {{year}}",
                    label__de: "#####Praxis in {{year}}",
                    args: { year: year - yearAgo },
                    condition: (watch) =>
                        watch(`${group}.organic_fertilization_changed`) ==
                        "yes",
                    styleBreakRowAfter: true,
                    styleClassName: "mt-3 mb-0",
                },
                {
                    type: "number",
                    name: `${group}.organic_fertilization_${yearAgo}y_ago_area_share`,
                    label: "In {{year}}, approximately what percentage of the arable land did you fertilize organically?",
                    label__de:
                        "Im Jahr {{year}}, auf ungefähr wie viel Prozent der Ackerfläche wurde organisch gedüngt?",
                    args: { year: year - yearAgo },
                    unit: "%",
                    condition: (watch) =>
                        watch(`${group}.organic_fertilization_changed`) ==
                        "yes",
                    widthLg: 5,
                    styleBreakRowAfter: true,
                },
            ]).reduce((prev, curr) => [...prev, ...curr]),
            {
                type: "note",
                label: "####Cover crops",
                label__de: "####Zwischenfrüchte",
                styleBreakRowAfter: true,
                styleClassName: "mt-5 mb-2",
            },
            {
                type: "select_one",
                name: `${group}.cover_crops_latest`,
                label: "Is substantial biomass from cover crops introduced into the soil?",
                label__de:
                    "Wird nennenswert Biomasse aus Zwischenfrüchten in den Boden eingebracht?",
                hint: "Minimum every 3 years",
                hint__de: "Mindestens alle 3 Jahre",
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.cover_crops_changed`,
                label: "Imagine the period from {{start_year}} to {{end_year}}. Has your cultivation of cover crops changed over this period?",
                label__de:
                    "Stellen Sie sich den Zeitraum von {{start_year}} bis {{end_year}} vor. Hat sich über diesen Zeitraum Ihr Anbau von Zwischenfrüchten geändert?",
                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                widthLg: 6,
            },
            ...YEARS_AGO.map((yearAgo) => [
                {
                    type: "note",
                    label: "#####Practice in {{year}}",
                    label__de: "#####Praxis in {{year}}",
                    args: { year: year - yearAgo },
                    condition: (watch) =>
                        watch(`${group}.cover_crops_changed`) == "yes",
                    styleBreakRowAfter: true,
                    styleClassName: "mt-3 mb-0",
                },
                {
                    type: "number",
                    name: `${group}.cover_crops_${yearAgo}y_ago_area_share`,
                    label: "In {{year}}, on approximately what percentage of arable land were cover crops regularly grown and was significant biomass introduced into the soil?",
                    label__de:
                        "Im Jahr {{year}}, auf ungefähr wie viel Prozent der Ackerfläche wurden regelmäßig Zwischenfrüchte angebaut und nennenswert Biomasse in den Boden eingebracht?",
                    args: { year: year - yearAgo },
                    unit: "%",
                    condition: (watch) =>
                        watch(`${group}.cover_crops_changed`) == "yes",
                    widthLg: 5,
                    styleBreakRowAfter: true,
                },
            ]).reduce((prev, curr) => [...prev, ...curr]),
        ],
    };

    return { group, data, ui, defaults };
};
