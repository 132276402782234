import Form from "react-bootstrap/Form";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { get, useFormContext } from "react-hook-form";
import InputGroup from "react-bootstrap/InputGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { InfoButton } from "./InfoButton";

export function BaseSelectHorizontalButtons({
    name,
    options,
    label,
    unit,
    hint,
    form,
    info,
}) {
    const {
        register,
        watch,
        setValue,
        trigger,
        formState: { errors },
    } = form ? form : useFormContext();

    const error = get(errors, name);

    return (
        <Form.Group controlId={name}>
            <Form.Label>
                {label}
                {unit && <> ({unit})</>}
            </Form.Label>

            <div className={error && "is-invalid"}>
                <ButtonToolbar aria-label="Toolbar with optional info button and toggle buttons">
                    {info && (
                        <div className="me-2">
                            <InfoButton
                                infoTitle={info.title}
                                infoText={info.text}
                            ></InfoButton>
                        </div>
                    )}

                    <ToggleButtonGroup
                        type="radio"
                        value={watch(name)}
                        name={name}
                        onChange={(value) => {
                            setValue(name, value);
                            trigger(name);
                        }}
                    >
                        {options.map((o, i) => (
                            <ToggleButton
                                key={`${name}-${i}`}
                                id={`${name}-${i}`}
                                value={o.value}
                                type="radio"
                                variant={
                                    error ? "outline-danger" : "outline-primary"
                                }
                                {...register(name)}
                            >
                                {o.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </ButtonToolbar>
            </div>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
            <Form.Control.Feedback type="invalid">
                {error?.message}
            </Form.Control.Feedback>
        </Form.Group>
    );
}
