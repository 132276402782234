import { number, object, string } from "yup";

export const soilGenericSchema = function ({}, { selectOptions }) {
    const group = "soil_generic";

    const data = object({
        characteristic: string().required().oneOfOptions("soil_characteristic"),
        texture: string().required().oneOfOptions("soil_texture"),
        organic_matter: string().required().oneOfOptions("soil_organic_matter"),
        drainage: string().required().oneOfOptions("soil_drainage"),
        ph_value: string().required().oneOfOptions("soil_ph_value"),
        organic_matter_value: number()
            .min(0.01)
            .max(100)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("organic_matter", (v) => v == "Custom_value"),
    });

    const ui = {
        type: "section",
        name: group,
        label: "Soil properties",
        label__de: "Bodeneigenschaften",
        badge: "per_area",
        children: [
            {
                type: "select_one",
                name: `${group}.characteristic`,
                label: "Soil characteristics",
                label__de: "Bodencharakteristik",
                options: selectOptions("soil_characteristic"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.texture`,
                label: "Soil texture",
                label__de: "Bodenart",
                options: selectOptions("soil_texture"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.organic_matter`,
                label: "Soil organic matter (SOM)",
                label__de: "Organische Bodensubstanz (OBS)",
                unit: "%",
                options: selectOptions("soil_organic_matter"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.drainage`,
                label: "Soil drainage",
                label__de: "Boden-Wasserdurchlässigkeit",
                options: selectOptions("soil_drainage"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.ph_value`,
                label: "Soil pH",
                label__de: "Boden-pH-Wert",
                options: selectOptions("soil_ph_value"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.organic_matter_value`,
                label: "Soil organic matter (SOM)",
                label__de: "Organische Bodensubstanz (OBS)",
                unit: "%",
                condition: (watch) => {
                    const val = watch(`${group}.organic_matter`);
                    return val == "Custom_value";
                },
                widthLg: 4,
            },
        ],
    };

    return { group, data, ui };
};
