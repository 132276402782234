import { Trans, useLingui } from "@lingui/react";
import Alert from "react-bootstrap/Alert";
import RForm from "react-bootstrap/Form";
import { FormProvider, get } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { useListProductsQuery } from "../../services/api";
import { LoadingSpinner } from "../elements/LoadingSpinner";

export default function SurveyForm({ farm, form, submitHandler }) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);

    const dispatch = useDispatch();

    const {
        handleSubmit,
        trigger,
        getValues,
        register,
        formState: { errors, isValidating, isFormStateSubmitting },
    } = form;

    const { data: products, isLoading: productsLoading } = useListProductsQuery(
        {}
    );
    const filteredProducts = (products || []).filter(
        (p) => p.type == "PRODUCT"
    );

    function isProductValid(product) {
        for (const c of products) {
            if (c.id == product) return true;
        }
        return false;
    }

    const years = Array.from(
        { length: 11 },
        (_, i) => `${new Date().getFullYear() - i}`
    );
    const isYearValid = (y) => years.includes(y);

    return (
        <FormProvider {...form}>
            {false && errorMessage && (
                <Alert key="warning" variant="warning" className="my-5">
                    <h5>{errorMessage.message}</h5>
                </Alert>
            )}

            {false && loadingMessage && (
                <LoadingSpinner label={loadingMessage} />
            )}

            <RForm
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                    return false;
                }}
                noValidate
            >
                <RForm.Group className="mb-3" controlId="product">
                    <RForm.Label>
                        <Trans id="surveys.form.product" />
                    </RForm.Label>
                    <RForm.Select
                        isInvalid={get(errors, "product")}
                        {...register("product", {
                            validate: isProductValid,
                            required: true,
                        })}
                    >
                        {productsLoading && (
                            <option value={""}>
                                <Trans id="loading.generic" />
                            </option>
                        )}
                        <option value={""}>
                            <Trans id="elements.please_select"></Trans>
                        </option>

                        {filteredProducts.map((p, i) => (
                            <option key={i} value={p.id}>
                                {p.name}
                            </option>
                        ))}
                    </RForm.Select>
                    <RForm.Text muted>
                        <Trans id="surveys.form.product_missing_hint" />
                    </RForm.Text>
                </RForm.Group>
                <RForm.Group className="mb-3" controlId="raw_material">
                    <RForm.Label>
                        <Trans id="surveys.form.raw_material" />
                    </RForm.Label>
                    <RForm.Control
                        type="text"
                        isInvalid={get(errors, "raw_material")}
                        {...register("raw_material", {
                            required: true,
                            maxLength: 100,
                            minLength: 3,
                            setValueAs: (v) => `${v}`.trim(),
                        })}
                    />
                </RForm.Group>
                <RForm.Group className="mb-3" controlId="title">
                    <RForm.Label>
                        <Trans id="surveys.form.title" />
                    </RForm.Label>
                    <RForm.Control
                        type="text"
                        isInvalid={get(errors, "title")}
                        {...register("title", {
                            required: false,
                            maxLength: 100,
                            minLength: 3,
                            setValueAs: (v) => `${v}`.trim(),
                        })}
                    />
                </RForm.Group>
                <RForm.Group className="mb-3" controlId="year">
                    <RForm.Label>
                        <Trans id="surveys.form.year" />
                    </RForm.Label>
                    <RForm.Select
                        isInvalid={get(errors, "year")}
                        {...register("year", {
                            validate: isYearValid,
                            required: true,
                        })}
                    >
                        {(years || []).map((y, i) => (
                            <option key={i} value={y}>
                                {y}
                            </option>
                        ))}
                    </RForm.Select>
                </RForm.Group>
            </RForm>
        </FormProvider>
    );
}
