import mustache from "mustache";
import { getText } from "./i18n";

function _fieldText(field, sectionKey, attribute, child = null) {
    const key = attribute;
    let obj = field;
    if (child) obj = field[child];

    if (!obj || !obj[key]) return null;

    let { message, language } = getText("form", sectionKey, obj[key]);
    if (!message) {
        message = obj[key];
    }

    return message;
}

export function fieldLabel(field, sectionKey, args = {}) {
    if (!field.label) return null;

    let { message, language } = getText("form", sectionKey, field.label);
    if (!message) {
        message = field.label;
    }

    // TODO: refactor crop_name stuff into own function or prepare context better
    let internalArgs = { ...args };
    if (args?.context) {
        delete internalArgs["context"]; // do not overload args

        const crop = args.context?.assessment?.survey?.crop;
        const rawMaterial = args.context?.assessment?.survey?.raw_material_name;
        for (let translation of crop?.translations || []) {
            if (translation?.language == language) {
                internalArgs["crop_name"] = translation?.label;
            }
        }
        if (!internalArgs["crop_name"]) {
            internalArgs["crop_name"] = crop?.name;
        }
        internalArgs["raw_material"] = rawMaterial;
        internalArgs["requesting_organization"] =
            args.context?.assessment?.survey?.requesting_organization?.name;

        if (!internalArgs["year"])
            internalArgs["year"] = args.context?.assessment?.survey?.year;
    }

    if (
        args?.context?.textArgs &&
        args?.context?.textArgs.constructor == Object
    ) {
        internalArgs = { ...internalArgs, ...args.context.textArgs };
    }

    if (field?.args && field.args.constructor == Object) {
        internalArgs = { ...internalArgs, ...field.args };
    }

    return mustache.render(message, internalArgs);
}

export function fieldUnit(field, sectionKey) {
    return _fieldText(field, sectionKey, "unit");
}

export function fieldHint(field, sectionKey, args = {}) {
    // return _fieldText(field, sectionKey, "hint"); <-- original
    if (!field.hint) return null;

    let { message, language } = getText("form", sectionKey, field.hint);
    if (!message) {
        message = field.hint;
    }

    // TODO: refactor crop_name stuff into own function or prepare context better
    let internalArgs = { ...args };
    if (args?.context) {
        delete internalArgs["context"]; // do not overload args

        const crop = args.context?.assessment?.survey?.crop;
        const rawMaterial = args.context?.assessment?.survey?.raw_material_name;
        for (let translation of crop?.translations || []) {
            if (translation?.language == language) {
                internalArgs["crop_name"] = translation?.label;
            }
        }
        if (!internalArgs["crop_name"]) {
            internalArgs["crop_name"] = crop?.name;
        }
        internalArgs["raw_material"] = rawMaterial;
        internalArgs["requesting_organization"] =
            args.context?.assessment?.survey?.requesting_organization?.name;

        if (!internalArgs["year"])
            internalArgs["year"] = args.context?.assessment?.survey?.year;
    }

    if (
        args?.context?.textArgs &&
        args?.context?.textArgs.constructor == Object
    ) {
        internalArgs = { ...internalArgs, ...args.context.textArgs };
    }

    if (field?.args && field.args.constructor == Object) {
        internalArgs = { ...internalArgs, ...field.args };
    }

    return mustache.render(message, internalArgs);
}

export function fieldCheckText(field, sectionKey) {
    return _fieldText(field, sectionKey, "checkText");
}

export function fieldInfo(field, sectionKey) {
    const title = _fieldText(field, sectionKey, "title", "info");
    const text = _fieldText(field, sectionKey, "text", "info");

    if (title || text) return { title, text };
    return null;
}

export function fieldAddButtonLabel(field, sectionKey) {
    return _fieldText(field, sectionKey, "add_label");
}
