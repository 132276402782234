import { Trans } from "@lingui/react";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { get, useFormContext } from "react-hook-form";
import { usePostGeocodeMutation } from "../../services/api";
import { useAuthToken } from "../../services/hooks";
import { formatNumber } from "../../utils/format";

const GEOCODING_DELAY = 1200;

/**
 * Field component that takes a postcode and resolves its respective geocoordinates.
 * The field uses the following format on the data schema:
 *
 * postcode: str
 * postcode_not_found: bool // indicates that postcode is not real
 * postcode_geocoding_failed: bool // indivates that resolving the postcode failed (but postcode could be valid)
 *
 * If a postcode could not be found, GPS coordinates should be entered manually by the user.
 * If geocoding failed, the user is informed and asked to Retry again later.
 */
export function PostcodeField({
    name,
    registerOptions,
    label,
    hint,
    countryFieldName,
    latitudeFieldName,
    longitudeFieldName,
    postcodeNotFoundFieldName,
    postcodeGeocodingFailedFieldName,
    form,
    context,
}) {
    const authToken = useAuthToken();
    const { register, setValue, getFieldState, formState, trigger, watch } =
        form ? form : useFormContext();

    const postcode = watch(name);
    const countryCode = watch(countryFieldName);
    const latitude = watch(latitudeFieldName);
    const longitude = watch(longitudeFieldName);
    const postcodeNotFound = watch(postcodeNotFoundFieldName);
    const geocodingFailed = watch(postcodeGeocodingFailedFieldName);

    const [fieldJustTouched, setFieldJustTouched] = useState(false); // used to show "loading" during request delay
    const [fieldWasFocused, setFieldWasFocused] = useState(false); // used to determine whether user has actually changed anything

    const countryFieldState = getFieldState(countryFieldName, formState);
    const countryFieldDirty = countryFieldState.isDirty;
    const postcodeFieldState = getFieldState(name, formState);
    const postcodeFieldDirty = postcodeFieldState.isDirty;

    const [
        postGeocode,
        {
            isLoading: isGeocoding,
            isSuccess: geocodingComplete,
            isError: geocodingRequestFailed,
        },
    ] = usePostGeocodeMutation({});

    const geocodePostcode = async (countryCode, postcode) => {
        let result = null;
        try {
            result = await postGeocode({
                authToken: authToken,
                assignmentId: context?.assessment?.id,
                method: "postcode",
                countryCode: countryCode,
                postcode: postcode,
            }).unwrap();
            setValue(postcodeGeocodingFailedFieldName, false);
        } catch (error) {
            console.log("Geocoding/network failure:", error);
            setValue(postcodeGeocodingFailedFieldName, true);
        }

        if (result?.success) {
            setValue(latitudeFieldName, result?.latitude);
            setValue(longitudeFieldName, result?.longitude);
            setValue(postcodeNotFoundFieldName, false);
        } else {
            setValue(latitudeFieldName, null);
            setValue(longitudeFieldName, null);
            setValue(postcodeNotFoundFieldName, true);
        }
        setFieldJustTouched(false);
        trigger(postcodeGeocodingFailedFieldName);
        trigger(postcodeNotFoundFieldName);
    };

    useEffect(() => {
        // if any failure occurs(e.g. network error), set geocoding_failed data field
        if (geocodingRequestFailed) {
            setValue(postcodeGeocodingFailedFieldName, true);
        }
    }, [geocodingRequestFailed]);

    // handle changes to postcode or country field
    useEffect(() => {
        if (!fieldWasFocused)
            // field not actually changed, just pre-filled by backend data
            return;

        if (!postcode || postcode.length < 3) return;

        setFieldJustTouched(true);

        // delay postcode resolution by x milliseconds after typing in the postcode
        const timeOutId = setTimeout(
            () => geocodePostcode(countryCode, postcode),
            GEOCODING_DELAY
        );
        return () => clearTimeout(timeOutId);
    }, [postcode, countryCode, fieldWasFocused]);

    useEffect(() => {
        // once any field was changed by user, mark as having been focused
        if ((countryFieldDirty || postcodeFieldDirty) && !fieldWasFocused)
            setFieldWasFocused(true);
    }, [countryFieldDirty, postcodeFieldDirty, fieldWasFocused]);

    const isLoading = fieldJustTouched || isGeocoding;
    const isNotFound = postcodeNotFound;
    const isFailure = geocodingRequestFailed || geocodingFailed;
    const isEntered = postcode && postcode.length >= 3;
    const error = get(formState?.errors, name);

    return (
        <Form.Group controlId={name}>
            <Form.Label>{label}</Form.Label>
            <InputGroup>
                <Form.Control
                    {...register(name, {
                        ...registerOptions,
                        deps: [countryFieldName],
                    })}
                    isInvalid={error}
                    aria-label={label}
                />
                {error && (
                    <Form.Control.Feedback type="invalid">
                        {error?.message}
                    </Form.Control.Feedback>
                )}
            </InputGroup>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
            <Form.Control
                {...register(postcodeNotFoundFieldName, {
                    deps: [name],
                })}
                type="hidden"
            />
            <Form.Control
                {...register(postcodeGeocodingFailedFieldName, {
                    deps: [name],
                })}
                type="hidden"
            />
            <Form.Text className="text-muted">
                {isLoading && <Trans id="loading.generic" />}
                {!isLoading && !isNotFound && !isFailure && isEntered && (
                    <>
                        <span className="text-success">
                            <Trans id="form.geocoding.postcode_found" />{" "}
                            <span className="small">
                                <br />
                                [GPS lat {formatNumber(latitude, 4)} lon{" "}
                                {formatNumber(longitude, 4)}]
                            </span>
                        </span>
                    </>
                )}
                {!isLoading && geocodingComplete && isNotFound && (
                    <span className="small">
                        <Trans id="form.geocoding.postcode_not_found" />
                    </span>
                )}
                {!isLoading && isFailure && (
                    <>
                        <span className="text-danger small">
                            <Trans id="form.geocoding.postcode_geocoding_failed" />
                        </span>
                        <br />
                        <Button
                            size="sm"
                            onClick={() =>
                                geocodePostcode(countryCode, postcode)
                            }
                        >
                            <Trans id="form.geocoding.postcode_retry" />
                        </Button>
                    </>
                )}
            </Form.Text>
        </Form.Group>
    );
}
