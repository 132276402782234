import { Trans, useLingui } from "@lingui/react";
import { useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import React, { useDispatch, useSelector } from "react-redux";
import img_logo_agghg from "../../public/images/AgGHG_Logo.png?height=50, image.jpg?height=50 2x";
import img_logo_farmpact from "../../public/images/farmpact-black.png?height=42, image.jpg?height=42 2x";
import { LANG_SUPPORTED } from "../constants";
import { selectCurrentAssessment } from "../features/assessmentSlice";
import { selectCurrentUser } from "../features/userSlice";
import {
    changeViewLanguage,
    selectCurrentViewLanguage,
} from "../features/viewSlice";
import { useSelfUser } from "../services/hooks";
import { InfoModal } from "./InfoModal";
import { FormState, selectFormState } from "../features/form/formSlice";
import { isFarmpactDomain } from "../utils/ui";

export default function Header(props) {
    const { i18n } = useLingui();
    const dispatch = useDispatch();

    // User details if link-based self-assessment
    const assessment_user = useSelector(selectCurrentUser);
    const { userData: loggedInUser } = useSelfUser();

    const isAssessmentUser =
        assessment_user.organization && assessment_user.country;
    const isLoggedInUser = loggedInUser?.first_name || loggedInUser?.last_name;

    let userLabel = i18n._("header.user_name.loading");
    if (isAssessmentUser) {
        userLabel = `${assessment_user.organization}, ${assessment_user.country}`;
    } else if (isLoggedInUser) {
        userLabel = `${loggedInUser?.first_name} ${loggedInUser?.last_name}`;
    }

    let availableLanguages = LANG_SUPPORTED;

    const assessment = useSelector(selectCurrentAssessment);
    const helpText = assessment?.survey.help_text;
    if (assessment && assessment?.survey?.languages) {
        availableLanguages = {};
        for (let lang of assessment?.survey?.languages) {
            availableLanguages[lang.key] = { label: lang.native_label };
        }
    }

    const language = useSelector(selectCurrentViewLanguage);
    const languageLabel = availableLanguages[language]?.label;

    const [showHelpModal, setHelpModal] = useState();

    const isFarmpact = useMemo(isFarmpactDomain, [1]);

    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            id="header"
            variant="light"
            fixed="top"
            bg="light"
        >
            <InfoModal
                title={/*i18n*/ i18n._("nav.help_button")}
                body={helpText}
                show={showHelpModal}
                onClose={() => setHelpModal(false)}
            />

            <Container fluid="lg">
                <Navbar.Brand
                    href="/"
                    className={"me-auto " + isFarmpact ? "pb-0 pt-2" : ""}
                    id="navbar-logo"
                >
                    {isFarmpact ? (
                        <img
                            src={img_logo_farmpact}
                            alt="Logo farmpact by sustainable"
                            height="42"
                        />
                    ) : (
                        <img
                            src={img_logo_agghg}
                            alt="Logo AgGHG by sustainable"
                            height="50"
                        />
                    )}
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    className="d-print-none"
                />
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="justify-content-end d-print-none"
                >
                    <Nav variant="pills">
                        <NavDropdown
                            title={languageLabel}
                            id="collasible-nav-dropdown"
                        >
                            {Object.keys(availableLanguages).map((lang) => (
                                <NavDropdown.Item
                                    key={lang}
                                    onClick={() =>
                                        dispatch(
                                            changeViewLanguage({
                                                language: lang,
                                            })
                                        )
                                    }
                                >
                                    {availableLanguages[lang].label}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {helpText && (
                            <Nav.Item>
                                <Nav.Link onClick={() => setHelpModal(true)}>
                                    <Trans id="nav.help_button">Help</Trans>
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        <NavDropdown
                            title={userLabel}
                            id="collasible-nav-dropdown"
                        >
                            <NavDropdown.Item href="/auth/logout/">
                                <Trans
                                    id="nav.not_yourself_logout"
                                    components={[<u />]}
                                />
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
