export function parseTsv(tsvString) {
    // TODO: may be replaced with a proper parsing library in the future, e.g. papaparse
    const rows = String(tsvString).split("\n");
    const header = rows[0].split("\t").map((x) => x.trim());

    const results = [];

    for (let i = 1; i < rows.length; i++) {
        const row = rows[i].split("\t").map((x) => x.trim());
        if (row.length <= 1) continue; // empty row
        const obj = {};
        row.forEach((x, i) => (obj[header[i]] = x));
        results.push(obj);
    }

    return results;
}

/**
 * Recursively removes 'ref' attributes from an object to that is can be converted to JSON.
 * @param {*} obj
 */
export function clearRefsFromObject(obj) {
    obj = { ...obj };
    if ("ref" in obj) {
        delete obj["ref"];
    }
    for (const [key, value] of Object.entries(obj)) {
        const child = obj[key];
        if (
            typeof child === "object" &&
            !Array.isArray(child) &&
            child !== null
        ) {
            obj[key] = clearRefsFromObject(child);
        }

        if (Array.isArray(child)) {
            obj[key] = child.map(clearRefsFromObject);
        }
    }
    return obj;
}
