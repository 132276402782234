import { Trans, useLingui } from "@lingui/react";
import { useEffect, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import {
    surveyEmissionEvaluationsExportAllExcelUrl,
    surveySubmissionsExportAllExcelUrl,
    useListSurveysQuery,
} from "../../services/api";
import { useRequireAuthenticated, useSelfUser } from "../../services/hooks";
import { IconTooltipButton } from "../elements/IconPopoverButton";
import { LoadingSpinner } from "../elements/LoadingSpinner";
import AddEditSurveyModal from "./AddEditSurveyModal";
import SurveyGridItem from "./SurveyGridItem";
import SurveyList from "./SurveyList";

export default function SurveyListContainer() {
    useRequireAuthenticated();
    const { i18n } = useLingui();

    const { userData } = useSelfUser();

    const { data, isLoading, error } = useListSurveysQuery();

    const [selectedView, setSelectedView] = useState("list");
    const isGridView = selectedView == "grid";
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (data && data.length > 0 && data.every((s) => s.type == "FARM"))
            setSelectedView("grid");
    }, [data]);

    // Show "Add Survey" button if there are any existing supplier surveys (non-farm surveys) in the list
    const createButtonAvailable =
        data && data.length > 0 && data.some((s) => s.type == "SUPPLIER");

    //const navigate = useNavigate();

    const urlExportSubmissions = surveySubmissionsExportAllExcelUrl({});
    const urlExportEmissionEvaluations =
        surveyEmissionEvaluationsExportAllExcelUrl({});

    return (
        <Container className="mx-auto my-5 ">
            <AddEditSurveyModal show={show} closeModal={() => setShow(false)} />
            <ButtonToolbar className="mb-3 float-end">
                {createButtonAvailable && (
                    <ButtonGroup className="ms-2">
                        <IconTooltipButton
                            infoText={i18n._("survey.add_survey_button_info")}
                            label={i18n._("survey.add_survey_button")}
                            iconName="plus-lg"
                            buttonVariant="primary"
                            onClick={() => setShow(true)}
                        />
                    </ButtonGroup>
                )}
                <ButtonGroup className="ms-2">
                    <Dropdown id="more-dropdown">
                        <Dropdown.Toggle
                            variant="light"
                            id="more-dropdown-toggle"
                        ></Dropdown.Toggle>

                        <Dropdown.Menu align={"end"}>
                            <Dropdown.Item
                                href={urlExportSubmissions}
                                target="_blank"
                            >
                                <Trans id="survey.export_submissions_excel" />
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={urlExportEmissionEvaluations}
                                target="_blank"
                            >
                                <Trans id="survey.export_emission_evaluations_excel" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
            </ButtonToolbar>
            <h3>
                <Trans id="survey_list.header.title" />
            </h3>
            <div className="clearfix pb-3"></div>
            <Nav
                variant="underline"
                activeKey={selectedView}
                defaultActiveKey={selectedView}
                className="justify-content-end"
                onSelect={(key) => setSelectedView(key)}
                // size="sm"
            >
                <Nav.Item>
                    <Nav.Link eventKey="list">
                        <i className="bi bi-list"></i> List
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="grid">
                        <i className="bi bi-grid"></i> Grid
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            {isLoading && <LoadingSpinner />}
            {isGridView && data && (
                <Row md={2} lg={3} className="my-5">
                    {data.map((survey, i) => (
                        <Col key={i} className="mb-5">
                            <SurveyGridItem survey={survey} />
                        </Col>
                    ))}
                </Row>
            )}
            {!isGridView && data && (
                <SurveyList data={data} setSelectedSurvey={null} />
            )}
        </Container>
    );
}
