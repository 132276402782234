import { useSelector } from "react-redux";
import { FormState } from "../../features/form/formSlice";
import Form from "./Form";
import FormResult from "./FormResult";
import FormWelcome from "./FormWelcome";

export default function FormContainer() {
    const formState = useSelector((state) => state.form.state);

    return (
        <div>
            {formState == FormState.welcome && <FormWelcome />}
            {formState == FormState.ongoing && <Form />}
            {formState == FormState.completed && <FormResult />}
        </div>
    );
}
