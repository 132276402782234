import { object, string } from "yup";

export const notesGenericSchema = function (
    { surveyParams, farm },
    { selectOptions }
) {
    const group = "notes_generic";

    const data = object({
        notes: string().notRequired(),
    });

    const defaults = {};

    const ui = {
        type: "section",
        name: group,
        label__de: "Notizen",
        label: "Notes",
        children: [
            {
                type: "text",
                name: `${group}.notes`,
                label: "Notes",
                label__de: "Notizen",
                hint: "Not considered in calculations",
                hint__de: "Fließen nicht in Berechnung ein",
                widthLg: 7,
                asTextarea: true,
                styleBreakRowAfter: true,
            },
        ],
    };

    return { group, data, ui, defaults };
};
