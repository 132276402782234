import { boolean, number, object, string } from "yup";

export const energyOffFarmProcessingSchema = function (
    { surveyParams, farm },
    { selectOptions, defaultUnit }
) {
    const group = "energy_off_farm_processing";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const data = object({
        energy_data_product_facility: string()
            .required()
            .oneOfOptions("energy_data_product_or_facility"),
        processing_steps: string(),
        diesel_processing: number().min(0).nanToUndefined().notRequired(),
        diesel_processing__unit: string().notRequired().oneOfOptions("volume"),
        petrol_processing: number().min(0).nanToUndefined().notRequired(),
        petrol_processing__unit: string().notRequired().oneOfOptions("volume"),
        electricity_processing: number().min(0).nanToUndefined().notRequired(),
        electricity_processing__unit: string()
            .notRequired()
            .oneOfOptions("energy"),
        coal_processing: number().min(0).nanToUndefined().notRequired(),
        coal_processing__unit: string().notRequired().oneOfOptions("weight"),
        natural_gas_processing: number().min(0).nanToUndefined().notRequired(),
        natural_gas_processing__unit: string()
            .notRequired()
            .oneOfOptions("energy"),
        oil_processing: number().min(0).nanToUndefined().notRequired(),
        oil_processing__unit: string().notRequired().oneOfOptions("volume"),
        propane_processing: number().min(0).nanToUndefined().notRequired(),
        propane_processing__unit: string().notRequired().oneOfOptions("volume"),
        biodiesel_processing: number().min(0).nanToUndefined().notRequired(),
        biodiesel_processing__unit: string()
            .notRequired()
            .oneOfOptions("volume"),
        bioethanol_processing: number().min(0).nanToUndefined().notRequired(),
        bioethanol_processing__unit: string()
            .notRequired()
            .oneOfOptions("volume"),
        wood_pellets_processing: number().min(0).nanToUndefined().notRequired(),
        wood_pellets_processing__unit: string()
            .notRequired()
            .oneOfOptions("weight"),
        fuel_wood_processing: number().min(0).nanToUndefined().notRequired(),
        fuel_wood_processing__unit: string()
            .notRequired()
            .oneOfOptions("weight"),

        biofuel_sustainably_sourced: string()
            .notRequired()
            .oneOfOptions("bioenergy_origin", true),
        wood_sustainably_sourced: string()
            .notRequired()
            .oneOfOptions("bioenergy_origin", true),

        electricity_purchased_renewable: string().conditionalOneOfOptions(
            "yes_no",
            ["electricity_processing"],
            (electricity_processing) =>
                electricity_processing && electricity_processing > 0
        ),
        electricity_generated_pv_wind_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        /* energetic_utilisation_of_raw_material_residues: string().oneOfOptions(selectOptions("yes_no")),
        residue_for_energy_description: string().requiredWhen("energetic_utilisation_of_raw_material_residues", (v) => v == "yes"),
        residue_for_energy_amount: number().min(0).nanToUndefined().requiredWhen("energetic_utilisation_of_raw_material_residues", (v) => v == "yes"),
        residue_for_energy_amount__unit: string().oneOfOptions("weight").requiredWhen("energetic_utilisation_of_raw_material_residues", (v) => v == "yes"), */
    });

    const defaults = {
        diesel_processing__unit: defaultUnit("volume", unitSystem).name,
        petrol_processing__unit: defaultUnit("volume", unitSystem).name,
        electricity_processing__unit: defaultUnit("energy", unitSystem).name,
        coal_processing__unit: defaultUnit("weight", unitSystem).name,
        natural_gas_processing__unit: defaultUnit("energy", unitSystem).name,
        oil_processing__unit: defaultUnit("volume", unitSystem).name,
        propane_processing__unit: defaultUnit("volume", unitSystem).name,
        biodiesel_processing__unit: defaultUnit("volume", unitSystem).name,
        bioethanol_processing__unit: defaultUnit("volume", unitSystem).name,
        wood_pellets_processing__unit: defaultUnit("weight", unitSystem).name,
        fuel_wood_processing__unit: defaultUnit("weight", unitSystem).name,
        residue_for_energy_amount__unit: defaultUnit("weight", unitSystem).name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Energy",
        children: [
            {
                type: "text",
                name: `${group}.processing_steps`,
                label: "Which processing and storage steps are carried out to produce {{crop_name}}?",
                hint: "E.g. washing, drying, cutting, sorting, cooling, packing etc.",
                widthLg: 7,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
            },
            {
                type: "select_one",
                name: `${group}.energy_data_product_facility`,
                widthLg: 6,
                label: "Are you providing energy data specific to the production of {{crop_name}} and its coproducts, or for the entire facility?",
                hint: "If available, product-specific energy data is preferred",
                options: selectOptions("energy_data_product_or_facility"),
                styleSelectHorizontalButtons: true,
                //styleClassName: "mt-5 horizontal-btn-align-end",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.diesel_processing`,
                label: "Diesel consumption",
                label__de: "Dieselverbrauch für Verarbeitung",
                unit: selectOptions("volume"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.petrol_processing`,
                label: "Petrol/Gas consumption",
                label__de: "Benzinverbrauch für Verarbeitung",
                unit: selectOptions("volume"),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.electricity_processing`,
                label: "Electricity consumption",
                label__de: "Stromverbrauch für Verarbeitung",
                unit: selectOptions("energy"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.coal_processing`,
                label: "Coal consumption",
                label__de: "Kohleverbrauch",
                unit: selectOptions("weight"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.natural_gas_processing`,
                label: "Natural gas consumption",
                label__de: "Erdgasverbrauch",
                unit: selectOptions("energy"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.oil_processing`,
                label: "(Fuel) Oil consumption",
                label__de: "(Heiz-)Ölverbrauch",
                unit: selectOptions("volume"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.propane_processing`,
                label: "Propane consumption",
                label__de: "Propangas-Verbrauch",
                unit: selectOptions("volume"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.biodiesel_processing`,
                label: "Biodiesel consumption",
                label__de: "Biodieselverbrauch",
                unit: selectOptions("volume"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.bioethanol_processing`,
                label: "Bioethanol consumption",
                label__de: "Bioethanolverbrauch",
                unit: selectOptions("volume"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.wood_pellets_processing`,
                label: "Wood pellets consumption",
                label__de: "Holzpellets-Verbrauch",
                unit: selectOptions("weight"),
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.fuel_wood_processing`,
                label: "Fuel wood consumption",
                label__de: "Feuerholz-Verbrauch",
                unit: selectOptions("weight"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.biofuel_sustainably_sourced`,
                label: "Is the used biodiesel/bioethanol from a sustainable origin?",
                label__de:
                    "Stammt der Biodiesel/das Bioethanol aus nachhaltigen Anbau?",
                options: selectOptions("bioenergy_origin"),
                widthLg: 6,
                styleSelectHorizontalButtons: false,
                condition: (watch) =>
                    watch(`${group}.biodiesel_processing`) > 0 ||
                    watch(`${group}.bioethanol_processing`) > 0,
            },
            {
                type: "select_one",
                name: `${group}.wood_sustainably_sourced`,
                label: "Are the used wood pellets/fuel wood from a sustainable origin?",
                label__de:
                    "Stammt Feuerholz/Holzpellets aus nachhaltigen Anbau?",
                options: selectOptions("bioenergy_origin"),
                widthLg: 6,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: false,
                condition: (watch) =>
                    watch(`${group}.wood_pellets_processing`) > 0 ||
                    watch(`${group}.fuel_wood_processing`) > 0,
            },
            {
                type: "note",
                label: "Electricity source",
                label__de: "Stromquelle",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
                condition: (watch) =>
                    watch(`${group}.electricity_processing`) > 0,
            },
            {
                type: "select_one",
                name: `${group}.electricity_purchased_renewable`,
                label: "Do you purchase electricity from a renewable tariff?",
                label__de: "Kaufen Sie Ihren Strom über einen Ökostromvertrag?",
                hint: "Select yes if your electricity contract consists of 100% green electricity",
                hint__de:
                    "Wählen Sie ja, wenn Ihr eingekaufter Strom aus 100% Ökostrom besteht",
                widthLg: 4,
                options: selectOptions("yes_no"),
                condition: (watch) =>
                    watch(`${group}.electricity_processing`) > 0,
                styleSelectHorizontalButtons: true,
            },
            {
                type: "number",
                name: `${group}.electricity_generated_pv_wind_share`,
                label: "Percentage of self-generated electricity from PV or wind",
                label__de:
                    "Anteil des Stromverbrauchs, der über erneuerbare Eigenerzeugung gedeckt ist (PV, Wind, Biogas)",
                unit: "%",
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.electricity_processing`) > 0,
            },
            /*             {
                type: "select_one",
                name: `${group}.energetic_utilisation_of_raw_material_residues`,
                label: "Did you burn raw material residues from producing {{crop_name}} for energy onsite?",
                options: selectOptions("yes_no"),
                widthLg: 6,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
            }, */

            // check later whether the section below should be included or not (since waste disposal method is already asked for in product section)
            /* {
    type: "text",
    name: `${group}.residue_for_energy_description`,
    label: "Type of residue from processing {{crop_name}} utilised?",
    hint: "E.g. husks, shells, pulp, stalks, peel, leaves etc.",
    widthLg: 7,
    condition: (watch) => watch(`${group}.energetic_utilisation_of_raw_material_residues`) == "yes",
},
{
    type: "number",
    name:`${group}.residue_for_energy_amount`,
    label: "Amount of residue burnt onsite",
    unit: selectOptions("weight"),
    condition: (watch) => watch(`${group}.energetic_utilisation_of_raw_material_residues`) == "yes",
    widthLg: 5,
    styleBreakRowAfter: true,
}, */
        ],
    };

    return { group, data, ui, defaults };
};
