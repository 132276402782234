import { number, object, string } from "yup";

export const residueManagementGenericSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit }
) {
    const group = "residue_management_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const enableResidueAmountPerProduct =
        surveyParams?.ENABLE_RESIDUES_PER_HARVEST_WEIGHT == "true";

    const data = object({
        residue_management: string()
            .required()
            .oneOfOptions("residue_management"),
        residue_amount: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .requiredWhen(
                "residue_amount_rate_type",
                (v) => enableResidueAmountPerProduct && v == "per_area"
            ),
        residue_amount__unit: string()
            .notRequired()
            .oneOfOptions("weight_area_rate"),
        ...(enableResidueAmountPerProduct && {
            residue_amount_rate_type: string()
                .required()
                .oneOfOptions("residue_amount_rate_type"),
            residue_amount_per_product: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .requiredWhen(
                    "residue_amount_rate_type",
                    (v) => enableResidueAmountPerProduct && v == "per_product"
                ),
            residue_amount_per_product__unit: string()
                .notRequired()
                .oneOfOptions("weight_weight_rate"),
        }),
    });

    const defaults = {
        residue_amount_rate_type: "none",
        residue_amount__unit: defaultUnit("weight_area_rate", unitSystem).name,
        residue_amount_per_product__unit: defaultUnit(
            "weight_weight_rate",
            unitSystem
        ).name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Crop residues",
        label__de: "Erntereste",
        badge: "per_area",
        children: [
            {
                type: "select_one",
                name: `${group}.residue_management`,
                label: "Crop residue management",
                label__de: "Ernterestemanagement",
                options: selectOptions("residue_management"),
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            enableResidueAmountPerProduct && {
                type: "select_one",
                name: `${group}.residue_amount_rate_type`,
                label: "Can you provide crop residue amounts to improve the accuracy of your results?",
                options: selectOptions("residue_amount_rate_type"),
                widthLg: 5,
            },
            {
                type: "number",
                name: `${group}.residue_amount`,
                label: "Optional: Dry matter residue amount",
                label__de: "Optional: Trockenmasse-Ernterestemenge",
                hint: "Only provide if amount is known",
                hint__de: "Nur angeben, wenn Menge bekannt",
                unit: selectOptions("weight_area_rate"),
                condition: (watch) =>
                    !enableResidueAmountPerProduct ||
                    watch(`${group}.residue_amount_rate_type`) == "per_area",
                widthLg: 4,
            },
            enableResidueAmountPerProduct && {
                type: "number",
                name: `${group}.residue_amount_per_product`,
                label: "Optional: Dry matter residue amount per harvested product",
                hint: "Example: Per tonne of harvested product, around 30 kg of dry matter dead plant residue (e.g., straw) may be occur",
                unit: selectOptions("weight_weight_rate"),
                condition: (watch) =>
                    watch(`${group}.residue_amount_rate_type`) == "per_product",
                widthLg: 4,
            },
        ],
    };

    return { group, data, ui, defaults };
};
