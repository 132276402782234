import { createSlice } from "@reduxjs/toolkit";

export const FormState = Object.freeze({
    welcome: "welcome",
    ongoing: "ongoing",
    completed: "completed",
});

const initialState = {
    state: FormState.welcome,
};

export const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        initWelcome: (state) => {
            state.state = FormState.welcome;
        },
        enterForm: (state) => {
            state.state = FormState.ongoing;
        },
        completeForm: (state) => {
            state.state = FormState.completed;
        },
    },
});

export const { initWelcome, enterForm, completeForm } = formSlice.actions;

export const selectFormState = (state) => state.form.state;

export default formSlice.reducer;
