import { detect, fromCookie, fromNavigator } from "@lingui/detect-locale";
import { LANG_DEFAULT_FALLBACK, LANG_SUPPORTED } from "./constants";

export const CSRF_COOKIE_NAME = "csrftoken";

export const DETECTED_LANGUAGE = (function () {
    try {
        // document.cookie context not available during jest testing
        return detect(
            fromCookie("lang"),
            fromNavigator(),
            LANG_DEFAULT_FALLBACK
        );
    } catch (e) {
        return "en";
    }
})();
export const LANGUAGE = DETECTED_LANGUAGE;
