import { number, object, string } from "yup";

export const waterOffFarmProcessingSchema = function (
    { surveyParams, farm },
    { selectOptions }
) {
    const group = "water_off_farm_processing";

    const data = object({
        water_use_amount: number().min(0).required(),
        water_use_amount__unit: string().required().oneOfOptions("volume"),
        water_use_source: string()
            .oneOfOptions("water_sources", true)
            .requiredWhen("water_use_amount", (v) => v > 0),
        wastewater_amount: number().min(0).required(),
        wastewater_amount__unit: string().required().oneOfOptions("volume"),
        wastewater_treatment_onsite: string()
            .notRequired()
            .oneOfOptions(selectOptions("yes_no"), true)
            .requiredWhen("wastewater_amount", (v) => v > 0),
        wastewater_treatment: string()
            .notRequired()
            .oneOfOptions("wastewater_treatment_method", true)
            .requiredWhen(
                ["wastewater_amount", "wastewater_treatment_onsite"],
                (water_amount, treatment_onsite) =>
                    water_amount > 0 && treatment_onsite == "yes"
            ),
        chemical_oxygen_demand_untreated_mg_COD_per_litre: number()
            .min(0)
            .notRequired()
            .requiredWhen(
                ["wastewater_amount", "wastewater_treatment_onsite"],
                (water_amount, treatment_onsite) =>
                    water_amount > 0 && treatment_onsite == "yes"
            ),
        wastewater_discharge: string()
            .notRequired()
            .oneOfOptions("wastewater_discharge_method", true)
            .requiredWhen("wastewater_amount", (v) => v > 0),
        chemical_oxygen_demand_discharge_mg_COD_per_litre: number()
            .notRequired()
            .min(0)
            .requiredWhen("wastewater_amount", (v) => v > 0),
        nitrogen_content_discharge_mg_N_per_litre: number()
            .notRequired()
            .min(0)
            .requiredWhen("wastewater_amount", (v) => v > 0),
        receiving_water_body_nutrient_impacted: string()
            .notRequired()
            .oneOfOptions("yes_no", true)
            .requiredWhen("wastewater_amount", (v) => v > 0),
    });

    const defaults = {};

    const ui = {
        type: "section",
        name: group,
        label: "Water & Wastewater",
        children: [
            {
                type: "number",
                name: `${group}.water_use_amount`,
                label: "Amount of water used (for producing {{crop_name}} and its coproducts)", // just main product or all products?
                unit: selectOptions("volume"),
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.water_use_source`,
                label: "Water source",
                options: selectOptions("water_sources"),
                widthLg: 6,
                styleBreakRowAfter: true,
                condition: (watch) => watch(`${group}.water_use_amount`) > 0,
            },
            {
                type: "number",
                name: `${group}.wastewater_amount`,
                label: "Amount of wastewater generated at facility in specified year",
                unit: selectOptions("volume"),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.wastewater_treatment_onsite`,
                label: "Do you (pre)treat the wastewater onsite?",
                options: selectOptions("yes_no"),
                widthLg: 6,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
                condition: (watch) => watch(`${group}.wastewater_amount`) > 0,
            },
            {
                type: "note",
                label: "Wastewater treatment",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.wastewater_treatment_onsite`) == "yes" &&
                    watch(`${group}.wastewater_amount`) > 0,
            },
            {
                type: "select_one",
                name: `${group}.wastewater_treatment`,
                label: "What type of waste water treatment system do you have?",
                hint: "If you have multiple treatment systems, please select the main type?",
                options: selectOptions("wastewater_treatment_method"),
                widthLg: 5,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.wastewater_treatment_onsite`) == "yes" &&
                    watch(`${group}.wastewater_amount`) > 0,
            },
            {
                type: "number",
                name: `${group}.chemical_oxygen_demand_untreated_mg_COD_per_litre`,
                label: "What is the Chemical Oxygen Demand (COD) of your waste water before treatment?",
                hint: "If you do not know the pre-treatment COD, please give an estimate (click info-button for example values).",
                unit: "mg COD/L",
                info: {
                    title: "Examples for waste water COD in different industries",
                    text: "Alcohol Refining: 1100 mg COD/L | Beer & Malt: 2900 mg COD/L | Coffee: 9000 mg COD/L | Dairy Products: 2700 mg COD/L | Fish Processing: 2500 mg COD/L | Meat & Poultry: 4100 mg COD/L | Organic Chemicals: 3000 mg COD/L | Pulp & Paper (combined): 9000 mg COD/L | Starch Production: 10000 mg COD/L | Sugar Refining: 3200 mg COD/L | Vegetable Oils: 500 - 1200 mg COD/L | Vegetables, Fruits & Juices: 2000 - 10000 mg COD/L | Wine & Vinegar: 1500 mg COD/L",
                },
                condition: (watch) =>
                    watch(`${group}.wastewater_treatment_onsite`) == "yes" &&
                    watch(`${group}.wastewater_amount`) > 0,
                styleBreakRowAfter: true,
                widthLg: 5,
            },

            {
                type: "note",
                label: "Wastewater Discharge",
                styleBreakRowAfter: true,
                styleClassName: "fs-3 mt-4",
                condition: (watch) => watch(`${group}.wastewater_amount`) > 0,
            },
            {
                type: "select_one",
                name: `${group}.wastewater_discharge`,
                label: "How do you discharge your treated or untreated wastewater?",
                options: selectOptions("wastewater_discharge_method"),
                widthLg: 5,
                styleBreakRowAfter: true,
                condition: (watch) => watch(`${group}.wastewater_amount`) > 0,
            },
            {
                type: "number",
                name: `${group}.chemical_oxygen_demand_discharge_mg_COD_per_litre`,
                label: "What is the Chemical Oxygen Demand (COD) of your discharged wastewater?",
                hint: "If you do not know the pre-discharge COD, please give an estimate (click info-button for example values for untreated wastewater).",
                unit: "mg COD/L",
                condition: (watch) => watch(`${group}.wastewater_amount`) > 0,
                widthLg: 5,
                info: {
                    title: "Examples for wastewater COD in different industries",
                    text: "Alcohol Refining: 11000 mg COD/L | Beer & Malt: 2900 mg COD/L | Coffee: 9000 mg COD/L | Dairy Products: 2700 mg COD/L | Fish Processing: 2500 mg COD/L | Meat & Poultry: 4100 mg COD/L | Organic Chemicals: 3000 mg COD/L | Pulp & Paper (combined): 9000 mg COD/L | Starch Production: 10000 mg COD/L | Sugar Refining: 3200 mg COD/L | Vegetable Oils: 500 - 1200 mg COD/L | Vegetables, Fruits & Juices: 5000 mg COD/L | Wine & Vinegar: 1500 mg COD/L",
                },
            },
            {
                type: "number",
                name: `${group}.nitrogen_content_discharge_mg_N_per_litre`,
                label: "What is the nitrogen content of your discharged wastewater?",
                hint: "If you do not know the pre-discharge nitrogen content, please give an estimate (click info-button for example values for untreated wastewater).",
                unit: "mg N/L",
                condition: (watch) => watch(`${group}.wastewater_amount`) > 0,
                styleBreakRowAfter: true,
                widthLg: 5,
                info: {
                    title: "Examples total nitrogen in wastewater for different industries",
                    text: "Alcohol Refining: 2400 mg N/L | Beer & Malt: 55 mg N/L | Fish Processing: 600 mg N/L | Iron & Steel Manufacturing: 250 mg N/L |  Meat & Poultry: 190 mg N/L | Nitrogen Fertiliser: 500 mg N/L | Plastics & Resins: 250 mg N/L | Starch Production: 900 mg N/L",
                },
            },
            {
                type: "select_one",
                name: `${group}.receiving_water_body_nutrient_impacted`,
                label: "Is the water body that receives your waste water nutrient-impacted, e.g. an eutrophic lake or river?",
                options: selectOptions("yes_no"),
                widthLg: 6,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
                condition: (watch) => watch(`${group}.wastewater_amount`) > 0,
            },
        ],
    };

    return { group, data, ui, defaults };
};
