import { number, object, string } from "yup";

export const transportGenericSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit }
) {
    const group = "transport_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const data = object({
        distance_farm_buyer: number().min(0).nanToUndefined().required(),
        distance_farm_buyer__unit: string()
            .notRequired()
            .oneOfOptions("distance"),
        total_diesel: number().positive().nanToUndefined().notRequired(),
        total_diesel__unit: string().notRequired().oneOfOptions("volume"),
    });

    const defaults = {
        distance_farm_buyer__unit: defaultUnit("distance", unitSystem).name,
        total_diesel__unit: defaultUnit("volume", unitSystem).name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Transport",
        label__de: "Transport",
        children: [
            {
                type: "number",
                name: `${group}.distance_farm_buyer`,
                label: "Transport distance from farm gate to buyer",
                label__de: "Transportdistanz von Hof-Tor zur Abnehmerin",
                hint: "Example: 34 km from farm gate to processor or wholesaler",
                hint__de:
                    "Beispiel: 34 km von Hof-Tor bis Verarbeiter oder Großhändler",
                unit: selectOptions("distance"),
                widthLg: 4,
                info: {
                    title: "Distance to buyer",
                    text: "Please enter the one-way road distance from the farm or crop collection point (e.g. in case of wild collection) to the drop-off point of the buyer of the crop. This might be a warehouse or processing facility.",
                    title__de: "Distanz bis Verarbeiter oder Großhändler",
                    text__de:
                        "Bitte die Distanz in eine Richtung vom Hof-Tor bzw. vom Sammelpunkt des Produkts (z.B. im Fall von Wildsammlung) bis Lagerhaus, Fabrik oder Verarbeitungswerk des Abnehmers eintragen.",
                },
            },
            {
                type: "number",
                name: `${group}.total_diesel`,
                label: "If known: Total Diesel consumption for transport to buyer",
                label__de:
                    "Wenn bekannt: Gesamt-Dieselverbrauch für Transporte zur Abnehmerin",
                hint: "Leave empty if unknown",
                hint__de: "Leer lassen, wenn unbekannt",
                unit: selectOptions("volume"),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
        ],
    };

    return { group, data, ui, defaults };
};
