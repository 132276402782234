import { object, string, number } from "yup";

export const residueManagementSugarcaneSchema = function ({}, {}) {
    const group = "residue_management_sugarcane";

    const data = object({
        residue_management: string()
            .required()
            .oneOfOptions("residue_management")
            .default("Left_on_field"),
        _cane_burned_preharvest_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        residue_burned_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        residue_left_on_field_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        residue_composted_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
    });

    const ui = {
        type: "section",
        name: group,
        label: "Crop residues",
        label__de: "Erntereste",
        children: [
            {
                type: "note",
                label: "2.1 Cane burning before harvest",
                styleClassName: "fs-4",
                widthLg: 12,
            },
            {
                type: "number",
                name: `${group}._cane_burned_preharvest_share`,
                label: "Share of field that was burned",
                unit: "%",
                widthLg: 4,
            },
            {
                type: "note",
                label: "2.2 Cane trash treatment after harvest",
                styleClassName: "fs-4",
                widthLg: 12,
            },
            {
                type: "number",
                name: `${group}.residue_burned_share`,
                label: "Share of cane trash that was burned",
                unit: "%",
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.residue_left_on_field_share`,
                label: "Share of cane trash that was left on field",
                unit: "%",
                widthLg: 4,
            },
            {
                type: "number",
                name: `${group}.residue_composted_share`,
                label: "Share of cane trash that was composted",
                unit: "%",
                widthLg: 4,
            },
        ],
    };

    return { group, data, ui };
};
