import { array, number, object, string } from "yup";
import { cropLabel } from "../../../utils/crops";

export const landUseGenericSchema = function (
    { surveyParams, survey },
    { selectOptions }
) {
    const group = "land_use_generic";

    const year = survey?.year;

    const data = object({
        latest_land_use: string().required().oneOfOptions("land_use_type"),
        land_use_change_occured: string().required().oneOfOptions("yes_no"),
        changes: array().of(
            object({
                year: number().integer().required().min(1990).max(2030),
                land_use_before: string()
                    .required()
                    .oneOfOptions(
                        selectOptions("carbon_stock_no_change").concat(
                            selectOptions("land_use_type")
                        )
                    ),
                land_use_before_allocation: number()
                    .required()
                    .min(0.01)
                    .max(100),
            })
        ),
        forest_type: string()
            .notRequired()
            .oneOfOptions(selectOptions("forest_type"), true)
            .requiredWhen("changes", (changes) =>
                (changes || []).some(
                    (c) => c.land_use_before == "native-forest"
                )
            ),
        forest_age: number()
            .notRequired()
            .min(0)
            .max(1000)
            .requiredWhen("changes", (changes) =>
                (changes || []).some(
                    (c) => c.land_use_before == "native-forest"
                )
            ),
    });

    const defaults = {
        latest_land_use: surveyParams?.CFT_CURRENT_LAND_USE,
        land_use_change_occured: "no",
        changes: [],
        changes__default: {
            land_use_before: "no-change",
            land_use_before_allocation: 100,
        },
    };

    const ui = {
        type: "section",
        name: group,
        label: "Land use",
        label__de: "Landnutzung",
        badge: "over_crop_rotation",
        children: [
            {
                type: "note",
                label: "The following section relates to **all cultivated arable lands** of your farm, i.e. not only {{crop_name}}.",
                label__de:
                    "Der folgende Abschnitt bezieht sich auf **alle bewirtschafteten Ackerflächen** Ihres Betriebs, d.h. nicht nur auf {{crop_name}}.",
                widthLg: 8,
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.latest_land_use`,
                label: "Latest land use",
                label__de: "Aktuelle Landnutzung",
                options: selectOptions("land_use_type"),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.land_use_change_occured`,
                label: "Has any land use change occured between {{start_year}} and {{end_year}}?",
                label__de:
                    "Hat es zwischen {{start_year}} und {{end_year}} Landnutzungsänderungen auf Ihren bewirtschafteten Flächen gegeben?",
                hint: "Examples: forest-to-arable land conversion or arable-to-grassland conversion",
                hint__de:
                    "Beispiele: Wald-zu-Ackerland oder Ackerland-zu-Gründland Umwandlungen",
                args: { start_year: year - 20, end_year: year },
                options: selectOptions("yes_no"),
                styleSelectHorizontalButtons: true,
                styleBreakRowAfter: true,
                widthLg: 7,
            },
            {
                type: "note",
                label: 'For years with land use change, please select the year in the field below the table and click "Add change". The land use type that existed before that year can then be entered in the table along with the percentage of the total arable land affected. Please note that only one land use change can be provided per year.',
                label__de:
                    'Bitte wählen Sie im Feld unterhalb der Tabelle ein Jahr aus und klicken auf "Änderung hinzufügen". Die jeweilige die Landnutzungsart, die vor diesem Jahr bestand, kann in der Tabelle angegeben werden, gemeinsam mit dem betroffenen Anteil der gesamten Ackerfläche. Je Jahr kann nur eine Landnutzungsänderung angegeben werden.',
                widthLg: 12,
                condition: (watch) => {
                    return watch(`${group}.land_use_change_occured`) == "yes";
                },
                styleBreakRowAfter: true,
            },
            {
                type: "model_repeat__carbon_stock_changes_v2",
                name: `${group}.changes`,
                label: "Changes",
                label__de: "Changes",
                widsthLg: 7,
                styleBreakRowAfter: true,
                condition: (watch) => {
                    const val = watch(`${group}.land_use_change_occured`);
                    return val == "yes";
                },
                parameters: (watch) => ({
                    columns: [
                        {
                            label: "Land use",
                            label__de: "Landnutzung",
                            latestField: `${group}.latest_land_use`,
                            beforeField: "land_use_before",
                        },
                    ],
                    yearField: "year",
                    allocationField: "allocation",
                    addYearNoteField: "add_year_note",
                }),
                children: [
                    {
                        type: "number",
                        name: "year",
                        label: "Year",
                        condition: () => false,
                    },
                    {
                        type: "select_one",
                        name: "land_use_before",
                        label: "Land use before selected year",
                        label__de: "Landnutzung vor dem ausgewählten Jahr",
                        options: selectOptions("carbon_stock_no_change").concat(
                            selectOptions("land_use_type")
                        ),
                        widthLg: 4,
                    },
                    {
                        type: "number",
                        name: "allocation",
                        label: "Subfield",
                        label__de: "Teilfläche",
                        hint: "E.g., the affected crop area share of the change",
                        hint__de:
                            "Zum Beispiel der betroffene Flächenanteil der Änderung",
                        unit: "%",
                        widthLg: 4,
                    },
                    {
                        type: "note",
                        name: "add_year_note",
                        label: "Select a year in which a land use change occured",
                        label__de:
                            "Wählen Sie ein Jahr, um eine Landnutzungsänderung hinzuzufügen",
                    },
                ],
            },
            {
                type: "note",
                label: "Forest before land use change",
                label__de: "Wald vor der Landnutzungsänderung",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.land_use_before == "native-forest"
                    ),
            },
            {
                type: "note",
                label: "You have entered a land use change that involved forest. Please provide details about the type and age of the former forest.",
                label__de:
                    "Sie haben eine Landnutzungsänderung angegeben, die Wald involviert. Bitte geben Sie Art und Alter des ehemaligen Waldes an.",
                styleClassName: "mt-0",
                widthLg: 12,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.land_use_before == "native-forest"
                    ),
            },
            {
                type: "select_one",
                name: `${group}.forest_type`,
                label: "Forest type before land use change",
                label__de: "Waldart vor Landnutzungsänderung",
                options: selectOptions("forest_type"),
                widthLg: 3,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.land_use_before == "native-forest"
                    ),
            },
            {
                type: "number",
                name: `${group}.forest_age`,
                label: "Forest age at land use change",
                label__de: "Alter des Waldes bei Landnutzungsänderung",
                unit: "Years",
                unit__de: "Jahre",
                widthLg: 3,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    (watch(`${group}.changes`, []) || []).some(
                        (change) => change.land_use_before == "native-forest"
                    ),
            },
        ],
    };

    return { group, data, ui, defaults };
};
