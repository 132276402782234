import { number, object, string } from "yup";
import { cropLabel } from "../../../utils/crops";

export const energySugarbeetSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit, testDieselWithinRangeOption }
) {
    const group = "energy_sugarbeet";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const data = object({
        diesel_known: string().required().oneOfOptions(selectOptions("yes_no")),
        diesel_field: number()
            .positive()
            .nanToUndefined()
            .notRequired()
            .requiredWhen("diesel_known", (v) => v == "yes"),
        diesel_field__unit: string().notRequired().oneOfOptions("volume"),
        diesel_estimated_range: string()
            .notRequired()
            .oneOfOptions(selectOptions("sugarbeet_diesel_estimate"), true)
            .requiredWhen("diesel_known", (v) => v == "no"),
        diesel_estimate: number()
            .positive()
            .nanToUndefined()
            .notRequired()
            .requiredWhen("diesel_known", (v) => v == "no")
            .test({
                name: "diesel_within_range",
                test: testDieselWithinRangeOption(
                    "sugarbeet_diesel_estimate",
                    "diesel_estimated_range",
                    "diesel_estimate",
                    "diesel_known"
                ),
            }),
        diesel_estimate__unit: string()
            .notRequired()
            .oneOfOptions(selectOptions("volume_area_rate"), true)
            .requiredWhen("diesel_known", (v) => v == "no"),

        electricity_field: number().min(0).nanToUndefined().notRequired(),
        electricity_field__unit: string().notRequired().oneOfOptions("energy"),

        electricity_purchased_renewable: string().conditionalOneOfOptions(
            "yes_no",
            ["electricity_field"],
            (electricity_field) => electricity_field && electricity_field > 0
        ),
        electricity_generated_pv_wind_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
    });
    const defaults = {
        diesel_field__unit: defaultUnit("volume", unitSystem).name,
        electricity_field__unit: defaultUnit("energy", unitSystem).name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Energy consumption",
        label__de: "Energieverbrauch",
        children: [
            {
                type: "select_one",
                name: `${group}.diesel_known`,
                label: "Do you know the exact amount of Diesel consumed for {{crop_name}}?",
                label__de:
                    "Kennen Sie den genauen Dieselverbrauch für {{crop_name}}?",
                options: selectOptions("yes_no"),
                widthLg: 4,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
            },
            {
                type: "number",
                name: `${group}.diesel_field`,
                label: "Total Diesel consumption of field machines",
                label__de: "Gesamt-Dieselverbrauch mit Feldmaschinen",
                hint: "Tractor usage for all steps from cultivation of preceding cover crop until on-farm handling of crop post-harvest (incl. consumption by contractors)",
                hint__de:
                    "Alle Feldarbeiten nach Ernte Vorfrucht bis Mietenpflege (inkl. Verbrauch von Fremddienstleistern)",
                unit: selectOptions("volume"),
                widthLg: 4,
                styleBreakRowAfter: true,
                condition: (watch) => watch(`${group}.diesel_known`) == "yes",
            },
            {
                type: "note",
                label: "Estimator for Diesel consumption",
                label__de: "Schätzhilfe für Dieselverbrauch",
                styleClassName: "fs-4 mt-4 mb-1",
                widthLg: 12,
                condition: (watch) => watch(`${group}.diesel_known`) == "no",
            },
            {
                type: "note",
                label: "##### High\n\n**141 - 190 l/ha; Ø 150 l/ha**\n\nConventional/turning tillage (stubble cultivation, plowing, seedbed preparation, sowing). Intercropping. Increased passes for fertilization and crop protection. Irrigation, if necessary. Beet harvesting and piles.",
                label__de:
                    "##### Hoch\n\n**141 - 190 l/ha; Ø 150 l/ha**\n\nKonventionelle/wendende Bodenbearbeitung (Stoppelbearbeitung, Pflug, Saatbettbereitung, Aussaat). Zwischenfruchtanbau. Erhöhte Überfahrten für Düngung und Pflanzenschutz. Ggf. Bewässerung. Rübenernte und Mietenpflege.",
                widthLg: 4,
                condition: (watch) => watch(`${group}.diesel_known`) == "no",
            },
            {
                type: "note",
                label: "##### Medium\n\n**116 - 140 l/ha; Ø 125 l/ha**\n\nPartially reduced tillage, precision seeding. +/- Intercropping. Increased passes for fertilization and crop protection. Beet harvest and piles.",
                label__de:
                    "##### Mittel\n\n**116 - 140 l/ha; Ø 125 l/ha**\n\nTeilweise reduzierte Bodenbearbeitung, Einzelkornsaat. +/- Zwischenfruchtanbau. Erhöhte Überfahrten für Düngung und Pflanzenschutz. Rübenernte und Mietenpflege.",
                widthLg: 4,
                condition: (watch) => watch(`${group}.diesel_known`) == "no",
            },
            {
                type: "note",
                label: "##### Low\n\n**80 - 115 l/ha; Ø 100 l/ha**\n\nGreatly reduced tillage, mulch/direct seeding. +/- Intercropping. Low passes for fertilization and crop protection. Beet harvest and piles.",
                label__de:
                    "##### Niedrig\n\n**80 - 115 l/ha; Ø 100 l/ha**\n\nStark reduzierte Bodenbearbeitung, Mulch-/Direktsaat. +/- Zwischenfruchtanbau. Geringe Überfahrten für Düngung und Pflanzenschutz. Rübenernte und Mietenpflege.",
                widthLg: 4,
                condition: (watch) => watch(`${group}.diesel_known`) == "no",
            },
            {
                type: "select_one",
                name: `${group}.diesel_estimated_range`,
                label: "Estimated consumption range",
                label__de: "Geschätzer Verbrauchsbereich",
                options: selectOptions("sugarbeet_diesel_estimate"),
                widthLg: 6,
                condition: (watch) => watch(`${group}.diesel_known`) == "no",
            },
            {
                type: "number",
                name: `${group}.diesel_estimate`,
                label: "Estimated Diesel consumption per area",
                label__de: "Geschätzter Flächen-Dieselverbrauch",
                hint: "Based on selected range",
                hint__de: "Auf Basis des ausgewählten Bereichs",
                unit: selectOptions("volume_area_rate"),
                widthLg: 6,
                styleBreakRowAfter: true,
                condition: (watch) => watch(`${group}.diesel_known`) == "no",
            },
            {
                type: "note",
                label: "Electricity",
                label__de: "Stromverbrauch",
                styleClassName: "fs-3 mt-4",
                widthLg: 12,
            },
            {
                type: "number",
                name: `${group}.electricity_field`,
                label: "Electricity consumption on the field for {{crop_name}} (e.g., for irrigation)",
                label__de:
                    "Stromverbrauch auf dem Feld für {{crop_name}} (z.B. für Bewässerung)",
                unit: selectOptions("energy"),
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.electricity_purchased_renewable`,
                label: "Do you purchase electricity from a renewable tariff?",
                label__de: "Kaufen Sie Ihren Strom über einen Ökostromvertrag?",
                hint: "Select yes if your electricity contract consists of 100% green electricity",
                hint__de:
                    "Wählen Sie ja, wenn Ihr eingekaufter Strom aus 100% Ökostrom besteht",
                widthLg: 4,
                options: selectOptions("yes_no"),
                condition: (watch) => watch(`${group}.electricity_field`) > 0,
                styleSelectHorizontalButtons: true,
            },
            {
                type: "number",
                name: `${group}.electricity_generated_pv_wind_share`,
                label: "Percentage of self-generated electricity from PV or wind",
                label__de:
                    "Anteil des Stromverbrauchs, der über erneuerbare Eigenerzeugung gedeckt ist (PV, Wind, Biogas)",
                unit: "%",
                widthLg: 4,
                condition: (watch) => watch(`${group}.electricity_field`) > 0,
            },
        ],
    };

    return { group, data, ui, defaults };
};
