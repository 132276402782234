import { Trans, useLingui } from "@lingui/react";
import { useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { Link, useParams } from "react-router-dom";
import {
    surveyEmissionEvaluationsExportExcelUrl,
    surveyLinksExportExcelUrl,
    surveySubmissionsExportExcelUrl,
    useListSurveyAssignmentsQuery,
    useListSurveysQuery,
    useUpdateSurveyAssignmentStatusMutation,
} from "../../services/api";
import { useRequireAuthenticated, useSelfUser } from "../../services/hooks";
import { formatNumber } from "../../utils/format";
import { assembleAssessmentLink } from "../../utils/survey";
import { IconTooltipButton } from "../elements/IconPopoverButton";
import { LoadingSpinner } from "../elements/LoadingSpinner";
import AddFarmModal from "./AddFarmModal";
import { AssignmentDetail } from "./AssignmentDetail";
import AssignmentList from "./AssignmentList";

export default function AssignmentListContainer() {
    const { i18n } = useLingui();
    useRequireAuthenticated();

    const { userData } = useSelfUser();

    const { surveyId } = useParams();
    const {
        data: surveyData,
        survey,
        isLoading: surveyIsLoading,
    } = useListSurveysQuery(undefined, {
        selectFromResult: ({ data }) => ({
            survey: data?.find((survey) => survey.id === surveyId),
        }),
    });

    // const [page, dispatchPageAction] = useReducer((state, action) => {
    //     if (action == "next") {
    //         return state + 1;
    //     }
    //     if (action == "reset") {
    //         return 0;
    //     }
    // }, 0);
    // const [data, dispatchDataAction] = useReducer((state, action) => {
    //     const { type, data } = action;
    //     if (type == "append") {
    //         return [...state, ...data];
    //     }
    //     if (type == "reset") {
    //         return [];
    //     }
    //     return state;
    // }, []);

    const resetAssignmentList = () => {
        dispatchDataAction({ type: "reset", data: [] });
        dispatchPageAction("reset");
    };
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [sorting, setSorting] = useState([]);
    const [currentSearchQuery, setCurrentSearchQuery] = useState("");
    const [globalFilter, setGlobalFilter] = useState("");

    const {
        data: response,
        isFetching: isFetchingAssignments,
        error,
    } = useListSurveyAssignmentsQuery({
        surveyId,
        page: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sorting,
        searchFilter: globalFilter,
    });
    const data = response?.results || [];
    // useEffect(() => {
    //     if (response?.results && response?.results.length > 0) {
    //         dispatchDataAction({ type: "append", data: response.results });
    //         if (response?.next) {
    //             dispatchPageAction("next");
    //         }
    //     }
    // }, [response?.next, response?.results]);

    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [copiedLinkFarmName, setCopiedLinkFarmName] = useState(null);
    const [show, setShow] = useState(false);
    const currentRowCount = data.length;
    const totalRowCount = response?.count;

    const pageCount = Math.ceil(totalRowCount / pagination.pageSize) || 0;
    const pageIndices = [...Array(pageCount).keys()];

    const handleCopyFarmLink = async (assessment) => {
        await navigator.clipboard.writeText(
            assembleAssessmentLink(assessment?.private_assignment_uri)
        );
        setCopiedLinkFarmName(assessment?.farm?.name);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setGlobalFilter(currentSearchQuery);
        return false;
    };
    const [updateAssignmentStatus, { isLoading: isUpdatingStatus }] =
        useUpdateSurveyAssignmentStatusMutation();

    const urlExportSubmissions = surveySubmissionsExportExcelUrl({ surveyId });
    const urlExportLinks = surveyLinksExportExcelUrl({ surveyId });
    const urlExportEmissionEvaluations =
        surveyEmissionEvaluationsExportExcelUrl({ surveyId });

    const isFarmSurvey = survey?.type == "FARM";

    return (
        <Container className="mx-auto my-5 ">
            <AssignmentDetail
                assignment={selectedAssignment}
                survey={survey}
                setSelectedAssignment={setSelectedAssignment}
            />
            <AddFarmModal
                show={show}
                setShow={setShow}
                resetList={resetAssignmentList}
                addToSurveyId={surveyId}
                isFarm={isFarmSurvey}
            />
            <ToastContainer position="top-center" className="top-10">
                <Toast
                    onClose={() => setCopiedLinkFarmName(null)}
                    show={!!copiedLinkFarmName}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">
                            <Trans id="survey.copy_farm_link_toast_title" />
                        </strong>
                        {/* <small>Link copied</small> */}
                    </Toast.Header>
                    <Toast.Body>
                        <Trans
                            id="survey.copy_farm_link_toast_text"
                            components={[<b>{copiedLinkFarmName}</b>]}
                        />
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {(isFetchingAssignments || surveyIsLoading) && <LoadingSpinner />}
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Surveys
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {survey?.title} {survey?.year}
                </Breadcrumb.Item>
            </Breadcrumb>

            <Row>
                <Col lg={6}>
                    {survey && (
                        <>
                            <h3>{survey?.title && survey.title}</h3>
                            <h5>{`${survey?.crop?.name}, ${survey?.year}`}</h5>
                        </>
                    )}
                </Col>
                <Col lg={6}>
                    <ButtonToolbar className="mb-3 float-end">
                        <form onSubmit={handleSearch}>
                            <InputGroup className="me-2">
                                <FormControl
                                    type="text"
                                    name="searchquery"
                                    placeholder={i18n._(
                                        "survey.header.search_placeholder"
                                    )}
                                    value={currentSearchQuery ?? ""}
                                    onChange={(e) =>
                                        setCurrentSearchQuery(
                                            String(e.target.value)
                                        )
                                    }
                                />
                                <Button
                                    variant={
                                        globalFilter == "" ? "light" : "warning"
                                    }
                                    type="submit"
                                >
                                    <i className="bi bi-search"></i>
                                </Button>
                            </InputGroup>
                        </form>
                        <ButtonGroup className="me-2">
                            {isFarmSurvey && (
                                <IconTooltipButton
                                    infoText={i18n._(
                                        "survey.add_farm_button_info"
                                    )}
                                    label={i18n._("survey.add_farm_button")}
                                    iconName="plus-lg"
                                    buttonVariant="primary"
                                    onClick={() => setShow(true)}
                                />
                            )}
                            {!isFarmSurvey && (
                                <IconTooltipButton
                                    infoText={i18n._(
                                        "survey.add_supplier_button_info"
                                    )}
                                    label={i18n._("survey.add_supplier_button")}
                                    iconName="plus-lg"
                                    buttonVariant="primary"
                                    onClick={() => setShow(true)}
                                />
                            )}
                        </ButtonGroup>
                        <ButtonGroup>
                            <Dropdown id="more-dropdown">
                                <Dropdown.Toggle
                                    variant="light"
                                    id="more-dropdown-toggle"
                                ></Dropdown.Toggle>

                                <Dropdown.Menu align={"end"}>
                                    <Dropdown.Item
                                        href={urlExportLinks}
                                        target="_blank"
                                    >
                                        <Trans id="survey.export_assignment_links_excel" />
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        href={urlExportSubmissions}
                                        target="_blank"
                                    >
                                        <Trans id="survey.export_submissions_excel" />
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        href={urlExportEmissionEvaluations}
                                        target="_blank"
                                    >
                                        <Trans id="survey.export_emission_evaluations_excel" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ButtonGroup>
                    </ButtonToolbar>
                    <div className="mt-3"></div>
                </Col>
            </Row>
            <AssignmentList
                survey={survey}
                data={data}
                setSelectedAssignment={setSelectedAssignment}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                pagination={pagination}
                setPagination={setPagination}
                sorting={sorting}
                setSorting={setSorting}
                totalRowCount={totalRowCount}
                onCopyFarmLink={handleCopyFarmLink}
                updateAssignmentStatus={updateAssignmentStatus}
            />
            <Row>
                <Col className="table-pagination text-center">
                    {pageIndices.map((i) => (
                        <Button
                            variant={
                                pagination.pageIndex == i
                                    ? "primary"
                                    : "outline-primary"
                            }
                            key={i}
                            size="sm"
                            disabled={pagination.pageIndex == i}
                            className="me-1"
                            onClick={() =>
                                setPagination({
                                    ...pagination,
                                    pageIndex: i,
                                })
                            }
                        >
                            {i + 1}
                        </Button>
                    ))}
                    <p className="mt-3">
                        <small>
                            <Trans
                                id="survey.showing_num_of_suppliers"
                                components={[
                                    <>{formatNumber(currentRowCount, 0)}</>,
                                    <>{formatNumber(totalRowCount, 0)}</>,
                                ]}
                            />
                        </small>
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
