import { number, object, string } from "yup";

export const residueManagementArableSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit }
) {
    const group = "residue_management_arable";

    const data = object({
        residue_management: string()
            .required()
            .oneOfOptions("residue_management"),
    });

    const ui = {
        type: "section",
        name: group,
        label: "Crop residues",
        label__de: "Erntereste",
        children: [
            {
                type: "select_one",
                name: `${group}.residue_management`,
                label: "Crop residue management",
                label__de: "Ernterestemanagement",
                options: selectOptions("residue_management"),
                widthLg: 5,
            },
        ],
    };

    return { group, data, ui };
};
